import React from 'react';

function BuildPage() {
    return (
        <>
            <h3>Developer Portal</h3>
            <h4>Status: Up</h4>
        </>
    )
}

export default BuildPage;
