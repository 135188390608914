import React, {useEffect} from "react";
import {SdfAvatar, SdfFloatingPane, SdfIcon, SdfLink} from "@synerg/react-components";
import {generateUrl} from "../utility/UriUtil";

import {setIsLoggedIn, setUserInfo, useFetchUserQuery} from '../store';
import {useDispatch, useSelector} from "react-redux";
import {LogIn} from "./LogIn";
import { useLocation } from "react-router-dom";

const rightAction = 'items-center absolute md:relative lg:right-0 md:inset-0 mr-4 lg:mr-0'
export default function LoginLogout() {
    const dispatch = useDispatch();
    const location = useLocation();
    //const debug = false;

    const {isLoggedIn, userInfo} = useSelector((state) => {
        const {isLoggedIn, userInfo} = state.auth
        return {
            isLoggedIn,
            userInfo,
        };
    });

    const loginInfo = useFetchUserQuery();
    useEffect(()=>{
        loginInfo.refetch();
    }, [location, loginInfo?.refetch])
    
    const oneUxWrapper = document.querySelector('sfc-shell');

    useEffect(() => {
        if (loginInfo?.isError && loginInfo?.error?.status === 401) {
            dispatch(setIsLoggedIn(false));
            dispatch(setUserInfo({}));
            oneUxWrapper?.setAttribute('isLoggedIn', false)
        }

        if (loginInfo?.status === "fulfilled" && loginInfo?.isSuccess && loginInfo?.data?.success) {
            dispatch(setIsLoggedIn(true));
            dispatch(setUserInfo(loginInfo?.data));
            oneUxWrapper?.setAttribute('isLoggedIn', true)

            if(
                !(
                    loginInfo?.data?.associateOID &&
                    loginInfo?.data?.organizationOID &&
                    window.pendo
                ) ||
                window.pendo?.isReady()
            )
                return

            window.pendo?.initialize({
                visitor: {
                    id: loginInfo?.data?.associateOI
                },
                account: {
                    id: loginInfo?.data?.organizationOID
                }
            });
        }
    }, [loginInfo]);


    const onHandleSignOut = () => {
        window.location.href = generateUrl(`${process.env.REACT_APP_API_PATH}/logout`);
        dispatch(setIsLoggedIn(false));           
        dispatch(setUserInfo({}));
    }

    const onHandleLoggedInPanel = () => {
        return null
    }

    const getInitials = (userInfo) => {
        let fi = (userInfo?.given_name || '').substring(0, 1);
        let li = (userInfo?.family_name || '').substring(0, 1);

        return `${fi}${li}`;
    }

    const getName = (userInfo) => {
        return `${userInfo?.given_name} ${userInfo.family_name}`;
    }


    return (
        !isLoggedIn ?
            <LogIn className={`${rightAction} pl-2 lg:pl-4`} navigationPath={window.location.href} /> :
            (
                <>
                    <div className="flex items-center" slot="icons">
                        <SdfAvatar
                            className={`${rightAction} mb-10 md:mb-0 mt-1 md:mt-0 mr-4 md:mr-0`}
                            size="md"
                            icon='user'
                            initials={getInitials(userInfo)}
                            slot="icons"
                            onClick={onHandleLoggedInPanel}
                        />
                    </div>

                    <SdfFloatingPane
                        auto-attach
                        attachment="bottom-end"
                        show-arrow>
                        <div className="w-64 p-4 pt-4 text-default">
                            <div className="flex items-center">
                                <SdfAvatar className="mr-2" size="md" icon='user' initials={getInitials(userInfo)}/>
                                <h3>{getName(userInfo)}</h3>
                            </div>
                            <hr className="border-divider border-light border-opacity-25"/>

                            <SdfLink
                                onClick={onHandleSignOut}
                                className="no-underline cursor-pointer py-3">
                                <SdfIcon className="mr-1" icon="action-login"/>
                                Sign out
                            </SdfLink>
                        </div>
                    </SdfFloatingPane>
            </>
            )
    )
}
