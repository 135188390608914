import {createSlice} from "@reduxjs/toolkit";

export const useCasePageSlice = createSlice({
    name: 'useCasePage',
    initialState: {
        id: '',
        index: '',
        source: null
    },
    reducers:
        {
            selectedUseCasePage(state, action) {
                state.id = action.payload.id;
                state.index = action.payload.index;
                state.source = action.payload.source;
            }
        }
})