import React from "react";

function isMultipleOfTwo(int) {
    return int % 2 === 0;
}

export function HorizontalTable({rows}) {
    return Object.keys(rows).map((key, index) => (
        <div key={index}>
            {
                rows[key] && (
                    <div className={`
                            items-center p-3 flex justify-between break-all ${isMultipleOfTwo(index) ? 'bg-white' : 'bg-alert-neutral-50'}
                        `}>
                        <div className="mr-5 w-2/6">
                            {key}
                        </div>
                        <div className="w-4/6">
                            {rows[key]}
                        </div>
                    </div>
                )
            }
        </div>
    ))
}