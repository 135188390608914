import React from "react";
import {SdfTag} from "@synerg/react-components/dist/components";

const tagStyle = 'mb-3 mr-2';

function FiltersTags({filters}) {
    if (!filters?.length) return;
    const closeInputTag = () => {
        filters.map((filter) => {
            if (filter.type !== 'input') return
            filter.eventHandler({target: {value: ''}});
        })
    }
    const closeSelectMultipleTag = (e) => {
        filters.map((filter) => {
            if (filter.type !== 'selectMulti' || filter.label !== e.target.type) return
            filter.eventHandler({
                target: {
                    value: filter.value.filter((select) => select.label !== e.target.value)
                }
            })

            filter.eventHandler({target: {value: ''}});
        })
    }

    const closeSelectSimpleTag = (filter) => {
        if (filter.type !== 'selectSimple') return
        filter.eventHandler({target: {value: filter.initialValue}});
    }

    return (
        <div className="flex justify-start flex-wrap pb-1">
            {
                filters.map(
                    (filter, index) => {
                        if (filter?.noTag) return;

                        if (filter.type === 'input' && filter.value)
                            return <div className={tagStyle} key={index}><SdfTag value={filter.value}
                                                                                 onClick={closeInputTag}/></div>

                        if (filter.type === 'selectMulti' && filter.value.length > 0) {
                            return filter.value.map((value) => {
                                    return <div className={tagStyle} key={value.label}>
                                            <SdfTag type={filter.label} value={value.label} onClick={closeSelectMultipleTag}/>
                                        </div>
                                }
                            )
                        }

                        if (filter.type === 'selectSimple' && filter?.value?.label && (filter.value.value !== filter.noTagIfValue)) {
                            return (
                                <div className={tagStyle} key={filter.label}>
                                    <SdfTag value={filter.value.label}
                                        onClick={() => closeSelectSimpleTag(filter)}/>
                                </div>
                            )
                        }

                    })
            }
        </div>
    )
}

export {FiltersTags}