import React from 'react';
import { UseCases } from '../components/welcome-page/UseCases';
import { LearnAndBuild } from '../components/welcome-page/LearnAndBuild';
import { LatestContent } from '../components/welcome-page/LatestContent';
import { HeroHeader } from '../components/welcome-page/HeroHeader';

function WelcomePage({user}) {


    return (
        <div className="welcomePage">
            <HeroHeader />
            <section className='md:px-12 lg:px-12'>
                <UseCases />
                <LearnAndBuild />
                <LatestContent />
            </section>
        </div>
    );
}

export default WelcomePage;
