function getBaseUrl(url) {
    if (!url) return;
    const i = url.lastIndexOf('/')
    if (!i) return
    return url.substring(0, i)
}

function getSampleUrl(url, samplePath) {
    return url + samplePath.replace('.', '');
}

export { getBaseUrl, getSampleUrl }
