import React, {useState, useRef} from "react";
import {SdfBusyIndicator, SdfFocusPane, SdfIcon} from "@synerg/react-components";
import {copyToClipboard} from "../../../../utility/copyToClipboard";
import {useSelector} from "react-redux";
import {loadSample} from "../../../../store/apis/swaggerApi";

export function BaseModal({handlers, data, children, headerTitle, size, widthClasses}) {
    const eventModalRef = useRef();

    const swaggerUrl = useSelector(state => {
        return state.swaggerMeta.url
    })

    const [selectedEvent, setSelectedEvent] = useState(null)

    async function onHandleSelectEvent(e, index) {
        const item = data[index]
        const activeItem = document.getElementsByClassName('bg-action-primary-700');
        eventModalRef?.current?.scrollTo({
            top: 0
          });

        if (!!activeItem.length) {
            activeItem[0].classList.remove('text-inverse-default', 'bg-action-primary-700', 'rounded-tl-lg')
        }

        //get element class list on modal open or when one of those item is selected
        const element = e?.target?.classList ?? e.classList
        if (index === 0) element.add('rounded-tl-lg')
        element.add('bg-action-primary-700', 'text-inverse-default')

        const sampleUrl = swaggerUrl + '/' + item.example.href;

        const sampleData = await loadSample(sampleUrl);

        setSelectedEvent({...item, sampleData});
    }

    async function selectFirstItem() {
        const eventRowFirstChild = document.getElementById('eventRows')
        await onHandleSelectEvent(eventRowFirstChild, 0);
    }

    function getDownloadHref() {
        return 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(selectedEvent?.sampleData));
    }

    async function onHandleCopyToClipboard() {
        await copyToClipboard(JSON.stringify(selectedEvent.sampleData) ?? "")
    }

    return (
        <SdfFocusPane
            size={size}
            visible
            id="eventModal"
            heading={headerTitle}
            closeable
            hideDismissButton
            acceptLabel="Close"
            onSdfAccept={handlers.closeModal}
            onSdfDismiss={handlers.closeModal}
            onSdfAfterOpen={selectFirstItem}
        >
            <div className="px-4 overflow-hidden">
                {children}
                <div className="flex border-solid border-2 border-light rounded-lg">
                    <div
                        className={`overflow-scroll flex flex-col ${widthClasses.itemsWidthClass} border-solid border-r-2 border-l-0 border-t-0 border-b-0 border-light`}>
                        {data?.map((item, index) => (
                            <span
                                id="eventRows"
                                key={index}
                                className="cursor-pointer p-2"
                                onClick={(e) => onHandleSelectEvent(e, index)}>{item.eventCode || item.uri}</span>
                        ))}
                    </div>
                    <div className={`${widthClasses.jsonCodeBoxWidthClass}`}>
                        <div className="flex justify-between bg-alert-neutral-50 p-2 ">
                            <span className="text-heading-02 font-bold">Response</span>
                            <div className="flex gap-4">
                                <SdfIcon
                                    className="object-cover cursor-pointer hover:opacity-25 text-inherit"
                                    icon="action-copy"
                                    onClick={onHandleCopyToClipboard}
                                />

                                <a
                                    className="self-center text-inherit no-underline pt-1"
                                    style={{color: 'inherit', width: '20px', height: '20px'}}
                                    href={getDownloadHref()}
                                    rel="noreferrer"
                                    target="_blank"
                                    download={selectedEvent?.example?.href}
                                >
                                    <SdfIcon ctyle={{color: 'inherit', width: '20px', height: '20px'}}
                                             className="object-fill hover:opacity-25" icon="action-download"/>
                                </a>
                            </div>
                        </div>
                        <div className="h-md p-2 overflow-auto whitespace-pre" ref={eventModalRef}>
                            {selectedEvent?.sampleData ? JSON.stringify(selectedEvent.sampleData, null, 4) :
                                <SdfBusyIndicator/>}
                        </div>
                    </div>

                </div>
            </div>
        </SdfFocusPane>
    )
}