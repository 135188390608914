function scrollToElement(element, offsetTopMargin=80, behavior = 'smooth') {
    if (!element) return

    window.scrollTo({
        behavior,
        top: (element.offsetTop - offsetTopMargin)
    })
}

function openAccordion(expandableBoxId) {
    const box = document.getElementById(expandableBoxId)
    if (box) box.open()
}

function getInnerText(htmlStr) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlStr, 'text/html');

    return htmlDoc.body.innerText;
}

const resetOptions = {
    'TABLE': (table) => {
        table.removeAttribute('border')
        table.cellSpacing = 0
        table.cellPadding = 0

        const hasTHead = !!table.querySelector('thead')
        table.classList.add(hasTHead ? 'table-with-thead' : 'table-without-thead')

        makeTableScrollable(table)
    },
    'PRE': (pre) => makeTableScrollable(pre)
}

const findElements = (query) => document.querySelectorAll(query)

const resetInlineStyle = (elements, elementsToReset) => {
    elements.forEach(element => {
        const docPreviewElements = element.querySelectorAll(elementsToReset)
        docPreviewElements.forEach(docPreviewElement => {
            docPreviewElement.removeAttribute('style')
            resetOptions[docPreviewElement.tagName]?.(docPreviewElement)
        })

    })
}

const makeTableScrollable = (tableElement) => {
    const div = document.createElement('div');
    div.style.overflowX = 'auto';
    tableElement.parentNode.insertBefore(div, tableElement);
    div.appendChild(tableElement);
}

export {scrollToElement, openAccordion, getInnerText, resetInlineStyle, findElements}