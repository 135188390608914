import {createSlice} from "@reduxjs/toolkit";

export const apiExplorerSlice = createSlice({
    name: 'apiExplorer',
    initialState: {
        selectedProduct: null,
        selectedApi: null,
        selectedProductView: 0
    },
    reducers: {
        selectProduct(state, action) {
            state.selectedProduct = action.payload;
        },
        selectApi(state, action) {
            state.selectedApi = action.payload;
        },
        selectProductView(state, action) {
            state.selectedProductView = action.payload;
        }
    }
});
