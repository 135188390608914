/*
    This will find a key/value in an array that has an arbitrary level of nesting
    It would find 'c', 'hello' in this structure:  [ a: [ b: [ c: 'hello ] ] ]
 */
function findInNestedArray(array, key, value, allowPartialMatch) {
    var o;
    array.some(function iter(a) {
        if (compareStrings(a[key], value, allowPartialMatch)) {
            o = a;
            return true;
        }
        return Array.isArray(a.children) && a.children.some(iter);
    });
    return o;
}

function compareStrings(a, b, allowPartialMatch) {
    if (allowPartialMatch) {
        return a?.startsWith(b) || b?.startsWith(a);
    } else {
        return a === b;
    }
}

function mergeObjects(objects) {
    return objects.reduce((acc, obj) => ({
        ...acc,
        ...obj
    }), {})
}

function isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
}


export {findInNestedArray, mergeObjects, isEmptyObject}
