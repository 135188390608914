import React, {useState} from "react";
import {saveAs} from 'file-saver'
import {copyToClipboard} from "../../../utility/copyToClipboard";
import {MediaType} from "./MediaType";
import {CodeExampleButton} from "./CodeExampleButton";
import {MIN_BYTE_SIZE} from "../../../utility/constants";
import {trueOrNull} from "../../../utility/operatorsUtil";
import {MaximizedCode} from "./FullsreenCode";
import {CodeTypeEnum} from "../../../utility/enums/CodeTypeEnum";

export function CodeBoxTop({dataByteLength, contentType, strData, fileName, disabled}) {
    const [showMaximizedCode, setShowMaximizedCode] = useState(false);
    const handleMaximize = () => {
        setShowMaximizedCode(true);
    }
    const closeMaximizedCode = () => {
        setShowMaximizedCode(false);
    }

    const handleDownload = (jsonData, fileName) => {
        saveAs(new Blob([jsonData], {type: CodeTypeEnum.JSON}), fileName)
    }

    return (
        <>
            <MaximizedCode
                strData={strData}
                visible={showMaximizedCode}
                contentType={contentType}
                onSdfDismiss={closeMaximizedCode}
            />
            <div className="md:mb-4 mt-1 mr-1 flex justify-between items-center flex-col md:flex-row ">
                <MediaType contentType={contentType}/>
                <div>
                    <CodeExampleButton
                        onClick={copyToClipboard}
                        data={strData}
                        icon="action-copy"
                        disabled={trueOrNull(disabled || dataByteLength <= MIN_BYTE_SIZE)}
                    />
                    <CodeExampleButton
                        icon="action-download"
                        onClick={() => handleDownload(strData, fileName)}
                        data={strData}
                        disabled={trueOrNull(disabled || dataByteLength <= MIN_BYTE_SIZE)}
                    />
                    <CodeExampleButton
                        icon="action-maximize"
                        onClick={handleMaximize}
                        disabled={trueOrNull(disabled )}
                    />
                </div>
            </div>
        </>
    )
}