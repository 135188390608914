import {FilterTypesEnum} from "../../utility/enums/FilterTypesEnum";
import {sortByOptions} from "../../utility/FetchingUtils";
import {ArticlesFilterQueriesEnum} from "../../utility/enums/ArticlesFilterQueriesEnum";
import {mkplIntegrationCategories} from "../../utility/articlesUtils";
import {InputWidthEnum} from "../../utility/enums/InputWidthEnum";

export const FILTERS = {
    input: {
        filterType: FilterTypesEnum.Input,
        initialValue: '',
        queryKey: ArticlesFilterQueriesEnum.QUERY,
        inputWidthMd: InputWidthEnum.FULL,
        inputMaxWidth: 'w-full'
    },
    inputWithoutMax: {
        filterType: FilterTypesEnum.Input,
        initialValue: '',
        queryKey: ArticlesFilterQueriesEnum.QUERY,
        inputWidthMd: InputWidthEnum.FULL,
        inputMaxWidth: `max-w-${InputWidthEnum.FULL}`,
    },
    sortBy: {
        filterType: FilterTypesEnum.SelectSimple,
        initialValue: sortByOptions['mostViewed'],
        queryKey: ArticlesFilterQueriesEnum.SORT,
        storeKey: ArticlesFilterQueriesEnum.SORT,
        selectItems: sortByOptions,
        label: 'Sort by',
        isUrlParam: false,
        noTag: true,
        shouldPreserveState: true,
        inputWidthMd: InputWidthEnum.FULL
    },
    browseBySolution: {
        filterType: FilterTypesEnum.SelectMulti,
        initialValue: [],
        label: 'Browse by solution',
        queryKey: ArticlesFilterQueriesEnum.INTEGRATION_CATEGORY,
        selectItems: mkplIntegrationCategories
    }
}

export function getFiltersForUrlParams(filters) {
    return filters
        .filter((filter) => filter?.urlParam?.isUrlParam)
        .map((filter) => {
            return ({
                ...filter.urlParam,
                key: filter.customUrlKey || filter.articlesQueryKey
            })
        })
}

