import {createSlice} from "@reduxjs/toolkit";

export const filtersSlice = createSlice({
    name: 'filtersSlice',
    initialState: {},
    reducers: {
        setFilters(state, action) {
            state.sort = action.payload.sort;
            state.region = action.payload.region;
        }
    }
});