import {SdfIcon, SdfInput} from "@synerg/react-components";
import React from "react";

function SearchInput({label='Search', placeholder='Search by keyword', value, onSdfChange, inputWidthMd, inputMaxWidth='max-w-xl'}) {
    return (
        <SdfInput
            inputWidth_md={inputWidthMd}
            className={`w-full ${inputMaxWidth}`}
            onSdfInput={onSdfChange}
            placeholder={placeholder}
            label={label}
            value={value}
        >
            <SdfIcon icon="action-search" slot="after" className='text-alert-info' />
        </SdfInput>
    )
}

export {SearchInput}