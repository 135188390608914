import React from "react";
import {Link} from "react-router-dom";
import {SdfIcon, SdfLink} from "@synerg/react-components";


/**
 * Use to create a link that has an icon.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

function LinkWithIcon(props) {
    let linkAttributes = {
        href: props.href,
        to: props.to,
        id: props.id,
        className: 'cursor-pointer no-underline hover:underline text-action-primary flex flex-nowrap',
    }

    if (props.external) {
        if (props.iconPosition === 'after') {
            return (
                <SdfLink external={true} target='_blank' {...linkAttributes}>
                    {props.children}
                    <SdfIcon icon={props.icon}/>
                </SdfLink>
            )
        } else {
            return (
                <SdfLink external={true} target='_blank' {...linkAttributes} >
                    <SdfIcon icon={props.icon}/>
                    {props.children}
                </SdfLink>
            )
        }
    } else {
        if (props.iconPosition === 'after') {
            return (
                <Link {...linkAttributes} >
                    {props.children}
                    <SdfIcon icon={props.icon}/>
                </Link>
            )
        } else {
            return (
                <Link {...linkAttributes} >
                    <SdfIcon icon={props.icon}/>
                    <span className="pl-2">
                        {props.children}
                    </span>
                </Link>
            )
        }
    }
}

export default LinkWithIcon;
