import React from "react";
import {SdfCard, SdfIcon, SdfIconButton, SdfLink, SdfTooltip} from "@synerg/react-components";
import {useNavigate} from "react-router-dom";
import {getDomainLabel} from "../../utility/apiExplorerUtils";
import {useDispatch} from "react-redux";
import {selectApi} from "../../store";
import CategoryBadge from "../CategoryBadge";
import {getColorCode} from "../../utility/enums/ExplorerDomainColor";
import {getInnerText} from "../../utility/DOMUtils";


function ApiExplorerProductGridView({products}) {
    const dispatch = useDispatch()
    const navigate = useNavigate();

    function onHandleNavigate({api, rest}) {
        const apiProduct = {...api, ...rest}
        dispatch(selectApi(apiProduct))
        navigate(apiProduct._id)
    }

    return (
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 pt-8">
            {products && products.map(({_source: api, ...rest}, index) => (
                <SdfCard key={index} className="cursor-pointer" onClick={() => onHandleNavigate({api, rest})}>
                    <div className="flex justify-between px-3 pt-3">
                        <CategoryBadge
                            colorCode={getColorCode(api.domain)}>{getDomainLabel(api.domain)}</CategoryBadge>
                        <SdfTooltip tooltipId="alert-info" attachmentPoint="right">
                            <SdfIconButton slot="tooltip-target">
                                <SdfIcon icon="alert-info"></SdfIcon>
                            </SdfIconButton>
                            <span className="break-words">
                                {getInnerText(api.content)}
                            </span>
                        </SdfTooltip>

                    </div>
                    <div className="font-bold text-heading-05 pl-3 pr-8 pt-2">{api.title}</div>
                    <div className="h-full flex flex-col justify-between pt-12 px-3 pb-3">
                        <div></div>
                        <div className="flex flex-col pt-6">
                            <SdfLink className="font-normal text-md text-inherit no-underline cursor-pointer font-medium">
                                View APIs
                            </SdfLink>
                            <span className="font-normal text-sm text-alert-neutral font-medium pt-1">
                                Updated: {new Date(api.modifiedAt).toLocaleDateString()}
                            </span>
                        </div>
                    </div>
                </SdfCard>
            ))}
        </div>
    )
}

export default ApiExplorerProductGridView;
