import React from "react";


function CategoryBadge(props) {
    const {children, colorCode, isListView, className} = props

    const background = `rounded-full p-2 min-w-24`
    const leftPill = `rounded-full w-24`
    return (
        <div className={`${isListView ? 'pb-2' : ''} ${className}`}>
            <span style={{backgroundColor: colorCode, width: 100}} className={leftPill}>
                &nbsp;&nbsp;
            </span>
            <span className={background}>
                {children}
            </span>
        </div>
    )

}

export default CategoryBadge