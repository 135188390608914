import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {SdfBox, SdfSegmentedControl, SdfShimmer} from "@synerg/react-components";
import {selectProductView, useFetchProductsQuery} from "../../../store";
import {transformProductsToSelect} from "../../../utility/transform/productsApi";
import {useFetchDomainsQuery} from "../../../store/apis/articlesMetaApi";
import ApiExplorerProductViewWithFilter from "../../../components/api-explorer/ApiExplorerListWithFilter";
import Breadcrumb from "../../../components/Breadcrumb";
import {RequestError} from "../../../components/RequestError";
import {PageTitle} from "../../../components/PageTitle";

function ApiExplorerProductView() {
    let domainsSelect, selectedProduct, bottomUi;
    const {productCode} = useParams();
    const dispatch = useDispatch();
    const breadcrumbPath = [
        {name: 'Welcome', route: '/welcome'},
        {name: 'Build Overview', route: '/build/overview'},
        {name: 'API Explorer', route: '/build/api-explorer'},
    ]
    const viewOptions = [
        {label: 'Grid', value: 'grid'},
        {label: 'List', value: 'list'}
    ]
    const {apiExplorerProductView} = useSelector(({useApiExplorer}) => {
        return {
            apiExplorerProduct: useApiExplorer.selectedProduct,
            apiExplorerProductView: useApiExplorer.selectedProductView
        };
    });

    const productsQuery = useFetchProductsQuery()
    const domainsQuery = useFetchDomainsQuery()
    const productsSelect = transformProductsToSelect(productsQuery.data)

    function onHandleSwitchPageView(event) {
        dispatch(selectProductView(event.detail))
    }

    if (domainsQuery?.data) {
        domainsSelect = domainsQuery?.data;
    }

    if (domainsQuery.error || productsQuery.error) {
        bottomUi = <RequestError/>
    } else if (domainsQuery.isLoading || productsQuery.isLoading) {
        bottomUi = (
            <SdfBox>
                <SdfShimmer rows={3}/>
            </SdfBox>
        )
    } else if (productCode && productsSelect && domainsSelect) {
        selectedProduct = productsSelect?.find((product) => product?.value === productCode) || productsSelect[0];
        breadcrumbPath.push({name: selectedProduct?.label, route: ''})

        bottomUi = (
            <ApiExplorerProductViewWithFilter
                productCode={productCode}
                productsSelect={productsSelect}
                selectedProduct={selectedProduct}
                apiExplorerProductView={apiExplorerProductView}
            />
        )
    }

    return (
        <div className="flex flex-col pb-8 px-12">
            <Breadcrumb path={breadcrumbPath}></Breadcrumb>
            <div className="flex justify-between">

                <PageTitle
                    text="API Explorer"
                />
                <SdfSegmentedControl
                    className="h-full mt-2"
                    items={viewOptions}
                    selectedIndex={apiExplorerProductView}
                    onSdfChange={onHandleSwitchPageView}
                />
            </div>
            {bottomUi}
        </div>
    )
}

export default ApiExplorerProductView;
