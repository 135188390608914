import React from 'react';
import LinkWithIcon from './LinkWithIcon';
import {InternalLink} from "./InternalLink";
import packagejson from "../../package.json"

import './articles/previews/GuidesPrintView/WholeGuidePrintView/style.css'

function Footer() {
    return (

        <div className='no-print flex flex-col-reverse flex-end'>
            <div
                className='flex mt-6 justify-between border-solid border-0 border-light border-t-2 py-3 pb-8 pr-10 my-auto'
                data-testid='footer'>
                <div className='pl-6 flex gap-10 flex-wrap justify-between'>
                    <LinkWithIcon icon='external-link' external href='https://adp.com/privacy.aspx' id="privacy">Privacy</LinkWithIcon>
                    <LinkWithIcon icon='external-link' external href='https://adp.com/legal.aspx' id="terms">Terms</LinkWithIcon>
                    <LinkWithIcon icon='external-link' external href='https://apps.adp.com' id="apps">Apps</LinkWithIcon>
                    <LinkWithIcon icon='external-link' external href='https://partners.adp.com' id="partner">Partner</LinkWithIcon>
                    <InternalLink path="/support/help-center" id="helpCenter">Help Center</InternalLink>
                </div>
                <div
                    title={"Application Version: " + packagejson.version}
                    className='ml-10 font-normal text-sm '
                    id="copyright"
                >
                    Copyright © 2023 ADP, Inc.
                </div>
            </div>
        </div>
    )
}

export default Footer;
