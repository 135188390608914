import React from 'react';
import ArticlesListWithFilter from "../../components/articles/ArticlesListWithFilter";
import {FILTERS} from "../../components/articles/filters";

const breadcrumbPath = [
    { name: 'Welcome', route: '/welcome' },
    { name: 'Learn Overview', route: '/learn/learn-overview' },
    { name: 'Key Concepts', route: '/learn/key-concepts' }
]

function KeyConceptsPage() {
    return (
        <ArticlesListWithFilter
            pageTitle="Key Concepts"
            navigatePath="/learn/key-concepts"
            breadcrumbPath={breadcrumbPath}
            fieldsDescriptions={['TOPIC', 'LAST UPDATED']}
            filtersDefinition={[
                FILTERS.inputWithoutMax,
                FILTERS.sortBy,
            ]}
            customArticlesQuery={{
                tags: ['keyconcept']
            }}
        />
    )
}

export default KeyConceptsPage;
