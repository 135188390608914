import React from "react";

import {SdfLink} from "@synerg/react-components";
import {setIsLoggedIn, setUserInfo} from "../store";
import {generateUrl} from "../utility/UriUtil";
import {useDispatch} from "react-redux";


export function LogIn({
	debug = false,
	slot="icons",
	className,
	navigationPath
}) {
	const dispatch = useDispatch();
	const handleSignIn = () => {
		if (debug) {
			dispatch(setIsLoggedIn(true));
			dispatch(setUserInfo({givenName: 'Test', familyName: 'User'}));

		} else {
			const url = navigationPath ? 'authenticate?redirUrl='+navigationPath : 'authenticate';
			window.location.href = generateUrl(`${process.env.REACT_APP_API_PATH}/${url}`);
		}
	}

	return (
		<SdfLink
			slot={slot}
			className={className}
			onClick={handleSignIn}
			href="#"
			id="viewBtn"
			emphasis="primary"
			external
		>
			Log in
		</SdfLink>
	)
}