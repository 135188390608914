import React from 'react';
import {
    SdfBusyIndicator,
    SdfCard,
    SdfCardFooter,
    SdfCardHeader,
    SdfLink,
    SdfSpotIllustration
} from '@synerg/react-components';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {selectedArticle, setIsLoggedIn, setUserInfo, useFetchArticlesQuery} from '../../store';

import LinkWithIcon from '../../components/LinkWithIcon';
import {buildQueryObj} from '../../utility/FetchingUtils';
import {generateUrl} from "../../utility/UriUtil";

import Icon from '../../assets/images/Book.svg'
import {OverviewPageTitle} from "../../components/OverviewPageTitle";
import {OVERVIEW_TEXT_IMAGE_TOP, OVERVIEW_TOP_IMAGE} from "../../utility/constants";
import {BriefCard} from "../../components/BriefCard";

const SECTION_TITLE_FONT_SIZE = 'text-3xl'
const SECTION_TITLE_FONT_WEIGHT = 'font-black'
const LINK_STYLE = "text-action-primary no-underline hover:underline cursor-pointer"


function LearnPage() {
    const debug = false;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {isLoggedIn} = useSelector(({auth}) => {
        return auth
    })

    const {
        data: keyConceptData,
        error: keyConceptError,
        isLoading: keyConceptIsLoading
    } = useFetchArticlesQuery(buildQueryObj({tags: ['keyconcept'], size: 3}));

    const {
        data: howtoData,
        error: howtoError,
        isLoading: howtoIsLoading
    } = useFetchArticlesQuery(buildQueryObj({tags: ['howto'], size: 4}));

    const {
        data: faqData,
        error: faqError,
        isLoading: faqIsLoading
    } = useFetchArticlesQuery(buildQueryObj({tags: ['faq'], index: "faq", size: 3}));

    const onHandleNavigate = (where, article) => {
        dispatch(selectedArticle(article));
        navigate(`/learn/${where}/${article._id}`);
    }

    const onHandleSignIn = (where, article) => {
        if (debug) {
            dispatch(setIsLoggedIn(true));
            dispatch(setUserInfo({givenName: 'Test', familyName: 'User'}));

        } else {
            dispatch(selectedArticle(article));
            window.location.href = generateUrl(`${process.env.REACT_APP_API_PATH}/authenticate?redirUrl=${window.location.origin}/learn/${where}/${article._id}`);
        }
    }

    return (
        <div className="break-words">
            <div className="bg-action-primary-900">
                <div className={OVERVIEW_TEXT_IMAGE_TOP}>
                    <section>
                        <OverviewPageTitle text="Learn"/>
                        <div className="text-heading-07 font-black text-inverse-default mb-1">
                            Access documentation to better
                            <br/>understand how ADP APIs work
                        </div>
                        <div className="text-lg font-normal text-inverse-default">
                            Find the reference information you need quickly using powerful reference tools
                            <br/>including full-text search, key concepts, FAQs and how-to articles.
                        </div>
                    </section>

                    <img
                        alt='Book'
                        src={Icon}
                        className={OVERVIEW_TOP_IMAGE}
                    />
                </div>
            </div>
            <div className="mx-3 mt-6 md:mx-12 lg:mx-12">
                <section className='mb-10'>
                    <div className='flex justify-between items-center'>
                        <span className={`${SECTION_TITLE_FONT_SIZE} ${SECTION_TITLE_FONT_WEIGHT}`}>Key Concepts</span>
                        <LinkWithIcon to='/learn/key-concepts' icon='nav-move-right' iconPosition='after'>View
                            all</LinkWithIcon>
                    </div>

                    <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 pt-4'>
                        {keyConceptIsLoading ? <SdfBusyIndicator/> : ''}
                        {keyConceptError ? <h3>Error loading key concepts</h3> : ''}
                        {keyConceptData && keyConceptData.articles.map((keyConcept, index) => (
                            <SdfCard
                                key={index}
                                onClick={() => onHandleNavigate('key-concepts', keyConcept)}
                                className="cursor-pointer"
                            >
                                <SdfCardHeader className="text-xl font-bold">{keyConcept._source.title}</SdfCardHeader>
                                <div className="card-height-stretch my-2"/>
                                <SdfCardFooter className="text-md font-medium">
                                    <SdfLink
                                        className={LINK_STYLE}>
                                        View article
                                    </SdfLink>
                                </SdfCardFooter>
                            </SdfCard>
                        ))
                        }
                    </div>
                </section>

                <section className='mb-10'>
                    <div className='flex justify-between items-center'>
                        <span className={`${SECTION_TITLE_FONT_SIZE} ${SECTION_TITLE_FONT_WEIGHT}`}>How-to Articles</span>
                        <LinkWithIcon to='/learn/how-to-articles' icon='nav-move-right' iconPosition='after'>View
                            all</LinkWithIcon>
                    </div>

                    <div className='grid lg:grid-cols-2 sm:grid-cols-1 gap-4 pt-4'>
                        {howtoIsLoading ? <SdfBusyIndicator/> : ''}
                        {howtoError ? <h3>Error loading How-to Articles</h3> : ''}
                        {howtoData && howtoData.articles.map((howto, index) => (
                            <BriefCard
                                key={index}
                                title={howto._source.title}
                                subtitle={howto._source.abstract}
                                footer="View how-to"
                                onClick={() => onHandleNavigate('how-to-articles', howto)}
                            />
                        ))
                        }
                    </div>
                </section>

                <section>
                    <div className='flex justify-between items-center'>
                        <span className={`${SECTION_TITLE_FONT_SIZE} ${SECTION_TITLE_FONT_WEIGHT}`}>FAQs</span>
                        <LinkWithIcon to='/learn/faqs' icon='nav-move-right' iconPosition='after'>View
                            all</LinkWithIcon>
                    </div>

                    <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 pt-4'>
                        {faqIsLoading ? <SdfBusyIndicator/> : ''}
                        {faqError ? <h3>Error loading FAQs</h3> : ''}
                        {faqData && faqData.articles.map((faq, index, array) => {
                                //if user isn't logged in and loop is in last index we show this static card
                                if (!isLoggedIn && array.length - 1 === index) return (
                                    <SdfCard>
                                        <SdfCardHeader className="text-lg font-bold mb-2">
                                            <SdfSpotIllustration illustrationName='docs-locked' size='sm'/>
                                        </SdfCardHeader>
                                        <span className="text-xl font-bold">Don’t miss out!</span>
                                        <span
                                            className='text-sm'>20+ more product-specific FAQs available to logged in users</span>
                                        <div className="card-height-stretch"/>
                                        <SdfCardFooter className="text-md font-medium">
                                            <SdfLink onClick={()=>onHandleSignIn('faqs', faq)} className={LINK_STYLE}>
                                                Log in to view
                                            </SdfLink>
                                        </SdfCardFooter>
                                    </SdfCard>
                                )
                                return (
                                    <SdfCard
                                        key={index}
                                        onClick={() => onHandleNavigate('faqs', faq)}
                                        className="cursor-pointer"
                                    >
                                        <SdfCardHeader className="text-xl font-bold">{faq._source.title}</SdfCardHeader>
                                        <div className="card-height-stretch"/>
                                        <SdfCardFooter className="text-md font-medium">
                                            <SdfLink
                                                className={LINK_STYLE}
                                            >
                                                View answer
                                            </SdfLink>
                                        </SdfCardFooter>
                                    </SdfCard>
                                )
                            }
                        )
                        }
                    </div>
                </section>
            </div>
        </div>
    )
}

export default LearnPage;
