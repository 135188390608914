import React from 'react';
import {useFetchSwaggerQuery} from "../../store";
import {Swagger} from "./swagger/Swagger";
import {SdfBusyIndicator} from "@synerg/react-components";

export function ApiExplorerPreview({swaggerUrl, apiClick}) {
	if(!swaggerUrl) return

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const fetchSwagger = useFetchSwaggerQuery(
        {url: swaggerUrl},
    );

	return (!fetchSwagger.data && fetchSwagger.isLoading) ?
		<SdfBusyIndicator/> :
		<span className="mt-10">
			<Swagger
				isPreview
				paths={fetchSwagger.data.paths}
				tags={fetchSwagger.data.tags}
				apiClick={apiClick}
			/>
		</span>
}