/* eslint-disable react-hooks/rules-of-hooks */
import {FilterTypesEnum} from "../../utility/enums/FilterTypesEnum";
import {handleInput, handleMultipleSelect, handleSingleSelect} from "../../utility/handlers/formHandler";
import {filterSelect} from "../../utility/useEffect/urlParamsUtil";
import {useSearchParams} from "react-router-dom";
import {useState} from "react";
import {ArticlesFilterQueriesEnum} from "../../utility/enums/ArticlesFilterQueriesEnum";
import {useDispatch, useSelector} from "react-redux";
import {setFilters} from "../../store";

function generateArticlesQuery(filters, filterFunction) {
    return {
        [ArticlesFilterQueriesEnum.QUERY]: findQueryFilter(filters, ArticlesFilterQueriesEnum.QUERY),
        [ArticlesFilterQueriesEnum.SORT]: findQueryFilter(filters, ArticlesFilterQueriesEnum.SORT),
        [ArticlesFilterQueriesEnum.INTEGRATION_CATEGORY]: mapSelectToStrArray(findQueryFilter(filters, ArticlesFilterQueriesEnum.INTEGRATION_CATEGORY)),
        [ArticlesFilterQueriesEnum.USECASE_TAGS]: mapSelectToStrArray(findQueryFilter(filters, ArticlesFilterQueriesEnum.USECASE_TAGS), 'value'),
        [ArticlesFilterQueriesEnum.API_PRODUCT_CODE]: filterFunction(filters, ArticlesFilterQueriesEnum.API_PRODUCT_CODE)?.value,
        [ArticlesFilterQueriesEnum.ID]: findQueryFilter(filters, ArticlesFilterQueriesEnum.API_PRODUCT_CODE)?.value,
        [ArticlesFilterQueriesEnum.API_PRODUCT]: mapAndMergeSelectToStrArray(
            filterByKey(filters, ArticlesFilterQueriesEnum.API_PRODUCT)
        ),
        _source: [
            '_id',
            'title',
            'abstract',
            'modifiedAt',
            'rbac'
        ]
    }
}

function isKeyArticleQuery(articlesQueryKey, key) {
    return articlesQueryKey === key;
}

function filterByKey(filters, key) {
    return filters.filter(
        (filter) => isKeyArticleQuery(filter?.articlesQueryKey, key)
    )
}

function findQueryFilterForApiProductsCode(filters) {
    const apiProductFilters = filters.filter(filter => filter.articlesQueryKey === ArticlesFilterQueriesEnum.API_PRODUCT_CODE)

    const foundFilter = apiProductFilters.find(filter => {
        if (filter.id && filter.value) {
            return filter
        }
    })
    return foundFilter?.value
}

function findQueryFilter(filters, key) {
    if (!filters?.length) return;
    return filters.find(filter => isKeyArticleQuery(filter?.articlesQueryKey, key))?.value;
}

function mapSelectToStrArray(obj, key = 'label') {
    if (!obj?.length) return;
    return obj.map((category) => category[key]);
}

function mapAndMergeSelectToStrArray(obj) {
    if (!obj?.length) return;
    return obj.reduce((acc, category) => {
        const str = mapSelectToStrArray(category?.value);
        if (!str) return acc;
        return [...acc, ...str]
    }, []);
}

function getArticlesQueryKey(isUrlParam, customUrlKey, queryKey) {
    return isUrlParam && (customUrlKey || queryKey)
}

export function generateFiltersAndQueryArticles({filtersDefinition, filterFunctionName = 'findQueryFilter'}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const filtersFromState = useSelector(state => state.filtersSlice)
    const dispatch = useDispatch();

    const generateFilter = ({
                                id,
                                filterType,
                                initialValue,
                                queryKey,
                                customUrlKey,
                                selectItems,
                                label,
                                placeholder,
                                noTag,
                                inputWidthMd,
                                customEventHandlerBefore,
                                customEventHandlerAfter,
                                noTagIfValue,
                                inputMaxWidth,
                                isUrlParam = true,
                                storeKey,
                                shouldPreserveState = false
                            }) => {
        const articlesUrlKey = getArticlesQueryKey(isUrlParam, customUrlKey, queryKey)
        switch (filterType) {
            case FilterTypesEnum.Input:
                const [input, setInput] = useState(initialValue);
                return {
                    type: filterType,
                    eventHandler: handleInput(
                        setInput,
                        searchParams,
                        setSearchParams,
                        articlesUrlKey
                    ),
                    value: input,
                    articlesQueryKey: queryKey,
                    inputWidthMd,
                    initialValue,
                    inputMaxWidth,
                    urlParam: {
                        isUrlParam,
                        isArray: false,
                        outsideUrlSetter: setInput,
                    }
                }
            case FilterTypesEnum.SelectSimple:
                const [selectSimple, setSelectSimple] = useState(
                    shouldPreserveState && filtersFromState[storeKey] || initialValue
                );
                return {
                    id,
                    type: filterType,
                    eventHandler: (e) => {
                        customEventHandlerBefore &&
                        customEventHandlerBefore(e)

                        handleSingleSelect(
                            setSelectSimple,
                            selectItems,
                            searchParams,
                            setSearchParams,
                            articlesUrlKey
                        )(e)

                        shouldPreserveState &&
                        dispatch(
                            setFilters({[storeKey]: e.target.value}
                            ))

                        customEventHandlerAfter &&
                        customEventHandlerAfter(e)
                    },
                    urlParam: {
                        isUrlParam: isUrlParam,
                        isArray: false,
                        outsideUrlSetter: setSelectSimple,
                        transformParamsFunc: filterSelect(selectItems)
                    },
                    value: selectSimple,
                    items: selectItems,
                    noTag,
                    articlesQueryKey: queryKey,
                    label,
                    inputWidthMd,
                    setSelectSimple,
                    noTagIfValue,
                    initialValue,
                    customUrlKey
                }
            case FilterTypesEnum.SelectMulti:
                const [selectMulti, setSelectMulti] = useState([]);
                return {
                    type: filterType,
                    eventHandler: handleMultipleSelect(
                        selectMulti,
                        setSelectMulti,
                        searchParams,
                        setSearchParams,
                        articlesUrlKey
                    ),
                    value: selectMulti,
                    items: selectItems,
                    noTag,
                    articlesQueryKey: queryKey,
                    initialValue,
                    urlParam: {
                        isUrlParam,
                        isArray: true,
                        outsideUrlSetter: setSelectMulti,
                        transformParamsFunc: filterSelect(selectItems)
                    },
                    label,
                    placeholder,
                    inputWidthMd,
                    customUrlKey
                }
            default: 
                return {}
        }
    }

    const filters = filtersDefinition
        .map((filter) => generateFilter(filter))

    return {
        filters,
        articlesQuery: generateArticlesQuery(filters, filterFunctionName === 'findQueryFilterForApiProductsCode' ? findQueryFilterForApiProductsCode : findQueryFilter),
        searchParams,
        setSearchParams
    }
}