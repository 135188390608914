const mkplIntegrationCategories = [
    {label: 'Partner Onboarding', value: 'Partner-Onboarding'},
    {label: 'Authentication and Security', value: 'Authentication-and-Security'},
    {label: 'Marketplace Integration', value: 'Marketplace-Integration'},
    {label: 'Single Sign-On', value: 'Single-Sign-On'},
    {label: 'Marketing and Sales', value: 'Marketing-and-Sales'},
    {label: 'International Marketplace', value: 'International-Marketplace'}
];

const productIntegrationcategories = [
    {label: 'Recruiting', value: 'Recruiting'},
    {label: 'Human Resources', value: 'Human-Resources'},
    {label: 'Time & Labor Management', value: 'Time-Labor-Management'},
    {label: 'Payroll Management', value: 'Payroll-Management'},
    {label: 'Benefit Management', value: 'Benefit-Management'},
    {label: 'Compliance Solutions', value: 'Compliance-Solutions'},
];

const adpNorthAmericaProduct = [
    {
        "label": "ADP Workforce Now®",
        "value": "ADP Workforce Now®",
        "id": "hcm-offrg-wfn"
    },
    {
        "label": "ADP® Workforce Manager",
        "value": "ADP® Workforce Manager",
    },
    {
        "label": "RUN Powered by ADP®",
        "value": "RUN Powered by ADP®",
        "id": "pr-offrg-run"
    },
    {
        "label": "ADP Vantage HCM®",
        "value": "ADP Vantage HCM®",
        "id": "hcm-offrg-vantg"
    },
    {
        "label": "ADP Enterprise HR®",
        "value": "ADP Enterprise HR®",
        "id": "hcm-offrg-ent"
    },
    {
        "label": "ADP Lyric",
        "value": "ADP Lyric",
        "id": "hcm-offrg-lifion"
    },
    {
        "label": "ADP® Recruiting Management",
        "value": "ADP® Recruiting Management",
        "id": "tal-offrg-tal.rcrtg"
    },
    {
        "label": "ADP SmartCompliance® Tax Credits",
        "value": "ADP SmartCompliance® Tax Credits",
        "id": "tax-offrg-tcs"
    },
    {
        "label": "ADP® DataCloud Embedded Direct Deposit Enablement",
        "value": "ADP® DataCloud Embedded Direct Deposit Enablement",
    },
    {
        "label": "ADP® DataCloud Employment and Income Verification",
        "value": "ADP® DataCloud Employment and Income Verification",
        "id": "anlyt-offrg-data.cld"
    },
    {
        "label": "ADP Workforce Now® Next Generation",
        "value": "ADP Workforce Now® Next Generation",
        "id": "hcm-offrg-wfn.next.gen"
    },
    {
        "label": "ADP® Next Gen Payroll",
        "value": "ADP® Next Gen Payroll",
        "id": "pr-offrg-pi"
    },
    {
        "label": "ADP SmartCompliance®",
        "value": "ADP SmartCompliance®",
        "id": "cp-offrg-smart.cp"
    },
    {
        "label": "ADP Screening and Selection Services®",
        "value": "ADP Screening and Selection Services®",
        "id": "tal-offrg-sass"
    },
    {
        "label": "ADP Insurance Services",
        "value": "ADP Insurance Services",
        "id": "ins-offrg-ins.svcs"
    },
    {
        "label": "ADP® Embedded Payroll",
        "value": "ADP® Embedded Payroll",
        "id": "pr-offrg-adp.embdd.pr"
    },
    {
        "label": "ADP Workforce Now On the Go®",
        "value": "ADP Workforce Now On the Go®",
        "id": "hcm-offrg-wfn.otg"
    }
];

const adpIternationalProduct = [
    {
        "label": "ADP® Link",
        "value": "ADP® Link",
        "id": "pr-offrg-adp.link.prem"
    },
    {
        "label": "ADP Decidium®",
        "value": "ADP Decidium®",
        "id": "hcm-offrg-dge"
    },
    {
        "label": "ADP® iHCM",
        "value": "ADP® iHCM",
        "id": "hcm-offrg-ihcm.2"
    },
    {
        "label": "ADP Workforce®",
        "value": "ADP Workforce®",
        "id": "hcm-offrg-wkfc"
    },
    {
        "label": "ADP eXpert®",
        "value": "ADP eXpert®",
    },
    {
        "label": "ADP GlobalView®",
        "value": "ADP GlobalView®",
    },
    {
        "label": "ADP Celergo®",
        "value": "ADP Celergo®",
    },
    {
        "label": "ADP Time Connect",
        "value": "ADP Time Connect",
        "id": "ta-offrg-gta"
    },
    {
        "label": "ADP eService-Case Management",
        "value": "ADP eService-Case Management",
        "id": "crm-int-siebel.crm-g.fr-o.oracle"
    },
    {
        "label": "ADP® AdvancedPay",
        "value": "ADP® AdvancedPay",
        "id": "pr-offrg-paisy.advd"
    },
    {
        "label": "ADP® OneService",
        "value": "ADP® OneService",
        "id": "hcm-offrg-sipert"
    }

];

const findFromProductsAndId = (products, productId) => products.find((product) => {
    if(!productId) return
    return product.id && (product.id === productId)
})
const findProductFromId = (productId) => (findFromProductsAndId(adpNorthAmericaProduct, productId) || findFromProductsAndId(adpIternationalProduct, productId))

function getBreadcrumb(pageName, articleTitle, articleLink) {
    let ret;
    switch (pageName) {
        case 'key-concepts':
            ret = [
                {
                    name: 'Welcome',
                    route: '/welcome'
                },
                {
                    name: 'Learn Overview',
                    route: '/learn/learn-overview'
                },
                {
                    name: 'Key Concepts',
                    route: '/learn/key-concepts'
                },
                {name: articleTitle}];
            break;
        case 'how-to-articles':
            ret = [
                {
                    name: 'Welcome',
                    route: '/welcome'
                },
                {
                    name: 'Learn Overview',
                    route: '/learn/learn-overview'
                },
                {
                    name: 'How-to Articles',
                    route: '/learn/how-to-articles'
                },
                {name: articleTitle}
            ];
            break;
        case 'faqs':
            ret = [
                {
                    name: 'Welcome',
                    route: '/welcome'
                },
                {
                    name: 'Learn Overview',
                    route: '/learn/learn-overview'
                },
                {
                    name: 'FAQs',
                    route: '/learn/faqs'
                },
                {name: articleTitle}
            ];
            break;
        case 'marketplace-integration-guides':
            ret = [
                {
                    name: 'Welcome',
                    route: '/welcome'
                },
                {
                    name: 'Build Overview',
                    route: '/build/overview'
                },
                {
                    name: 'Marketplace Integration Guides',
                    route: '/build/guides/marketplace-integration-guides'
                }              
            ];
            if (articleLink) {
                ret.push({
                    name: articleLink[1],
                    route: `/build/guides/marketplace-integration-guides/${articleLink[0]}`
                }, { name: articleTitle });
            }
            else {
                ret.push({
                    name: articleTitle
                });
            }
            break;
        case 'product-integration-guides':
            ret = [
                {
                    name: 'Welcome',
                    route: '/welcome'
                },
                {
                    name: 'Build Overview',
                    route: '/build/overview'
                },
                {
                    name: 'Product Integration Guides',
                    route: '/build/guides/product-integration-guides'
                }
            ];
            if (articleLink) {
                ret.push({
                    name: articleLink[1],
                    route: `/build/guides/product-integration-guides/${articleLink[0]}`
                }, { name: articleTitle });
            }
            else {
                ret.push({
                    name: articleTitle
                });
            }
            break;
        default:
            ret = [];
    }
    return ret;
}

const isFetching = (query) => (query.isLoading || query.status === 'pending')

export {
    mkplIntegrationCategories,
    productIntegrationcategories,
    adpNorthAmericaProduct,
    adpIternationalProduct,
    getBreadcrumb,
    findProductFromId,
    isFetching
};
