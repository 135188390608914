import React from "react";
import {SdfPromoBox} from "@synerg/react-components";
import SearchArticleContent from '../SearchArticleContent';
import ChapterList from "../../guides/ChapterList";
import {transformDateToLongWithComma} from "../../../utility/dateUtil";
import {PageTitle} from "../../PageTitle";
import DOMPurify from "dompurify";


function ArticlePreview({article}) {

    return (
        <div className="flex px-6">
            <div>
                <PageTitle
                    text={article?.title}
                    className="mb-2"
                />
                <div className="text-tertiary mb-6">
                    Last updated: {
                    transformDateToLongWithComma(
                        new Date(article?.date)
                    )
                }
                </div>
                <div className="flex flex-col w-full">
                    <div className="flex w-full">
                        <SdfPromoBox layout="horizontal" variant="filled"
                                     illustrationName="documents" header="Summary"
                                     message={article?.abstract} style={{wordBreak: "break-word"}}/>
                    </div>
                    {article?._source?.navigation?.length > 0 ?
                        <ChapterList list={article._source?.navigation}/> :
                        <>
                            {<div id="doc-preview" className="max-w-lg"
                                  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(article._source.abstract)}}>
                            </div>}
                            {
                                <SearchArticleContent
                                    section={article?._source}
                                    // eslint-disable-next-line no-undef
                                    highlightText={(text) => highlightText(text)} apiClick={() => {
                                }}/>
                            }
                        </>
                    }
                </div>
            </div>

        </div>
    )

}

export {ArticlePreview};
