import React from "react";

function customStyleFromIndex(index) {
    return index === 0 ?
        'w-3/5 xl:w-4/5 mr-12 xl:mr-64 ml-2' :
        'w-2/5 invisible md:visible mr-2'
}

function ArticlesListFieldsDescriptions({fields}) {
    return (
        <div className="flex">
            {
                fields.map((field, index) => (
                    <section
                        className={`text-tertiary font-bold text-sm pt-6 pb-3 ${customStyleFromIndex(index)}`}
                        key={index}
                    >
                        {field}
                    </section>
                    )
                )
            }
        </div>
    )
}

export {ArticlesListFieldsDescriptions}