import {ALL_SELECT_ITEM} from "../constants";

function handleSetFromTargetValue(setter) {
    return (e) => setter(e.target.value)
}

function handleInput(setter, urlParams, urlParamSetter, urlParamKey) {
    return (e) => {
        setter(e.target.value);
        if(!urlParams) return;
        if (!e.target.value) {
            urlParams.delete(urlParamKey)
        } else {
            urlParams.set(urlParamKey, e.target.value)
        }
        urlParamSetter(urlParams)
    }
}

function handleSingleSelect(setter, items, urlParams, urlParamSetter, urlParamKey) {
    return (e) => {
        let updated = e.target.value;
        setter(updated);
        if (!(urlParamSetter && urlParamKey)) return
        if (updated) {
            urlParams.set(urlParamKey, e.target.value.value)
        } else
            urlParams.delete(urlParamKey)
        urlParamSetter(urlParams)
    }
}

function handleMultipleSelect(getter, setter, urlParams, urlParamSetter, urlParamKey) {
     return (e) => {
        let updated = e.target.value;
        if(updated)
            setter(updated);
        if (!(urlParamSetter && urlParamKey)) return

         const previous = []

         urlParams.forEach((value, key) => {
             if (urlParamKey !== key)
                previous.push([key, value])
         })

         const updatedParams = Array.isArray(updated) && updated?.map((select) => {
            return [urlParamKey, select.value]
        })

        if(updatedParams)
            urlParamSetter([
                ...previous,
                ...updatedParams
            ])
    }
}

function handleLocalSingleSelect(queryData, setQueryData) {
    return (e) => {
        const domainValue = e?.target?.value?.value
        if (domainValue === ALL_SELECT_ITEM.value)
            setQueryData(queryData)
        else
            setQueryData(queryData.filter((api) => api._source.domain === e?.target?.value?.value))
    }
}

export {handleInput, handleSingleSelect, handleMultipleSelect, handleLocalSingleSelect, handleSetFromTargetValue}