import {SdfButton, SdfIcon} from "@synerg/react-components";
import React from "react";

function CodeExampleButton({icon, onClick, disabled, data}) {
    return (
        <SdfButton
            emphasis="tertiary"
            action="standard"
            onClick={() => onClick && onClick(data)}
            disabled={disabled}
        >
            <SdfIcon icon={icon}/>
        </SdfButton>
    )
}

export {CodeExampleButton}