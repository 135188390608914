import React from "react";

import {PageTitle} from "./PageTitle";

export function OverviewPageTitle(props) {
	return (
		<div className="text-inverse-default mb-1">
			<PageTitle
				style={{margin: 'auto'}}
				{
					...{
						...props,
						className: `${props.className}`
					}
				}
			/>
		</div>
	)
}