import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import axios from "axios";
import {store} from "../index";

const swaggerApi = createApi({
    reducerPath: 'swagger',
    baseQuery: fetchBaseQuery(),
    endpoints: (builder) => ({
        fetchSwagger: builder.query({
            query: ({url}) => url,
        })
    }),
    overrideExisting: false,
});

export async function loadSample(sampleUrl) {
    const result = await store.dispatch(swaggerApi.endpoints.fetchSwagger.initiate({
        url: encodeURI(sampleUrl),
        forceRefetch: true
    }))

    return result?.data;
}

export async function loadFile(sampleUrl) {
    const response = await axios({
        url: encodeURI(sampleUrl),
        responseType: 'blob',
    })

    return await response?.data;
}

export const {useFetchSwaggerQuery} = swaggerApi;
export {swaggerApi};
