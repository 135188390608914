import React from "react";
import {SdfPromoBox, SdfSpotIllustration} from "@synerg/react-components";
import {useSelector} from "react-redux";
import LinkWithIcon from "../LinkWithIcon";
import ChapterList from "./ChapterList";
import {transformDateToLongWithComma} from "../../utility/dateUtil";
import {PageTitle} from "../PageTitle";
import useMediaQuery from "../../hooks/useMediaQuery";
import {ContentsMenu} from "../articles/ContentsMenu";
import {ShouldLoginBox} from "../ShouldLoginBox";

export function GuideContent({content}) {
    const isLgOrBigger = useMediaQuery('(min-width: 1024px)')

    const isLoggedIn = useSelector((state) => {
        return state.auth.isLoggedIn;
    });

    return (
        <div>
            <div className="overflow-x-auto">
                 <PageTitle
                    text={content.title}
                    className="mb-2"
                />
                <div className="text-tertiary mb-6">
                    Last updated: {
                    transformDateToLongWithComma(
                        new Date(content?.date)
                    )
                }
                </div>
                <div className="flex space-x-5">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col md:flex-row lg:flex-row gap-2 w-full">
                            <SdfPromoBox layout="horizontal" variant="filled"
                                         illustrationName="documents" header="Summary"
                                         message={content.abstract} className="md:w-4/5 lg:w-4/5"/>
                            {(content?.isPublic || isLoggedIn) && content?.pdfLink &&
                                <div className="flex flex-shrink bg-accent rounded-lg p-2 w-2/5 sm:w-2/5 md:flex-grow md:w-1/5 lg:flex-grow lg:w-1/5">
                                    <a href={content.pdfLink} target="_blank" rel="noreferrer"
                                       className="flex w-full h-full flex-col justify-center items-center text-base font-medium no-underline">
                                        <span className="text-default font-bold text-heading-03">Download Guide</span>
                                        <SdfSpotIllustration illustrationName="cloud-arrow-down"
                                                             size="sm"/>
                                        <LinkWithIcon icon="action-download-cloud" external
                                                      to={content.pdfLink}> Download PDF</LinkWithIcon>
                                    </a>
                                </div>
                            }
                        </div>
                        {content?.chapters?.length > 0 &&
                            (content?.isPublic || isLoggedIn) &&
                            <ChapterList list={content.chapters}/>
                        }
                        {!content?.isPublic && !isLoggedIn &&
                            <div className="flex pt-4 flex-col justify-center items-center w-4/5">
                                <ShouldLoginBox />
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                ((!content.isPublic && isLoggedIn) || content.isPublic) &&
                <ContentsMenu
                    article={content}
                    isLgOrBigger={isLgOrBigger}
                    className="mt-8"
                />
            }
        </div>
    )

}

