import React from "react";
import LinkWithIcon from "../../LinkWithIcon";
import postmanLogo from "../../../assets/images/postman-logo.svg";
import {useSelector} from "react-redux";
import {handleDownload} from "../../../utility/apiExplorerUtils";
import {ARTICLES_BORDER} from "../../../utility/constants";

export function PostmanBox({postmanCollection, product}) {
    const swaggerUrl = useSelector(state => {
        return state.swaggerMeta.url
    })

    async function onHandleDownloadPostmanCollection() {
        const fileName = postmanCollection.example.href;

        const fileUrl = swaggerUrl + '/' + fileName

        await handleDownload({fileUrl, fileName});
    }

    return (
        <>

            <div className={`${ARTICLES_BORDER} border-2 flex flex-col items-center rounded-lg`}>
                <img src={postmanLogo} alt="postmanLogo" className="py-4 mx-4"/>
                <span className="text-heading-05 font-bold">Postman</span>
                <span
                    className="text-body-02 text-center pt-3 px-3">{`Test these endpoints for ${product?.title} with Postman`}</span>
                <div className="text-center font-medium py-6" onClick={onHandleDownloadPostmanCollection}>
                    <LinkWithIcon icon="action-download-cloud"> Download Postman Collection</LinkWithIcon>
                </div>
            </div>
        </>
    )
}