import {createSlice} from "@reduxjs/toolkit";

export const articleSlice = createSlice({
    name: 'article',
    initialState: {
        selectedArticleContent: ''
    },
    reducers: {
        selectedArticle(state, action) {
            state.selectedArticleContent = action.payload;
        }
    }
});