import {createSlice} from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isLoggedIn: false,
        userInfo: {
            givenName: '',
            familyName: '',
            permissions: []
        }
    },
    reducers: {
        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload;
        },
        setUserInfo(state, action) {
            state.userInfo = action.payload;
        }
    }
})

export {authSlice};