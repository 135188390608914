import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";


const userApi = createApi({
    reducerPath: 'user',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            headers.append('Content-Type', 'application/json');
            headers.append('X-Requested-With', 'XMLHttpRequest');
            headers.append('Content-Length', 0);
            headers.append('Accept', 'application/json');
        },
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        fetchUser: builder.query({
            query: (queryObj) => {
                return {
                    url: `${process.env.REACT_APP_API_PATH}/userInfo`,
                    method: 'GET',
                }
            },
        })
    })
});

export const {useFetchUserQuery} = userApi;
export {userApi};
