import {createSlice} from "@reduxjs/toolkit";

export const swaggerSlice = createSlice({
    name: 'swaggerMeta',
    initialState: {
        url: ''
    },
    reducers: {
        setSwaggerUrl(state, action) {
            state.url = action.payload;
        }
    }
});