import React from 'react';
import ArticlesListWithFilter from "../../components/articles/ArticlesListWithFilter";
import {FILTERS} from "../../components/articles/filters";
const breadcrumbPath = [
    { name: 'Welcome', route: '/welcome'},
    { name: 'Learn Overview', route: '/learn/learn-overview' },
    { name: 'FAQs', route: '/learn/faqs' }
];

function FaqsPage() {
    return (
        <ArticlesListWithFilter
            pageTitle="FAQs"
            navigatePath="/learn/faqs"
            breadcrumbPath={breadcrumbPath}
            fieldsDescriptions={['TOPIC', 'LAST UPDATED']}
            filtersDefinition={[
                FILTERS.inputWithoutMax,
                FILTERS.sortBy,
            ]}
            customArticlesQuery={{
                tags: ['faq'],
                index: 'faq'
            }}
        />
    )
}

export default FaqsPage;
