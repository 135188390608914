import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {SdfBusyIndicator, SdfSelectSimple} from "@synerg/react-components";
import {articlesApiTransformByType} from '../../../utility/transform/articlesApi';
import {PageTitle} from "../../PageTitle";
import {useFetchArticlePreviewQuery} from "../../../store/apis/articlesApi";
import Breadcrumb from "../../Breadcrumb";
import {getBreadcrumb} from "../../../utility/articlesUtils";
import {LoadHtmlFromString} from "../../LoadHtmlFromString";
import {NavChapter} from "../NavChapter";

export function GuideChapterPreview() {
    const [searchParams] = useSearchParams()
    const chapter = searchParams.get('chapter')
    const {articleId} = useParams();
    const navigate = useNavigate();
    const [prevChapter, setPrevChapter] = useState();
    const [nextChapter, setNextChapter] = useState();
    const currentChapter = Number(chapter);
    const currentChapterIndex = Number(chapter) - 1;
    const guidePath = `/articles/preview/${articleId}`;

    let {articleItem} = useSelector((state) => {
        const {isLoggedIn} = state.auth
        return {
            isLoggedIn,
            articleItem: state.useArticles.selectedArticleContent
        };
    });

    const {isLoading, isSuccess, data} = useFetchArticlePreviewQuery({articleId})

    if (isSuccess) {
        articleItem = articlesApiTransformByType(data?.hits?.hits[0]);
    }

    // Needed because user can visualize preview from only chapter preview and navigated from guide preview chapter list
    const cameFromCmsChapterPreviewModal = !(!!articleItem?._source?.navigation)

    const chapterList = articleItem?._source?.navigation?.map((chapter, index) => {
        return {label: `Ch. ${index + 1}: ${chapter.title}`, value: index + 1};
    })

    const mapChapter = () => {
        const calcPrev = currentChapter === 1 ? '' : articleItem?._source?.navigation?.[currentChapter - 2]?.title;
        const lastChapter = articleItem?._source?.navigation?.length;
        const calcNext = currentChapter === lastChapter ? '' : articleItem?._source?.navigation?.[currentChapter]?.title;
        setPrevChapter(calcPrev);
        setNextChapter(calcNext);
    }

    const mapGuide = {
        'mkplIntegration': 'marketplace-integration-guides',
        'productIntegration': 'product-integration-guides'

    }

    const breadcrumbPath = getBreadcrumb(mapGuide[articleItem?._source?.guideType], articleItem.title);

    useEffect(() => {
        mapChapter()
    }, [currentChapter]);

    const onChangeChapter = (event) => {
        const chapter = event.detail.value;
        mapChapter(chapter);
        navigate(`${guidePath}?chapter=${chapter}`)

    }

    const handlePrev = () => {
        if (currentChapter !== 1) {
            const prevChapter = currentChapter - 1
            mapChapter(prevChapter);
            navigate(`${guidePath}?chapter=${prevChapter}`)
        }
    }

    const handleNext = () => {
        const lastChapter = articleItem?._source?.navigation?.length;
        if (currentChapter !== lastChapter) {
            const nextChapter = currentChapter + 1;
            mapChapter(nextChapter);
            navigate(`${guidePath}?chapter=${nextChapter}`)
        }
    }

    return (
        isLoading ? <SdfBusyIndicator/> :
            <div className="no-print px-12">
                <>
                    {!cameFromCmsChapterPreviewModal && <Breadcrumb path={breadcrumbPath}/>}
                    <div className="flex items-center justify-between mt-6">
                        <PageTitle
                            text={articleItem.title}
                            className="text-heading-07"
                        />
                        {!cameFromCmsChapterPreviewModal && <SdfSelectSimple
                            className="pr-20"
                            label="Jump to chapter"
                            inputWidth_md="md"
                            items={chapterList}
                            value={chapterList?.[currentChapterIndex]}
                            onSdfChange={onChangeChapter}
                        />}
                    </div>


                    {!cameFromCmsChapterPreviewModal && <div className="flex justify-between w-3/4 py-3 pr-2">
                        <div className="text-tertiary font-medium">CHAPTER {currentChapter}</div>
                    </div>}


                    <div className="text-heading-06 font-bold pt-2">
                        {cameFromCmsChapterPreviewModal ? articleItem?.abstract : articleItem?._source?.navigation?.[currentChapterIndex].title}
                    </div>


                    <div className="flex justify-between">
                        <LoadHtmlFromString
                            htmlStr={cameFromCmsChapterPreviewModal ? articleItem?._source?.content : articleItem?._source?.navigation?.[currentChapterIndex].content}/>
                    </div>


                    {!cameFromCmsChapterPreviewModal && <div className="flex justify-between pt-12">
                        <NavChapter
                            isPrev
                            chapterTitle={prevChapter}
                            onClick={handlePrev}
                        />
                        <NavChapter
                            chapterTitle={nextChapter}
                            onClick={handleNext}
                        />
                    </div>}
                </>
            </div>
    )
}

