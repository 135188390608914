import React from "react";

import {SdfFloatingPane, SdfLink, SfcShellAppBarIcon} from "@synerg/react-components";
import {SHELL_NAV_ICON} from "../utility/constants";

export function NavHelp() {
    return (
        <>
            <SfcShellAppBarIcon
                slot="icons"
                icon="action-help"
                className={`${SHELL_NAV_ICON} md:mr-4`}
                label="Help"
            />

            <SdfFloatingPane
                show-arrow
                auto-attach
                portalEnabled
                style={{zIndex: '1062'}}
            >
                <div className="w-xs p-5 text-default">
                    <div className="font-bold text-lg">
                        Need help?
                    </div>
                    <div className="font-normal mt-1 text-sm leading-sm">
                        Please visit the <SdfLink href="/support/help-center">
                        support page </SdfLink> to get access to support resources as either a partner or a client.
                    </div>
                </div>
            </SdfFloatingPane>
        </>
    )
}