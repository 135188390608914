import React from 'react';
import {SdfIcon} from "@synerg/react-components";

export function ErrorMessage({textMessage}) {
	return (
		<label className="text-alert-error text-sm">
			<SdfIcon icon="alert-error" className="mr-1"/>
			{textMessage}
		</label>
	)
}