import React from 'react';
import ArticlesListWithFilter from "../../../components/articles/ArticlesListWithFilter";
import {FILTERS} from "../../../components/articles/filters";
import {mergeObjects} from "../../../utility/ObjectUtil";
import {InputWidthEnum} from "../../../utility/enums/InputWidthEnum";

const breadcrumbPath = [
    {name: 'Welcome', route: '/welcome'},
    {name: 'Build Overview', route: '/build/overview'},
    {name: 'Marketplace Integration Guides', route: '/build/guides/marketplace-integration-guides'},
]

const INPUT_WIDTH_MD = InputWidthEnum.FULL

function MarketplaceIntegrationGuidesPage() {
     return (
         <ArticlesListWithFilter
            pageTitle="Marketplace Integration Guides"
            navigatePath="/build/guides/marketplace-integration-guides"
            breadcrumbPath={breadcrumbPath}
            fieldsDescriptions={['GUIDE TITLE/DESCRIPTION', 'LAST UPDATED']}
            filtersDefinition={[
                FILTERS.input,
                mergeObjects([FILTERS.browseBySolution, {inputWidthMd: INPUT_WIDTH_MD}]),
                mergeObjects([FILTERS.sortBy, {inputWidthMd: INPUT_WIDTH_MD}]),
            ]}
            description={{
                illustrationName: 'dashboard',
                text: 'Use these guides to learn how to integrate with the Marketplace storefront and build inside the ADP API ecosystem.'
            }}
            customArticlesQuery={{
                tags: ['guide'],
                index: "guides",
                guideType: "mkplIntegration"
            }}
        />
     )
}

export default MarketplaceIntegrationGuidesPage;
