import React, {useState} from "react";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {SfcShellLeftNav} from "@synerg/react-components/dist/sfc-components";
import useMenu from "../routes/menu";
import {findInNestedArray} from "../utility/ObjectUtil";
import {ARTICLES_BORDER_RIGHT} from "../utility/constants";
import './articles/previews/GuidesPrintView/WholeGuidePrintView/style.css'

function LeftNav() {
    const [{appRoutes}] = useMenu();
    const [allRoutes] = useState(appRoutes);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const isPrintView = Boolean(searchParams.get('printView'))
    
    const activeItem = findInNestedArray(allRoutes, 'pathname', window.location.pathname, false) ||
        findInNestedArray(allRoutes, 'pathname', window.location.pathname, true);

    return (
        !isPrintView &&
        <SfcShellLeftNav
            className={`no-print ${ARTICLES_BORDER_RIGHT} pt-6`}
            id='nav'
            slot='nav'
            activeItem={activeItem}
            role='navigation'
            menuProvider={allRoutes}
            onSfcNavigate={(event) => {
                event.preventDefault();
                navigate(event.detail.pathname);
            }}
            data-testid='left-nav'
        />
    )

}


export default LeftNav;
