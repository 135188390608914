import React from 'react';
import {SdfBusyIndicator, SdfLink} from '@synerg/react-components';
import ApiExplorerPopularProducts from '../../../components/api-explorer/ApiExplorerPopularProducts';

import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from "react-router-dom";
import {selectProduct, useFetchProductsQuery} from '../../../store';

import Icon from '../../../assets/images/LaptopCode.svg';
import {OverviewPageTitle} from "../../../components/OverviewPageTitle";
import {OVERVIEW_TEXT_IMAGE_TOP, OVERVIEW_TOP_IMAGE} from "../../../utility/constants";


const SECTION_TITLE_FONT_SIZE = 'text-heading-06'
const SECTION_TITLE_FONT_WEIGHT = 'font-black'


function ApiExplorerPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {pathname} = useLocation();


    const {
        data: apiExplorerData,
        error: apiExplorerError,
        isLoading: apiExplorerIsLoading,
        isSuccess: apiExplorerIsSuccess
    } = useFetchProductsQuery();

    const onHandleNavigate = (product) => {
        dispatch(selectProduct(product));
        navigate(`${pathname}/${product.key}`);
    }

    const isInternationalProduct = (product) => product.internationalOffering
    const isAdditionalProduct = (product) => (!product.isPopular && !product.internationalOffering)

    return (
        <div>
            <div className="bg-action-primary-900">
                <div className={OVERVIEW_TEXT_IMAGE_TOP}>
                    <section>
                        <OverviewPageTitle text="API Explorer" />
                        <div className="text-heading-07 font-black text-inverse-default mb-1">
                            Develop integrations with <br/> ADP APIs
                        </div>
                        <div className="text-lg font-normal text-inverse-default">
                            Choose a category below to view supported APIs and <br/> specific payload samples.
                        </div>
                    </section>

                    <img
                        alt='Tools'
                        src={Icon}
                        className={OVERVIEW_TOP_IMAGE}
                    />
                </div>
            </div>

            <div>
                <section className='px-4 md:px-12 py-4 md:py-8'>
                    <div className='flex justify-between items-center'>
                        <span className={`${SECTION_TITLE_FONT_SIZE} ${SECTION_TITLE_FONT_WEIGHT} tracking-sm`}>Featured APIs for Integration</span>
                    </div>

                    <div>
                        {apiExplorerIsLoading ? <SdfBusyIndicator/> : ''}
                        {apiExplorerError ? <h3>Error loading API Explorer products</h3> : ''}
                        {apiExplorerIsSuccess && <ApiExplorerPopularProducts products={apiExplorerData}/>}
                    </div>
                </section>

                <section className='px-4 md:px-12 py-10 bg-gray-50'>
                    <div className='flex justify-between items-center'>
                        <span className={`${SECTION_TITLE_FONT_SIZE} ${SECTION_TITLE_FONT_WEIGHT} tracking-sm`}>Additional Integrations</span>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-4'>
                        {apiExplorerIsLoading ? <SdfBusyIndicator/> : ''}
                        {apiExplorerError ? <h3>Error loading API Explorer products</h3> : ''}
                        {apiExplorerData && apiExplorerData.filter(isAdditionalProduct).map((product, index) => (
                            <div key={index} className="mr-4">
                                <SdfLink
                                    className="py-4 text-inherit no-underline cursor-pointer font-medium"
                                    onClick={() => onHandleNavigate(product)}
                                >
                                    {product.title}
                                </SdfLink>
                            </div>
                        ))}
                    </div>
                    <div className='pt-12'>
                        <div className='text-heading-04 font-bold'>International Integrations</div>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pt-4'>
                            {apiExplorerIsLoading ? <SdfBusyIndicator /> : ''}
                            {apiExplorerError ? <h3>Error loading API Explorer products</h3> : ''}
                            {apiExplorerData && apiExplorerData.filter(isInternationalProduct).map((product, index) => (
                                <div key={index} className="mr-4">
                                    <SdfLink
                                        className="py-4 text-inherit no-underline cursor-pointer font-medium"
                                        onClick={() => onHandleNavigate(product)}
                                    >
                                        {product.title || product.name}
                                    </SdfLink>
                                </div>

                            ))
                            }
                        </div>
                    </div>
                </section>

            </div>
        </div>
    )
}

export default ApiExplorerPage;
