import React from 'react';
import {SdfFocusPane} from "@synerg/react-components";
import {CodeBox} from "../../CodeBox";

export function MaximizedCode({visible, strData, onSdfDismiss, contentType}) {
    return (
        <SdfFocusPane
            visible={visible}
            id="eventModal"
            size="full"
            heading="Code sample"
            onSdfAccept={onSdfDismiss}
            onSdfDismiss={onSdfDismiss}
            closeable
            hideDismissButton
            dismissOnClickOutside
            acceptLabel="Close"
        >
            <CodeBox
                strData={strData}
                contentType={contentType}
                customStyle="bg-white h-full"
            />

        </SdfFocusPane>
    )
}