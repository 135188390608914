import React from "react";
import {BaseModal} from "./BaseModal";
import {SdfLink} from "@synerg/react-components";

export function EventsModal({events, closeModal, apiTitle, productTitle, size}) {
    const itemsWidthClass = "w-2/5"
    const jsonCodeBoxWidthClass = "w-3/5"
    return (
        <BaseModal
            handlers={{closeModal}}
            data={events}
            headerTitle="Supported Event Notifications"
            size={size}
            widthClasses={{itemsWidthClass, jsonCodeBoxWidthClass}}
        >
            <span>
                When integrating with an ADP product you can choose which notifications you’d like to receive from the event queue.
                Want to learn more about <SdfLink target="_blank"
                                                  href="https://developers.adp.com/articles/general/adp-event-apis-and-event-notification-guide">event handling</SdfLink>?
            </span>
            <div className="text-heading-04 font-bold pt-4">{apiTitle}</div>

            <div className="flex py-1">
                <div className={`flex justify-between ${itemsWidthClass} text-body-02 text-alert-neutral`}>
                    <span>{productTitle}</span>
                    <span>{`${events?.length} events`}</span>
                </div>

                <div className={`${jsonCodeBoxWidthClass}`}>
                    <span className="flex justify-end text-body-02 text-alert-neutral">Sample Payload</span>
                </div>
            </div>
        </BaseModal>
    )
}