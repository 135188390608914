import React from 'react';
import OneuxLayout from "./components/oneux-layout";
import {SdfBusyIndicator} from "@synerg/react-components";
import useLoadingEventListener from "./hooks/useEventListener";

function App() {
    const isLoading = useLoadingEventListener('sdfLoaded', !window.SynergThemes)

    if(window.location.pathname.includes('/articles/preview/')) return <OneuxLayout />;

    if(window.location.pathname.includes('/usecases-preview/')) return <OneuxLayout />;

    return (
        isLoading ?
        <div className="app-load">
          <SdfBusyIndicator
            style={{ color: "#1a8099" }}
            size="xl"
            fullScreen={true}
          ></SdfBusyIndicator>
        </div> :
        <OneuxLayout />
    )
}

export default App;
