import {
    getAllArticles,
    getArticlesByParams,
    getIntegrationBySolutionArticles
} from "../store/services/elasticSearchService";

export const closeAppBarSearch = () => {
    document.querySelector('sfc-shell-app-bar-search').close();
}

export const clearAppBarSearch = () => {
    document.querySelector('sfc-shell-app-bar-search').clear();
}

export const highlightText = (text, searchTerm) => {
    if (typeof text !== "string") return;
    if (!searchTerm) {
        return text;
    }
    try {
        const regex = new RegExp(searchTerm, 'gi');
        const matches = text?.match(regex);
        if (!matches) {
            return text;
        }
        const elements = text.split(/(<[^>]+>)/gi);
        const highlightedElements = elements.map((element) => {
            if (element.startsWith("<") && element.endsWith(">")) {
                return element;
            }
            
            const highlightedText = element.replace(regex, '<mark>$&</mark>');
            return highlightedText;
        });
        const highlightedText = highlightedElements.join("");
        return highlightedText;

    } catch (e) {
        return text;
    }
};

// const commonParams = {
//     searchQuery: '',
//     guideType: '',
//     tag: '',
//     index: '',
//     from: 0,
//     size: 10,
// }

export const categoryList = {
        all: {
            label: 'All Categories',
            id: 'all',
            isActive: true,
            index: '',
            tags: 'all',
            type: '',
            service: async ({searchQuery, from, size}) => await getAllArticles({searchQuery, from, size, sort: [{}]})
        },
        apiExplorer: {
            label: 'API Explorer',
            id: 'apiExplorer',
            isActive: false,
            index: 'api',
            tags: 'apiexplorer',
            type: '',
            highlight: 'API',
            service: async ({...commonParams}) => await getArticlesByParams({
                ...commonParams
            })

        },
        productIntegration: {
            label: 'Product Integration Guides',
            id: 'productIntegration',
            isActive: false,
            index: 'guides',
            tags: 'guide',
            type: 'productIntegration',
            highlight: 'PRODUCT INTEGRATIONS GUIDES',
            service: async ({...commonParams}) => await getArticlesByParams({
                ...commonParams
            })

        },
        marketplaceIntegration: {
            label: 'Marketplace Integration Guides',
            id: 'marketplaceIntegration',
            isActive: false,
            index: 'guides',
            tags: 'guide',
            type: 'mkplIntegration',
            highlight: 'MARKETPLACE INTEGRATIONS GUIDES',
            service: async ({...commonParams}) => await getArticlesByParams({
                ...commonParams
            })
        },
        integrationBySolution: {
            label: 'Integrations by Solution',
            id: 'integrationBySolution',
            isActive: false,
            index: '',
            tags: 'usecases',
            type: '',
            highlight: 'USECASE',
            service: async ({searchQuery}) => await getIntegrationBySolutionArticles({searchQuery})
        },
        faqs: {
            label: 'FAQs',
            id: 'faqs',
            isActive: false,
            index: 'faq',
            tags: 'faq',
            type: '',
            highlight: 'FAQ',
            service: async ({...commonParams}) => await getArticlesByParams({
                ...commonParams
            })
        },
        howToArticles: {
            label: 'How-to Articles',
            id: 'howToArticles',
            isActive: false,
            index: 'general',
            tags: 'howto',
            type: '',
            highlight: 'HOW TO',
            service: async ({...commonParams}) => await getArticlesByParams({
                ...commonParams
            })
        },
        keyConcepts: {
            label: 'Key Concepts',
            id: 'keyConcepts',
            isActive: false,
            index: 'general',
            tags: 'keyconcept',
            type: '',
            highlight: 'KEY CONCEPT',
            service: async ({...commonParams}) => await getArticlesByParams({
                ...commonParams
            })
        }
    }
;
