import React from "react";
import {SdfPromoBox} from "@synerg/react-components";

export function NoData() {
    return (
        <SdfPromoBox
            className="my-6"
            header="We didn't find a match for your search."
            illustration-name="empty-magnifying-glass"
            align="center"
            message="Adjust your search and try again."
            id="no-data"
            variant="clear"
        />
    )
}