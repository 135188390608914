import React from "react";
import {SdfPromoBox} from "@synerg/react-components";
import {useSelector} from "react-redux";
import SearchArticleContent from './SearchArticleContent';
import {PageTitle} from "../PageTitle";
import {ContentsMenu} from "./ContentsMenu";
import './previews/GuidesPrintView/ChapterPrintView/style.css';
import {RateArticle} from "./RateArticle";
import useMediaQuery from "../../hooks/useMediaQuery";
import {LoadHtmlFromString} from "../LoadHtmlFromString";
import {ShouldLoginBox} from "../ShouldLoginBox";

function ArticleContent({content}) {
    const isLgOrBigger = useMediaQuery('(min-width: 1024px)')

    const isLoggedIn = useSelector((state) => {
        return state.auth.isLoggedIn;
    });

    const highlightText = (text) => {
        return text;
    };

    return (
        <div className="flex justify-between">
            <div className="overflow-x-auto w-11/12">
                {isLgOrBigger ?
                    <PageTitle
                        text={content.title}
                        className="mb-2"
                    /> :
                    <div className="flex flex-col justify-between">
                        <PageTitle
                            text={content.title}
                            className="mb-0"
                        />
                        <div className="mb-8 ml-0">
                            <RateArticle article={content}/>
                        </div>
                    </div>}
                <div className="flex space-x-5">
                    <div className="flex flex-col w-full">
                        <div className="flex w-full">
                            <div className={content?.isLearnArticle ? 'w-full' : 'w-4/5'}>
                                <SdfPromoBox layout="horizontal" variant="filled"
                                             illustrationName="documents" header="Summary"
                                             message={content.abstract} style={{wordBreak: "break-word"}}/>
                            </div>
                        </div>
                        <>
                            {(content?.isPublic || isLoggedIn) &&
                                <div className="w-full overflow-x-auto">
                                    <LoadHtmlFromString htmlStr={content._source.content}/>
                                </div>}
                            {(!content?.isPublic || isLoggedIn) &&
                                <SearchArticleContent
                                    section={content?._source}
                                    highlightText={(text) => highlightText(text)} apiClick={() => {
                                }}/>
                            }
                        </>
                        {!content?.isPublic && !isLoggedIn &&
                            <div className="flex pt-4 flex-col justify-center items-center">
                                <ShouldLoginBox />
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                ((!content.isPublic && isLoggedIn) || content.isPublic) &&
                <ContentsMenu
                    article={content}
                    isLgOrBigger={isLgOrBigger}
                    className="mt-8"
                />
            }
        </div>
    )

}

export default ArticleContent;
