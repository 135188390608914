import React from 'react';
import Icon from '../../assets/images/Chat.svg'
import {OverviewPageTitle} from "../../components/OverviewPageTitle";
import {OVERVIEW_TEXT_IMAGE_TOP, OVERVIEW_TOP_IMAGE} from "../../utility/constants";
import {BriefCard} from "../../components/BriefCard";

function SupportPage() {
    return (
        <div>
            <div className="bg-action-primary-900">
                <div className={OVERVIEW_TEXT_IMAGE_TOP}>
                    <section>
                        <OverviewPageTitle text="Support"/>
                        <div className="text-heading-07 font-black text-inverse-default mb-1">Get the support you need when
                            you <br/>need it most
                        </div>
                        <div className="text-lg font-normal text-inverse-default">                            
                            Learn where to get help as a Marketplace partner or as an ADP client.
                        </div>
                    </section>
                    <img
                        alt='Book icon'
                        src={Icon}
                        className={OVERVIEW_TOP_IMAGE}
                    />
                </div>
            </div>
            <div className="sm:mx-12 md:ml-12 lg:ml-12 h-md mb-2 pt-5">
                <div className="flex justify">
                    <BriefCard
                        title="Help Center"
                        subtitle={<>Find where to get help as an ADP<br/>Marketplace Partner or ADP Client</>}
                        footer="Learn more"
                        to="/support/help-center"
                        size="w-xs"
                    />
                </div>
            </div>
        </div>
    )
}

export default SupportPage;
