class ArticlesFilterQueriesEnum {
    static QUERY = 'query';
    static SORT = 'sort';
    static INTEGRATION_CATEGORY = 'mkplIntegrationCategory';
    static USECASE_TAGS = 'useCaseTags';
    static API_PRODUCT = 'adpProducts';
    static API_PRODUCT_CODE = 'apiProductCode';
    static ID = 'id';
    static TAG = 'tag';
}

export {ArticlesFilterQueriesEnum}