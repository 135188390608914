import React, {useState} from "react";
import {SfcShellAppBar, SfcShellAppBarSearch} from "@synerg/react-components";
import SearchResults from "../pages/SearchResults";
import NavLoginUser from "./LoginLogout";
import {NavHelp} from "./NavHelp";
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {NavFeedback} from "./shell-app-bar/NavFeedback";

import './articles/previews/GuidesPrintView/WholeGuidePrintView/style.css'

export default function AppBar() {
    const [searchKey, setSearchedKey] = useState('');
    const [isClose, setIsClose] = useState(false);
    const [searchParams] = useSearchParams()
    const navigate = useNavigate();
    const location = useLocation();
    const shouldHideSearchPreview = window.innerWidth < 1024 || location.pathname === '/searchresults';

    const onChangeHandler = (evt) => {
        setSearchedKey(evt.target.value);

        if (!shouldHideSearchPreview) return

        navigateToSearchResult(evt.target.value)
    }

    const onClose = () => {
        setIsClose(!isClose);
    }

    const navigateToSearchResult = (query) => {
        searchParams.set('index', '')
        searchParams.set('from', '0')
        searchParams.set('size', '10')
        searchParams.set('query', (query !== undefined) ? query : searchKey)

        return navigate({
            pathname: "/searchresults",
            search: searchParams.toString()
        })
    }

    const handleOnSfcSearch = (e) => {
        if (e.detail) {
            navigateToSearchResult(e.detail);
        }
    }

    const appBarClicked = (e) => {
        if (e.clientX > 61 && e.clientX < 81 && e.clientY > 26 && e.clientY < 50) {
            navigate('/welcome')
        }
    }

    return (
        <SfcShellAppBar
            className="no-print cursor-pointer"
            style={{zIndex: '1061'}}
            slot="app-bar"
            size="default"
            data-testid='app-header'
            onClick={(e) => appBarClicked(e)}
        >
            <div className="cursor-pointer" onClick={() => navigate('/welcome')}>
                <div className="logo-divider no-branding"/>
                <div className="branding-area font-semibold">Developer Resources</div>
            </div>
            <SfcShellAppBarSearch
                slot="search"
                onSfcQueryChanged={onChangeHandler}
                onSfcPanelClosed={onClose}
                onSfcSearch={handleOnSfcSearch}
            >
                {
                    !shouldHideSearchPreview &&
                    <SearchResults
                        searchedKey={searchKey}
                        panelClosed={isClose}
                        navigateToSearchResult={navigateToSearchResult}
                    />
                }
            </SfcShellAppBarSearch>

            <NavFeedback/>

            <NavHelp/>

            <NavLoginUser/>

        </SfcShellAppBar>


    );
}
