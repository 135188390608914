import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {getClickBody, getElasticQuery} from "../../utility/FetchingUtils";


const elasticSearchApi = createApi({
    reducerPath: 'elasticSearchApi',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            headers.append('Content-Type', 'application/json');
            headers.append('X-Requested-With', 'XMLHttpRequest');
            headers.append('Content-Length', 0);
            headers.append('Accept', 'application/json');
        },
        credentials: 'include'
    }),
    endpoints(builder) {
        return {
            fetchElasticSearch: builder.query({
                query: (queryObj) => {
                    return {
                        url: `${process.env.REACT_APP_API_PATH}/services/elasticsearch/${queryObj.index}/_doc/all`,
                        method: 'POST',
                        body: getElasticQuery(queryObj)
                    }
                },
            }),
            fetchElasticSearchById: builder.query({
                query: (queryObj) => {
                    return {
                        url: `${process.env.REACT_APP_API_PATH}/services/elasticsearch/${queryObj.index}/_doc/${queryObj.id}`,
                        method: 'POST',
                        body: {
                            query: "",
                            sort: [{}]
                        }
                    }
                },
            }),
            updateMetrics: builder.mutation({
                query: (queryObj) => {
                    return {
                        url: `${process.env.REACT_APP_API_PATH}/services/elasticsearch/${queryObj.type || "articles"}/click`,
                        method: 'POST',
                        body: getClickBody(queryObj)
                    }
                }
            }),
            postElasticSearchById: builder.mutation({
                query: (queryObj) => {
                    return {
                        url: `${process.env.REACT_APP_API_PATH}/services/elasticsearch/${queryObj.index}/_doc/${queryObj.id}`,
                        method: 'POST',
                        body: {
                            query: "",
                            sort: [{}]
                        }
                    }
                },
            }),
        }
    }
});


export const {useFetchElasticSearchQuery, useFetchElasticSearchByIdQuery, useUpdateMetricsMutation, usePostElasticSearchByIdMutation} = elasticSearchApi;
export {elasticSearchApi};
