import {createApi} from "@reduxjs/toolkit/query/react";
import {generateBaseQuery} from "../../utility/storeUtil";


const articlesMetaApi = createApi({
    reducerPath: 'domains',
    baseQuery: generateBaseQuery(),
    endpoints(builder) {
        return {
            fetchDomains: builder.query({
                query: () => ({
                    url: `${process.env.REACT_APP_API_PATH}/services/elasticsearch/collections/_doc/all/apiexplorer`,
                    method: 'GET'
                }),
                transformResponse: (response) => {
                    return [
                        ...response.hits.hits
                        .filter((collection) => (
                            collection._source.tags.includes('apiexplorer') && collection._source.title !== 'API Explorer')
                        )
                        .map((collection) => ({
                            label: collection._source.title,
                            value: collection._source.articles.url.tags ? collection._source.articles.url.tags[0] : 'all'
                        }))
                    ]
                        .sort((a, b) => a.label.localeCompare(b.label))
                }
            }),
        }
    }
});

export const {useFetchDomainsQuery} = articlesMetaApi;
export {articlesMetaApi};
