import React, {useEffect, useState} from "react";

import {SHELL_NAV_ICON} from "../../utility/constants";
import {
	SdfAlert,
	SdfFocusPane,
	SdfFormControlLayout, SdfInput,
	SdfRadioButton,
	SdfRadioGroup,
	SdfSelectSimple,
	SdfTextarea,
	SfcShellAppBarIcon
} from "@synerg/react-components";
import {handleSetFromTargetValue} from "../../utility/handlers/formHandler";
import {usePostFeedbackMutation} from "../../store";
import {ErrorMessage} from "../ErrorMessage";
import {EMAIL_REGEX} from "../../utility/stringUtil";
import useMediaQuery from "../../hooks/useMediaQuery";

const INITIAL_STATES = {
	feedbackTopic: null,
	feedbackText: '',
	isContactAllowed: null,
	contactEmail: '',
	feedbackRequestStatus: null
}

const FEEDBACK_SELECT_TOPICS = [
	{
		label: 'Developer Resources website',
		value: 'developerResources'
	},
	{
		label: 'API feedback',
		value: 'apiFeedback'
	},
	{
		label: 'Specific documentation',
		value: 'specificDocumentation'
	},
	{
		label: 'Other',
		value: 'other'
	},
]

const FORM_FIELD = 'mt-4 w-full'

const ALERTS_DATA = {
	isSuccess: {
		text: 'Thank you! Your feedback will be reviewed by our team.',
		status: "success"
	},
	isError: {
		text: 'An error occurred while sending the feedback.',
		status: "error"
	}
}

export function NavFeedback() {
	const isSmallMobile = useMediaQuery('(max-width: 768px)')
	const [showFeedbackForm, setShowFeedbackForm] = useState(false);
	const [feedbackTopic, setFeedbackTopic] = useState(INITIAL_STATES.feedbackTopic)
	const [feedbackText, setFeedbackText] = useState(INITIAL_STATES.feedbackText)
	const [isContactAllowed, setIsContactAllowed] = useState(INITIAL_STATES.isContactAllowed)
	const [contactEmail, setContactEmail] = useState(INITIAL_STATES.contactEmail)
	const [postFeedback, postFeedbackResults] = usePostFeedbackMutation()
	const [feedbackRequestStatus, setFeedbackRequestStatus] = useState(INITIAL_STATES.feedbackRequestStatus)

	const isContactEmailCompliant = contactEmail ? EMAIL_REGEX.test(contactEmail) : true
	const disableAcceptButton = (
		(feedbackTopic === INITIAL_STATES.feedbackTopic) ||
		(feedbackText === INITIAL_STATES.feedbackText) ||
		(isContactAllowed === INITIAL_STATES.isContactAllowed) ||
		(isContactAllowed && (!contactEmail || !isContactEmailCompliant))
	)

	useEffect(() => {
		if(
			!postFeedbackResults.isLoading &&
			(postFeedbackResults.isSuccess || postFeedbackResults.isError)
		) {
			setFeedbackRequestStatus(ALERTS_DATA[postFeedbackResults.isSuccess ? 'isSuccess' : 'isError'])
			setTimeout(
			() => setFeedbackRequestStatus(null),
				4000
			)
			if(postFeedbackResults.isSuccess)
				setInitialStates()
		}
	}, [postFeedbackResults])

	const sendFeedback = () => {
		postFeedback({
			feedbackTopic: feedbackTopic.value,
			isContactAllowed: Boolean(isContactAllowed),
			contactEmail: isContactAllowed ? contactEmail : INITIAL_STATES.contactEmail,
			feedbackText,
		})

		if(!postFeedbackResults.isLoading)
			setShowFeedbackForm(false)
	}

	const setInitialStates = () => {
		setFeedbackTopic(INITIAL_STATES.feedbackTopic)
		setFeedbackText(INITIAL_STATES.feedbackText)
		setIsContactAllowed(INITIAL_STATES.isContactAllowed)
		setContactEmail(INITIAL_STATES.contactEmail)
	}

	return (
		<>
			<SfcShellAppBarIcon
				slot="icons"
				icon="action-chat"
				className={`${SHELL_NAV_ICON} lg:mr-5 ${isSmallMobile ? 'mt-12' : ''}`}
				label="Feedback"
				onClick={() => setShowFeedbackForm(true)}
			/>

			{
				<SdfAlert
					toast
					type="banner"
					size="md"
					hidden={!feedbackRequestStatus}
					status={feedbackRequestStatus?.status}
					useAnimation
				>
					{feedbackRequestStatus?.text}
				</SdfAlert>
			}

			<SdfFocusPane
				id="feedback-pane"
				className="inset-y-0 text-default cursor-default"
				dismissOnClickOutside
				closeable
				acceptLabel="Send"
				heading="Developer Resources Feedback"
				titleTag="h1"
				visible={showFeedbackForm}
				disableAcceptButton={disableAcceptButton}
				onSdfAccept={sendFeedback}
				onSdfDismiss={() => setShowFeedbackForm(false)}
			>
				<aside className="p-5 py-0 text-default">
                    <div className="text-sm leading-sm h-md mb-16">
						<h3 className="mb-1">
							Share your feedback to help us improve our developer resources.
						</h3>
                        <div className="font-normal mb-1">
							An asterisk (
							<span className="text-action-destructive font-semibold">*</span>
							) means the field is required.
						</div>
						<SdfFormControlLayout
							inputId="feedbackForm"
							disabled={true}
						>
							<SdfSelectSimple
								label="Feedback topic"
								placeholder="Select a feedback"
								inputId="feedbackForm"
								requiredState="required"
								size="full"
								className={FORM_FIELD}
								value={feedbackTopic}
								items={FEEDBACK_SELECT_TOPICS}
								onSdfChange={handleSetFromTargetValue(setFeedbackTopic)}
							/>
							<SdfTextarea
								required
								rows={4}
								inputId="feedbackForm"
								label="Feedback"
								placeholder="Enter your feedback"
								requiredState="required"
								className={FORM_FIELD}
								value={feedbackText}
								maxlength={5000}
								onSdfChange={handleSetFromTargetValue(setFeedbackText)}
							/>
							<SdfRadioGroup
								requiredState="required"
								inputId="feedbackForm"
								label="Can we contact you in the future regarding your feedback?"
								className={FORM_FIELD}
								value={isContactAllowed}
								onSdfChange={handleSetFromTargetValue(setIsContactAllowed)}
							>
								<SdfRadioButton
									label="Yes"
									value={1}
								/>
								<SdfRadioButton
									label="No"
									value={0}
								/>
							</SdfRadioGroup>
							{
								(isContactAllowed === 1) &&
									<>
										<SdfInput
											requiredState="required"
											type="email"
											showValidationIcon={true}
											className={`${FORM_FIELD} mb-2`}
											onSdfInput={handleSetFromTargetValue(setContactEmail)}
											placeholder="example@example.com"
											label="Email address"
											pattern="email"
											inputType="email"
											state={isContactEmailCompliant ? 'normal' : 'error'}
											validationIconText="Invalid email"
											value={contactEmail}
										/>
										{
											!isContactEmailCompliant &&
											<ErrorMessage textMessage="Invalid email" />
										}
									</>
							}
						</SdfFormControlLayout>
					</div>
                </aside>

			</SdfFocusPane>
		</>
	)
}