import React from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import {ARTICLES_BORDER_BOTTOM} from "../../utility/constants";

function ChapterList({list, isPreview}) {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const showContent = ({step}) => {
        let chapterPath = `${pathname}/chapter/${step}`

        if (isPreview) {
            chapterPath = `${pathname}?chapter=${step}`
        }

        navigate(chapterPath)
    }


    const chapters = list?.map((chapter, i) => (
            <div className="flex flex-col w-full py-2" key={i}>
                {/* Need to use index + 1 cause step property isn't in all guides chapter*/}
                <div className="text-heading-03 font-bold">Chapter {i + 1}</div>
                <div className="text-action-primary cursor-pointer font-medium pt-1"
                     onClick={() => showContent({...chapter, step: i + 1})}>{chapter.title}</div>
                <div className={`pt-3 ${ARTICLES_BORDER_BOTTOM}`}/>
            </div>
        )
    );

    return (
        <div className="pt-8">
            {chapters}
        </div>
    )
}

export default ChapterList;

