import React from "react";

import {CodeBoxTop} from "../../code-example/CodeBoxTop";
import {NoAvailableContentBox} from "../../NotAvailableContentBox";

export function SwaggerNoPreview({className}) {
	return (
		<div className={className}>
			<CodeBoxTop
				disabled
				contentType="-"
			/>
			<NoAvailableContentBox
				title="No preview available"
			/>
		</div>
	)
}