import {SdfSelectSimple} from "@synerg/react-components";
import {SdfMultiSelect} from "@synerg/react-components/dist/components";
import React from "react";
import {FiltersTags} from "./FiltersTags";
import {SearchInput} from "./SearchInput";
import {InputWidthEnum} from "../utility/enums/InputWidthEnum";
import {FilterTypesEnum} from "../utility/enums/FilterTypesEnum";

const DEFAULT_INPUT_WIDTH_MD = InputWidthEnum.XL
const SELECTS_TAILWIND_CLASSES = 'w-48 sm:w-36 xl:w-52 2xl:w-60'

function Filters({filters, filterTags}) {
    const input = ({eventHandler, value, inputWidthMd, inputMaxWidth}) => (
        <SearchInput
            value={value}
            onSdfChange={eventHandler}
            inputWidthMd={inputWidthMd}
            inputMaxWidth={inputMaxWidth}
        />
    )
    const selectSimple = ({eventHandler, value, items, label, placeholder, loading, inputWidthMd }) => (
        <SdfSelectSimple
            loading={loading}
            className={SELECTS_TAILWIND_CLASSES}
            inputWidth_md={inputWidthMd}
            items={!loading && Object.values(items)}
            label={label}
            placeholder={placeholder}
            value={value}
            onSdfChange={eventHandler}
        />
    )
    const selectMulti = ({eventHandler, value, items, label, placeholder, inputWidthMd}) => (
        <SdfMultiSelect
            className={SELECTS_TAILWIND_CLASSES}
            inputWidth_md={inputWidthMd}
            items={Object.values(items)}
            label={label}
            placeholder={placeholder || 'Select...'}
            value={value}
            onSdfChange={eventHandler}
            selectionStatusMessage="Selected"
        />
    )

    const filtersUis = {
        input,
        selectSimple,
        selectMulti,
    }

    return (
        <>
            <section className={`flex justify-start flex-wrap lg:flex-nowrap items-end mt-3 gap-2 ${filters?.length < 5 && 'xl:flex-nowrap xl:justify-between'}`}>
                {
                    filters.map((filter, index) =>
                        (
                            <div className={`
                                    pb-4
                                    ${filter.type === FilterTypesEnum.Input && 'pr-2'} 
                                    ${(filter.type === FilterTypesEnum.Input && filter?.inputWidthMd === InputWidthEnum.FULL) && 
                                        `w-full ${filter.inputMaxWidth || 'max-w-xl'}`
                                    }
                                `} key={index}>
                                {
                                    filter && filtersUis[filter.type]({
                                        eventHandler: filter.eventHandler,
                                        value: filter.value,
                                        items: filter?.items,
                                        label: filter?.label,
                                        placeholder: filter?.placeholder,
                                        loading: filter?.loading,
                                        inputWidthMd: filter?.inputWidthMd || DEFAULT_INPUT_WIDTH_MD,
                                        inputMaxWidth: filter?.inputMaxWidth
                                    })
                                }
                            </div>
                        )
                    )
                }
            </section>
            {
                filterTags && filters?.length && <FiltersTags filters={filters}/>
            }
        </>
    )
}

export {Filters}