import { SdfBusyIndicator, SdfButton, SdfCard, SdfCardFooter, SdfSpotIllustration } from "@synerg/react-components";
import React from "react";
import LinkWithIcon from "../LinkWithIcon";
import { useFetchProductsQuery } from "../../store";
import { useNavigate } from "react-router-dom";

const HOVER_ROW = 'hover:bg-action-default-100 cursor-pointer';

const integrationFoundation = [
    {
        title: 'Product guides',
        desc: 'Step-by-step implementation guides to help you build integrations to a specific ADP product',
        url: '/build/guides/product-integration-guides',
    },
    {
        title: 'Marketplace guides',
        url: '/build/guides/marketplace-integration-guides',
        desc: 'Learn how to setup your listing on our digital storefront and how ADP authentication and authorization models work'
    },
    {
        title: 'Key Concepts',
        desc: 'Learn about authentication, single-sign on and our digital storefront platform',
        url: '/learn/key-concepts'
    }
];

const isPopularProduct = (product) => product.isPopular

export function LearnOrBuildSection({title, subtitle='no description', key, onClick} ) {
    return (
        <div className={'p-2 ' + HOVER_ROW} onClick={onClick} key={key}>
            <div className="text-action-primary font-semibold text-heading-02">{title}</div>
            <div className="flex justify-between pt-2">
                <div className="w-11/12 font-normal text-body-02">{subtitle}</div>
                <div className="text-action-primary text-end ml-2">
                    <LinkWithIcon
                        icon='nav-move-right'
                    />
                </div>
            </div>
        </div>
    )
}

export function LearnAndBuild() {
    const navigate = useNavigate();

    const {data, isLoading, isError} = useFetchProductsQuery();

    let productList;
    if (isLoading) {
        productList = <SdfBusyIndicator/>
    } else if (isError) {
        productList = <h3>Error loading Products</h3>
    } else {
        productList =
            data?.filter(isPopularProduct).map((product, index) => {
                return (
                    <LearnOrBuildSection
                      title={product.title || product.name}
                      subtitle={product.description || 'no description'}
                      key={index}
                      onClick={() => navigate(`/build/api-explorer/${product.key}`)}
                    />
                )
            });
    }


    return (
        <>
        <section className="flex flex-col md:flex-row lg:flex-row pt-8 mb-2" id="exploreGuidesAndApis">
                <div className="mt-2">
                    <SdfSpotIllustration illustrationName="tools" id="exploreGuidesAndApisImage"/>
                </div>
                <div className="pl-3 pt-2">
                    <div className="font-black text-heading-06 pt-2" id="exploreGuidesAndApisHeader">Learn and build</div>
                    <div className="text-heading-03 font-normal pt-2" id="exploreGuidesAndApisDescription">Build integrations with foundational tools and guides,
                        and explore APIs by ADP product.
                    </div>
                </div>
            </section>
            <section className="grid gap-6 sm:mx-4 sm:grid-cols-1 sm:pl-2 sm:ml-5 md:grid-cols-1 md:gap-16 md:pl-32 md:ml-5 md:pr-40 lg:gap-16 lg:grid-cols-2 lg:pl-24 lg:ml-5 pt-4">
                <SdfCard id="exploreGuidesContent" className="ml-1">
                    <section className="pb-4">
                        <div className="font-bold text-heading-05 mb-1">Integration foundations</div>
                        <div className='font-normal text-heading-04'>Tools and guides</div>
                    </section>
                    <div className="card-height-stretch">
                        {integrationFoundation.map((ele, index) => (
                            <LearnOrBuildSection
                              title={ele.title}
                              subtitle={ele.desc}
                              key={index}
                              onClick={() => navigate(ele.url)}
                            />
                        ))}
                    </div>
                    <SdfCardFooter>
                        <SdfButton onClick={() => navigate('/build/overview')} id='getStarted2'>
                            Get started
                        </SdfButton>
                    </SdfCardFooter>
                </SdfCard>
                <SdfCard id="exploreApisContent">
                    <section className="pb-4">
                        <div className="font-bold text-heading-05 mb-1">Explore APIs</div>
                        <div className='font-normal text-heading-04'>Browse by ADP product</div>
                    </section>
                    {productList}
                    <SdfCardFooter>
                        <SdfButton onClick={() => navigate('/build/api-explorer')}>
                            View all
                        </SdfButton>
                    </SdfCardFooter>
                </SdfCard>
            </section>
        </>
        
    )
}