import { createApi } from "@reduxjs/toolkit/query/react";
import {generateBaseQuery} from "../../utility/storeUtil";


const feedbackApi = createApi({
    reducerPath: 'feedback',
    baseQuery: generateBaseQuery('omit'),
    endpoints: (builder) => ({
        postFeedback: builder.mutation({
            query: (queryObj) => {
                return {
                    url: `${process.env.REACT_APP_API_PATH}/devportal/feedback`,
                    method: 'POST',
                    body: queryObj
                }
            },
        })
    })
});

export const { usePostFeedbackMutation } = feedbackApi;
export { feedbackApi };
