import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {getElasticQuery} from "../../utility/FetchingUtils";
import {articlesApiTransformByType} from "../../utility/transform/articlesApi";


const articlesApi = createApi({
    reducerPath: 'articles',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            headers.append('Content-Type', 'application/json');
            headers.append('X-Requested-With', 'XMLHttpRequest');
            headers.append('Content-Length', 0);
            headers.append('Accept', 'application/json');
        },
        credentials: 'include'
    }),
    endpoints: (builder) => ({
        fetchArticles: builder.query({
            query: (queryObj) => ({
                url: `${process.env.REACT_APP_API_PATH}/services/articles`,
                method: 'POST',
                body: getElasticQuery(queryObj),
            }),
            transformResponse: (response) => {
                if (response?.error) throw new Error('ErrorOnResponseData');
                return response;
            }
        }),
        fetchArticle: builder.query({
            query: (queryObj) => {
                return {
                    url: getArticleUrl(queryObj),
                    method: 'GET'
                }
            },
            transformResponse: (response) => {
                if (response?.error) throw new Error('ErrorOnResponseData');

                if (!response?.hits?.hits.length) {
                    return null;
                }

                return articlesApiTransformByType(response?.hits?.hits[0]);
            }
        }),
        fetchArticlePreview: builder.query({
            query: ({articleId}) => {
                return {
                    url: `${process.env.REACT_APP_API_PATH}/services/elasticsearch/preview/_doc/${articleId}`,
                    method: 'GET'
                }
            },
        }),
        rateArticle: builder.mutation({
            query: (queryObj) => ({
                url: `${process.env.REACT_APP_API_PATH}/services/elasticsearch/articles/rating`,
                method: 'POST',
                body: getElasticQuery(queryObj),
            }),
            transformResponse: (response) => {
                if (response?.error) throw new Error('ErrorOnResponseData');

                return response;
            }
        }),
    })
});

const getArticleUrl = (queryObj) => {
    return `${process.env.REACT_APP_API_PATH}/services/elasticsearch/${queryObj?.index}/_doc/${queryObj?.id}`;
}
export const {
    useFetchArticlesQuery,
    useFetchArticleQuery,
    useFetchArticlePreviewQuery,
    useRateArticleMutation
} = articlesApi;
export {articlesApi};
