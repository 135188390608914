import {SdfIcon} from "@synerg/react-components"
import React, {useState} from "react"
import {useSelector} from 'react-redux';
import {useRateArticleMutation} from '../../store';

const getRatingValue = (rating) => {
    let item = [];
    for (let i = 1; i <= 5; i++) {
        item.push(i <= rating ? 'feedback-rate-full' : 'feedback-rate-empty')
    }
    return item;
};

export function RateArticle({article}) {
    const [rating, setRating] = useState(article?._source?.rating || 0);
    const [stars, setStars] = useState(getRatingValue(rating));
    const [updateRatings] = useRateArticleMutation();

    const isLoggedIn = useSelector((state) => {
        return state.auth.isLoggedIn;
    });

    const ratingClickHandler = (event) => {
        setRating(event);
        updateRatings({
            index: article._index,
            type: article._type,
            id: article._id,
            rating: event
        });
    }

    const onHandleHoverStars = (hoverIndex) => {
        const newStars = stars.map((star, index) => {
            return (hoverIndex >= index) ? "feedback-rate-full" : "feedback-rate-empty"
        })

        setStars(newStars)
    }

    return (
        <>
            {
                isLoggedIn ?
                    <div className="flex items-center flex-col mt-8 w-32 lg:ml-10"
                         onMouseLeave={() => setStars(getRatingValue(rating))}>
                        <div className="text-lg">
                            Rate this article
                        </div>
                        <div>
                            {stars.map((star, index) => (
                                <SdfIcon
                                    className="mr-1"
                                    key={index}
                                    icon={star}
                                    onMouseEnter={() => onHandleHoverStars(index)}
                                    onClick={() => ratingClickHandler(index + 1)}
                                />
                            ))}
                        </div>
                    </div> : null
            }
        </>
    )
}