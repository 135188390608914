function strToBytes(str) {
    return new TextEncoder().encode(str)
}

function uppercaseFirstDigit(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function removeStyleFromMediaQuery(style, mediaQueryStyle = true) {
    // This avoids code repetition because the alternative is repeating the classes or added those as constants
    if (mediaQueryStyle) return style;

    return style.split(' ')
        .filter((styleClass) =>
            !['sm:', 'md:', 'lg:', 'xl:', '2xl:']
                .some((mediaQuery) => styleClass.includes(mediaQuery))
        )
        .join(' ')
}

function removeExtraSpacesFromString(str) {
    return str.replace(/\s+/g, ' ').trim()
}

const EMAIL_REGEX = /(.+)@(.+){2,}\.(.+){2,}/;

export {
    strToBytes,
    uppercaseFirstDigit,
    removeStyleFromMediaQuery,
    removeExtraSpacesFromString,
    EMAIL_REGEX
}