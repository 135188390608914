import React from "react";

import {SdfIcon} from "@synerg/react-components";
import useMediaQuery from "../../hooks/useMediaQuery";

export function NavChapter({isPrev, chapterTitle, onClick}) {
    const isSmall = useMediaQuery('(max-width: 804px)')

    return (
        <div
            className={`flex ${chapterTitle ? 'text-action-primary cursor-pointer' : 'text-tertiary'}`}
            onClick={() => onClick()}
        >
            <div className="md:overflow-ellipsis truncate max-w-xs xl:max-w-md">
                {
                    isPrev && <SdfIcon className="mr-1" icon="nav-move-left"/>
                }
                {isPrev ? 'Prev' : 'Next'} {!isSmall && chapterTitle ? `Chapter: ${chapterTitle}` : ''}
            </div>
            {
                !isPrev && <SdfIcon className="ml-1" icon="nav-move-right"/>
            }
        </div>
    )
}