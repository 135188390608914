import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from "@reduxjs/toolkit/query";
import {
    productsApi,
    articlesMetaApi,
    articlesApi,
    searchApi,
    elasticSearchApi,
    swaggerApi,
    userApi,
    feedbackApi
} from './apis'
import {
    apiExplorerSlice,
    articleSlice,
    authSlice,
    filtersSlice,
    swaggerSlice,
    useCasePageSlice,
    useCasesSlice
} from "./slices";

export const store = configureStore({
    reducer: {
        [articlesApi.reducerPath]: articlesApi.reducer,
        [productsApi.reducerPath]: productsApi.reducer,
        [articlesMetaApi.reducerPath]: articlesMetaApi.reducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [elasticSearchApi.reducerPath]: elasticSearchApi.reducer,
        [swaggerApi.reducerPath]: swaggerApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [feedbackApi.reducerPath]: feedbackApi.reducer,
        swaggerMeta: swaggerSlice.reducer,
        filtersSlice: filtersSlice.reducer,
        auth: authSlice.reducer,
        useCases: useCasesSlice.reducer,
        useArticles: articleSlice.reducer,
        useApiExplorer: apiExplorerSlice.reducer,
        useCasePage: useCasePageSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .concat(articlesApi.middleware)
            .concat(productsApi.middleware)
            .concat(articlesMetaApi.middleware)
            .concat(searchApi.middleware)
            .concat(elasticSearchApi.middleware)
            .concat(swaggerApi.middleware)
            .concat(userApi.middleware)
            .concat(feedbackApi.middleware)
    }
});

setupListeners(store.dispatch);

export {useFetchProductsQuery, useFetchProductsByApiQuery} from './apis/productsApi';
export {useFetchArticlesQuery, useFetchArticleQuery, useRateArticleMutation} from './apis/articlesApi';
export {useFetchSearchQuery} from './apis/searchApi';
export {
    useFetchElasticSearchQuery, useFetchElasticSearchByIdQuery, useUpdateMetricsMutation, usePostElasticSearchByIdMutation
} from './apis/elasticSearchApi';
export {useFetchSwaggerQuery} from './apis/swaggerApi';
export {useFetchUserQuery} from './apis/userApi';
export {usePostFeedbackMutation} from './apis/feedbackApi'
export const {selectedArticle} = articleSlice.actions;
export const {selectProduct, selectApi, selectProductView} = apiExplorerSlice.actions;
export const {setIsLoggedIn, setUserInfo} = authSlice.actions;
export const {setSwaggerUrl} = swaggerSlice.actions;
export const {setFilters} = filtersSlice.actions;
export const {selectedUseCasePage} = useCasePageSlice.actions;
