import React from "react";
import {HorizontalTable} from "../../HorizontalTable";

export function SwaggerSummary({summary}) {
    if (!summary) return;

    const gridRows = {
        'Method Path': summary.pathname,
        'HTTP Method': summary.method,
        'Accepted content type(s)': 'application/json',
        'Canonical URL': summary.canonicalUri,
        'Token type': 'Oauth 2.0',
        'Required scope(s)': 'Client id/client secret',
    }

    return (
        <div>
            <div className="text-heading-06 font-black mb-1">
                Summary
            </div>
            <div className="text-lg mb-6 mt-2"
                 dangerouslySetInnerHTML={{
                     __html: summary.description
                 }}
            />
            <HorizontalTable rows={gridRows} />
        </div>
    )
}
