import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getElasticQuery } from "../../utility/FetchingUtils";


const searchApi = createApi({
    reducerPath: 'search',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            headers.append('X-Requested-With', 'XMLHttpRequest');
        },
        credentials: 'include'
    }),
    endpoints(builder) {
        return {
            fetchSearch: builder.query({
                query: (queryObj) => {
                    return {
                        url: `${process.env.REACT_APP_API_PATH}/services/elasticsearch/faq,%20general,%20api,%20guides,%20usecases/_doc/all`,
                        method: 'POST',
                        body: getElasticQuery(queryObj)
                    }
                },
            })
        }
    }
});

export const { useFetchSearchQuery } = searchApi;
export { searchApi };
