import React, { useEffect, useState } from "react";
import { SdfFocusPane, SdfTab, SdfTabGroup, SdfLink } from "@synerg/react-components";
import get from 'lodash/get';

function FeatureSteps(props) {
  const [selectedProductExampleSteps, setSelectedProductExampleSteps] = useState([]);
  const [selectedSorName, setSelectedSorName] = useState('');
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const { handleOnHide, show, selectedFeatureItem, useCaseInfo } = props;
  useEffect(() => {
    const selectedExample = get(selectedFeatureItem, 'example.adpProductExamples[0]', {});
    const selectedSor = get(selectedExample, 'sorName', '');
    const exampleSteps = get(selectedExample, 'exampleSteps', []);
    if (exampleSteps.length > 0) {
      setSelectedProductExampleSteps(exampleSteps);
      setSelectedSorName(selectedSor);
    }
  }, [])
  const selectedProduct = (productExample, idx) => {
    setSelectedProductExampleSteps(get(selectedFeatureItem, `example.adpProductExamples[${idx}].exampleSteps`, []));
    setSelectedSorName(productExample.sorName);
    setSelectedProductIndex(idx);
  }

  const headingStyles = "text-heading-02 font-bold p-4";
  const borderStyles = "border-1 border-solid border-action-default";
  return (<div>
    <SdfFocusPane hide-dismiss-button='' hide-accept-button='' closeable={true} visible={show} onSdfDismiss={() => handleOnHide()} size='xl' spacing='loose' dismissOnClickOutside={true} style={{ zIndex: '1063' }}>
      <h2 className="text-heading-06 font-black m-0">{get(selectedFeatureItem, 'iconTitle')}</h2>
      <h4 className="text-heading-04 font-bold m-0">Product-specific integration flows with guides</h4>
      <p className="text-body-02 mt-6">{get(selectedFeatureItem, 'iconTitle')} integrating with {useCaseInfo.title} products typically follow the steps and corresponding objectives listed in the table below.</p>
      <h4 className="text-heading-03 font-bold m-0 mb-6">Select an ADP Product to view product-specific flows</h4>
      <SdfTabGroup className='mb-6'>
        {get(selectedFeatureItem, 'example.adpProductExamples', []).map((productExample, idx) =>
          <SdfTab className={selectedProductIndex === idx && 'active'} value={idx} key={idx}
            onClick={() => selectedProduct(productExample, idx)}>
            {productExample.sorName}</SdfTab>
        )}
      </SdfTabGroup>
      <table className="w-full border-collapse">
        <thead className="bg-alert-neutral-50">
          <tr>
            <th className={`${headingStyles} text-right ${borderStyles}`} >Steps</th>
            <th className={`${headingStyles} text-left max-w-md ${borderStyles}`} >Integration objective</th>
            <th className={`${headingStyles} text-left max-w-md ${borderStyles}`} >API documentation <span className="font-normal">({selectedSorName})</span> </th>
            <th className={`${headingStyles} text-left ${borderStyles}`}>Availability status</th>
          </tr>
        </thead>
        <tbody>
          {selectedProductExampleSteps.map((productExample, index) => {
            const guideUrl = get(productExample, 'relatedDocumentation.relatedDocGuideUrl', '');
            const matchUrl = '/build/guides/product-integration-guides' + guideUrl.substring(guideUrl.lastIndexOf('/'));
            return <tr key={index}>
              <td className={`p-4 text-right ${borderStyles}`} >{index + 1}</td>
              <td className={`p-4  text-left max-w-md ${borderStyles}` }>{get(productExample, 'integrationObjective', '')}</td>
              <td className={`p-4 text-left  max-w-md ${borderStyles}`} ><SdfLink href={matchUrl} target='_blank' >{get(productExample, 'relatedDocumentation.relatedDocGuideTitle', '')}</SdfLink></td>
              <td className={`p-4 text-left ${borderStyles}`} >{get(productExample, 'relatedAvailabilityStatus')}</td>
            </tr>
          })}
        </tbody>
      </table>
      <sdf-button emphasis="primary" slot="custom-buttons" onClick={() => { handleOnHide() }}>Close</sdf-button>
    </SdfFocusPane>
  </div>);
}
export default FeatureSteps;