import {saveAs} from 'file-saver'
import {domainsSelect} from "./FetchingUtils";
import {loadFile} from "../store/apis/swaggerApi";


function getDomainLabel(domain) {
    const foundDomain = domainsSelect.find(dm => dm.value === domain);

    return foundDomain?.label;
}


async function handleDownload({fileUrl, fileName}) {
    if (!fileUrl) return

    let file;
    try {
        console.log(`Trying to load file ${fileUrl}`);
        file = await loadFile(fileUrl);
    } catch (e) {
        console.log('### Caught exception ' + e);
        if (fileNameHasBlankSpaces(fileName)) fileUrl = encodeURI(fileUrl)

        file = await loadFile(fileUrl)

        if (file) {
            console.log('Loaded file with extra encoding');
        } else {
            console.log(`Still can't load file ${fileUrl}`);
        }
    }
    if (file) {
        console.log(`Saving file as ${fileName}`)
        saveAs(file, fileName);
    }
}

function fileNameHasBlankSpaces(fileName) {
    return fileName.includes(" ");
}

function sortSwaggerTags(tags) {
    return [...tags]
        .sort((a, b) => a.name.localeCompare(b.name))
}

export {getDomainLabel, handleDownload, sortSwaggerTags}
