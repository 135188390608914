import {useNavigate} from "react-router-dom";
import {getPathName} from "../../routes/legacyRedirects";
import {useEffect} from "react";

export function ApiExplorerProductApiPath() {
    const navigate = useNavigate()
    const pathName = getPathName()
    const searchParams = (new URL(document.location)).searchParams;
    const operation = searchParams.get('operation')
    const splitedPathName = pathName.split('/')

    useEffect(() => {
        let newPathName = `/build/api-explorer/${splitedPathName[3]}/${splitedPathName[4]}`
        if (operation) {
            const encodedOperation = encodeURIComponent(operation)
            newPathName += `?operation=${encodedOperation}`
        }

        navigate(newPathName)
    })
}