import React from 'react';
import {SdfBox, SdfSpotIllustration} from "@synerg/react-components";

function ArticlesListDescriptionBox({illustrationName, text}) {
    return (
        <SdfBox className="mb-4">
            <div className="flex items-center justify-start">
                <SdfSpotIllustration
                    illustrationName={illustrationName}
                    className="mt-1"
                />
                <div className="ml-4">
                    {text}
                </div>
            </div>
        </SdfBox>
    )
}

export {ArticlesListDescriptionBox}
