import React from 'react';
import {SdfCard, SdfCardFooter, SdfLink} from "@synerg/react-components";
import {useNavigate} from "react-router-dom";
import {DESCRIPTION_STYLE} from "../utility/constants";
import useMediaQuery from "../hooks/useMediaQuery";

export function BriefCard({title, subtitle, footer, className, to, onClick, size=""}) {
	const navigate = useNavigate()
	const isMdOrBigger = useMediaQuery('(min-width: 768px)')

	const wordsClass = isMdOrBigger ? 'break-words' : 'break-all'

	return (
		<SdfCard className={`${size} cursor-pointer ${className}`} onClick={to ? () => navigate(to) : onClick}>
			<div className='h-full flex flex-col justify-between'>
				<section>
					<div className={`text-xl font-bold ${wordsClass} mb-2`}>{title}</div>
					<div style={DESCRIPTION_STYLE} className={`font-normal text-body-02 ${wordsClass} overflow-hidden ${size ? 'pb-6' : ''}`}>{subtitle}</div>
				</section>
				<SdfCardFooter className="flex flex-end text-md font-medium mt-12 ">
					<SdfLink className="text-action-primary no-underline hover:underline">
						{footer}
					</SdfLink>
				</SdfCardFooter>
			</div>
		</SdfCard>
	)
}