import React, {useEffect} from "react";
import DOMPurify from "dompurify";
import {findElements, resetInlineStyle} from "../utility/DOMUtils";

export function LoadHtmlFromString({htmlStr, className = ''}) {
    if (!htmlStr) return

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const elements = findElements('#doc-preview')
        elements.length > 0 && resetInlineStyle(elements, 'table,th,td,p,pre')
    }, [htmlStr])

    return (
        <div className={`dev-portal-custom-style ${className}`}>
            <div id="doc-preview" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(htmlStr)}}/>
        </div>
    )
}