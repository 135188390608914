import React from "react";
import {useSelector} from "react-redux";

import {useFetchSwaggerQuery} from "../../../store";
import {getSampleUrl} from "../../../utility/transform/path";
import {strToBytes} from "../../../utility/stringUtil";
import {BoxShimmer} from "./BoxShimmer";
import {CodeBoxTop} from "./CodeBoxTop";
import {CodeBox} from "../../CodeBox";

export function CodeExample({path, name, fileName, contentType, bgColorTop, bgColorCode, elementRef}) {
    let swaggerUrl = useSelector((state) => {
        return state?.swaggerMeta?.url;
    });
    const {data, isLoading} = useFetchSwaggerQuery({
        url: getSampleUrl(swaggerUrl, path)
    });

    const strData = data ? JSON.stringify(data, null, 2) : null;
    const {byteLength} = strToBytes(strData)

    if (isLoading) return <BoxShimmer/>
    else if (!strData) return

    return (
        <div className={bgColorTop}>
            <>
                <CodeBoxTop
                    contentType={contentType}
                    strData={strData}
                    name={name}
                    dataByteLength={byteLength}
                    fileName={fileName}
                />
                <CodeBox
                    customStyle={`${bgColorCode} h-md`}
                    contentType={contentType}
                    strData={strData}
                    elementRef={elementRef}
                />
            </>
        </div>
    )
}