import React, {useState} from "react";

import {SwaggerPathParameters} from "./SwaggerPathParameters";
import {SdfTab, SdfTabGroup} from "@synerg/react-components";
import {SwaggerDetailsTabsEnum} from "../../../utility/enums/SwaggerDetailsTabsEnum";
import SwaggerPathExamples from "./SwaggerPathExamples";
import {SwaggerSummary} from "./SwaggerSummary";

const detailsTabs = [
    {
        value: SwaggerDetailsTabsEnum.SUMMARY,
        label: 'Summary',
    },
    {
        value: SwaggerDetailsTabsEnum.EXAMPLES,
        label: 'Example(s)',
    },
    {
        value: SwaggerDetailsTabsEnum.PARAMETERS,
        label: 'Parameters',
    },
]

function SwaggerPathDetails({summary, parameters, examples, scenarios, fileNameBase}) {

    const [selectedDetail, setSelectedDetail] = useState(SwaggerDetailsTabsEnum.SUMMARY);
    const handleSelectedTabChange = (e) => {
        setSelectedDetail(e.detail.value)
    }

    const selectedDetailUi = {
        [SwaggerDetailsTabsEnum.SUMMARY]: (
            <SwaggerSummary summary={summary}/>
        ),
        [SwaggerDetailsTabsEnum.EXAMPLES]: (
            //parameters prop is for getting parameters and request headers descriptions
            <SwaggerPathExamples scenarios={scenarios} parameters={parameters} headersFromPath={examples}
                                 fileNameBase={fileNameBase}/>
        ),
        [SwaggerDetailsTabsEnum.PARAMETERS]: (
            <SwaggerPathParameters parameters={parameters}/>
        )
    }

    return (
        <div className="mt-4 md:grid md:grid-cols-12">
            <div className="w-min md:col-end-1">
                <SdfTabGroup vertical size="small" onSdfChange={handleSelectedTabChange}>
                    {
                        detailsTabs.map((tab, index) => (
                            <SdfTab key={index} value={tab.value} active={selectedDetail === tab.value}>
                                {
                                    tab.label
                                }
                            </SdfTab>
                        ))
                    }
                </SdfTabGroup>
            </div>
            <div className="ml-4 md:col-span-11">
                {
                    selectedDetailUi[selectedDetail]
                }
            </div>
        </div>

    )
}

export {SwaggerPathDetails}