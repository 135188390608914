import React from "react";
import {SwaggerField} from "./SwaggerField";

function paramsListUi(title, list) {
    return (
        <>
            <div className="mr-2 text-heading-06 font-black">
                {(list.length > 0) && title}
            </div>
            {
                list.map((param, index) => (
                    <SwaggerField
                        key={index}
                        dataType={param?.schema?.type}
                        fieldType={param?.in}
                        name={param.name}
                        description={param.description}
                    />
                ))
            }
        </>
    )
}

function SwaggerPathParameters({parameters}) {
    let required = [];
    let optional = [];
    const groupByCategory = () => {
        for (const param of parameters) {
            if (param['x-adpParameterData']?.visibilityCodes?.includes('external')) {
                if (param.required) required.push(param)
                else optional.push(param)
            }
        }
    }

    groupByCategory()

    return (
        <div className="mt-1">
            {
                paramsListUi('Required Parameters', required)
            }
            <div className="mt-10">
                {
                    paramsListUi('Optional Parameters', optional)
                }
            </div>
        </div>
    )
}

export {SwaggerPathParameters}
