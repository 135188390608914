import React from "react";
import {SdfIcon} from "@synerg/react-components";

export function FieldsDescription({description}) {
    return (
        <div className="flex flex-row justify-between items-center mt-6 mb-2">
            <div className="text-heading-05 font-black">
                {description}
            </div>
            <div className="text-alert-error text-xs">
                <SdfIcon icon="alert-error" className="mr-1"/>
                REQUIRED
            </div>
        </div>
    )
}
