import React, {useState, useRef} from "react";
import {SdfBox, SdfTab, SdfTabGroup} from "@synerg/react-components";
import {SwaggerFieldsOrNotAvailableBox} from "./SwaggerFieldsOrNotAvailableBox";
import {fieldsMapper} from "./mappers";
import {CodeExample} from "../../code-example/CodeExample";
import {isEmptyObject} from "../../../../utility/ObjectUtil";
import {SwaggerNoPreview} from "./SwaggerNoPreview";

export function CodeSampleResponse({responses, headers, fileNameBase}) {
    const [codeSampleResponseIndex, setCodeSampleResponseIndex] = useState(0);
    const response = responses && responses[codeSampleResponseIndex]; // data from scenario
    const elementRef = useRef(null)

    const getHeaders = () => {
        if (!response?.headers) return null
        if (!headers) return null

        const availableHeaders = Object.keys(response.headers).filter(header =>
            headers[header]?.['x-adpParameterData']?.['visibilityCodes'].includes('external'))
            .reduce((acc, value) => {
                return {
                    ...acc,
                    [value]: {
                        description: headers?.[value]?.description,
                        value: response.headers[value]
                    }
                }
            }, {});

        if (isEmptyObject(availableHeaders)) return null;

        return availableHeaders
    }

    const handleCodeSampleResponseChange = (e) => {
        setCodeSampleResponseIndex(e.detail.value);
        if(elementRef.current){
            elementRef.current.scrollTop = 0;
        }
    }

    return (

        <div className="overflow-hidden">
            {
                responses ?
                    <>
                        <SdfTabGroup
                            size="small"
                            onSdfChange={handleCodeSampleResponseChange}
                        >
                            {
                                responses.map((response, index) => (
                                    <SdfTab
                                        key={index}
                                        value={index}
                                        active={codeSampleResponseIndex === index}
                                    >
                                        {response.code}
                                    </SdfTab>
                                ))
                            }
                        </SdfTabGroup>
                        <div className="text-heading-03 font-black mt-8">
                            {response?.description}
                        </div>
                        <div className="overflow-hidden">
                            {
                                response?.content &&
                                Object.keys(response?.content).map((contentType, index) => (
                                    <CodeExample
                                        key={index}
                                        path={response?.content[contentType]?.externalValue}
                                        name={response?.name}
                                        response={response}
                                        contentType={contentType}
                                        fileName={`${fileNameBase}-response-${response.code}.json`}
                                        elementRef={elementRef}
                                    />
                                ))
                            }
                        </div>
                        <SdfBox className="my-6 text-xl font-black">
                            {response?.code}: Response Header(s)
                        </SdfBox>
                    </> :
                    <SwaggerNoPreview className="mb-2" />
            }
            {
                getHeaders() &&
                <SwaggerFieldsOrNotAvailableBox
                    data={getHeaders()}
                    mappers={{
                        ...fieldsMapper,
                        fieldType: () => 'header',
                        required: () => false
                    }}
                    notAvailableTitle="No response headers provided"
                />
            }
        </div>

    )
}
