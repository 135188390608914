import React, {useEffect, useState} from "react";
import get from 'lodash/get';
import {
    SdfButton,
    SdfTooltip,
    SdfIcon,
    SdfIconButton,
    SdfCard,
    SdfCardFooter,
    SdfCardHeader,
    SdfAlert
} from '@synerg/react-components';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import FeatureSteps from "./FeatureSteps";
import {useFetchElasticSearchByIdQuery, useUpdateMetricsMutation, useFetchElasticSearchQuery} from '../../../store';
import {buildQueryObj} from '../../../utility/FetchingUtils';
import {NotFound} from "../../NotFound";
import {PageTitle} from "../../../components/PageTitle";
import Breadcrumb from "../../../components/Breadcrumb";
import {removeStyleFromMediaQuery} from "../../../utility/stringUtil";
import {closeAppBarSearch} from "../../../utility/searchUtil";
import {BusyPageIndicator} from "../../../components/BusyPageIndicator";

const integrationFlowsStateHash = '#integration-flow';
const adpSolutionKeys = (solution) => {
    switch (solution) {
        case 'Recruiting':
        case 'Recruiting-International':
        case 'Recruitment & Onboarding - International':
            return 'Recruiting'
        case 'Human Resources':
        case 'Human Resources-International':
        case 'Productivity & Collaboration - International':
        case 'Learning Management - International':
        case 'Performance - International':
            return 'Human-Resources'
        case 'Time & Labor Management':
        case 'Time & Labor Management-International':
        case 'Time & Activities Management - International':
            return 'Time-Labor-Management'
        case 'Payroll Management':
        case 'Payroll Management-International':
        case 'Travel & Expense Management - International':
        case 'Compensation - International':
        case 'Finance - International':
        case 'External HR & Data Connectors - International':
            return 'Payroll-Management'
        case 'Benefit Management':
        case 'Benefit Management-International':
        case 'Engagement & Benefits - International':
            return 'Benefit-Management'
        case 'Compliance Solutions':
            return 'Compliance-Solutions'
        default:
            return '';
    }
}

function UseCasePage({
                         id,
                         showBreadcrumb = true,
                         mediaQueryStyle = true,
                         useCasePreview = false
                     }) {
    let source;
    const [showFeatureSteps, setShowFeatureSteps] = useState(false);
    const [selectedFeatureItem, setSelectedFeatureItem] = useState({});
    const {id: paramsId} = useParams();
    const location = useLocation();
    let useCaseResponse = useFetchElasticSearchQuery(buildQueryObj({query : '', index: 'usecases'}), {skip : paramsId !=='embeddedpayroll'});
    let idValue;
    if(useCaseResponse?.isSuccess)
    {
        const useCaseSolution = useCaseResponse?.data?.hits?.hits?.find(useCase => get(useCase, '_source.title', '')==='ADP® Embedded Payroll');
        idValue = useCaseSolution?._id;
    }
    if (!id) {
        id = location?.state?.id || paramsId;
        if (id === 'embeddedpayroll') {
            id = idValue || id;
        }
    }

    let {isSuccess, data, isLoading} = useFetchElasticSearchByIdQuery(buildQueryObj({
        index: useCasePreview ? 'usecases-preview' : 'usecases',
        id,
        sort: [{}]
    }), {skip: source || id && id === 'embeddedpayroll' });
    let useCaseData;
    if (isSuccess) {
        useCaseData = data?.hits?.hits[0]?._source;
        source = source ?? useCaseData;
    }

    const section1 = get(source, 'content.section1', '');
    const title = get(source, 'content.section2.title', '')
    const solutions = get(source, 'content.section2.solutions', []);

    const navigate = useNavigate();
    const solutionTitle = get(source, 'title', '');

    const breadcrumbPath = [
        {name: 'Welcome', route: '/welcome'},
        {name: 'Build Overview', route: '/build/overview'},
        {name: 'Integrations by Solution', route: '/build/integrations-by-solution'},
        {name: solutionTitle}
    ]

    const [updateMetrics, results] = useUpdateMetricsMutation();

    useEffect(() => {
        if(id && id !== 'embeddedpayroll')
        {
            updateMetrics({id, type: "usecases"});
        }
    }, []);

    useEffect(()=>{
        if(idValue && !location?.state?.id)
        {
           updateMetrics({id:idValue, type: "usecases"});
        }
    }, [idValue])

    const errorMsg = results.error ?
        <>
            <SdfAlert closeable status="error">An error occurred when updating metrics for this document</SdfAlert>
        </>
        : <></>

    const updateFeatureFromHash = () => {
        const getHashWithoutIndex = location.hash.substring(0, location.hash.lastIndexOf('-'));
        if (getHashWithoutIndex === integrationFlowsStateHash) {
            const getIndex = location.hash.substring(location.hash.lastIndexOf('-') + 1);
            if (solutions[getIndex - 1] === undefined) {
                setShowFeatureSteps(false);
                setSelectedFeatureItem({});
            } else {
                setShowFeatureSteps(true);
                setSelectedFeatureItem(solutions[getIndex - 1]);
            }
        }
    }

    useEffect(() => {
        updateFeatureFromHash()
        if (showFeatureSteps && location.hash === '') {
            setShowFeatureSteps(false);
            setSelectedFeatureItem({});
        }
    }, [location])

    useEffect(() => {
        updateFeatureFromHash()
    }, [source]);

    const onOpenHandler = (index, state) => {
        closeAppBarSearch()
        const solutionPath = solutionTitle === 'ADP® Embedded Payroll' ? 'embeddedpayroll' : id;
        const pathname = paramsId || solutionPath;
        let path = useCasePreview ? `/api-usecases/usecases-preview/${id}` : `/build/integrations-by-solution/usecases/${pathname}`;
        navigate({
            pathname: path,
            hash: `${state}-${index + 1}`
        }, { state: { id: location?.state?.id || id } });
        setShowFeatureSteps(true);
        setSelectedFeatureItem(solutions[index]);
    }

    const onCloseHandler = () => {
        const solutionPath = solutionTitle === 'ADP® Embedded Payroll' ? 'embeddedpayroll' : id;
        const pathname = paramsId || solutionPath;
        let path = useCasePreview ? `/api-usecases/usecases-preview/${id}` : `/build/integrations-by-solution/usecases/${pathname}`;
        navigate(path,{state: {id: location?.state?.id || id}});
        setShowFeatureSteps(false);
        setSelectedFeatureItem({});
    }

    const clickHandler = () => {
        const query = solutionTitle === 'ADP® Embedded Payroll' ? {adpProductsNa: solutionTitle} : {useCaseTags: adpSolutionKeys(solutionTitle)};
        const params = new URLSearchParams({
            filterQuery: '',
            product: '',
            ...query
        });
        closeAppBarSearch()
        navigate({
            pathname: `/build/guides/product-integration-guides`,
            search: params.toString(),
            state: {filter: query?.useCaseTags ?  adpSolutionKeys(solutionTitle) : solutionTitle}
        })
    }

    const embeddedPayroll = paramsId === 'embeddedpayroll' && !id && !useCaseResponse?.isLoading;

    if (!isLoading && solutions?.length === 0 && id && id !== 'embeddedpayroll' || embeddedPayroll) return <NotFound/>

    return isLoading || useCaseResponse?.isLoading ?
        <BusyPageIndicator positionClass="absolute-center"/> :
        <div className='min-h-screen'>
            {showBreadcrumb && <Breadcrumb className="pl-4 md:pl-12 lg:pl-12" path={breadcrumbPath}/>}
            {errorMsg}
                <div
                    className={
                        removeStyleFromMediaQuery(
                            'bg-alert-info-900 md:h-sm px-4 md:px-0 md:pl-12 md:pr-24 flex justify-between flex-col md:flex-row',
                            mediaQueryStyle
                        )
                    }
                >
                <div className='flex flex-col justify-center items-start gap-4'>
                    <div>
                        <h2 className="text-inverse-default mb-1">
                            {source?.region}
                        </h2>
                        <PageTitle
                            noMargin
                            text={section1?.title}
                            className="text-heading-07 font-black text-inverse-secondary"
                        />
                    </div>
                    <div className='text-body-03 text-inverse-secondary font-medium '>{section1?.subtitle}</div>
                    <SdfButton emphasis="primary" size='sm' className='font-medium'
                               onclick={() => clickHandler()}>Documentation</SdfButton>
                </div>
                <div>
                    <img
                        src={`${section1?.img}`}
                        className={
                            removeStyleFromMediaQuery(
                                'w-full md:w-sm my-4 md:my-0',
                                mediaQueryStyle
                            )
                        }
                        alt='integration solution'
                    />
                </div>
            </div>
            <div className='md:pl-12 py-6 text-heading-06 font-black max-w-4xl pl-4'>
                {title}
            </div>
            <div className={
                removeStyleFromMediaQuery(
                    'grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1  md:px-12 px-4 gap-8',
                    mediaQueryStyle
                )
            }>
                {solutions?.map((solution, index) => {
                    const productExamples = get(solution, 'example.adpProductExamples', []);
                    return (
                        <SdfCard key={index} className="cursor-pointer pb-8">
                            <SdfCardHeader>{solution.iconTitle}</SdfCardHeader>
                            <div className="h-full flex flex-col justify-between">
                                <div
                                    className="font-normal text-md text-alert-neutral pr-16 break-words">
                                    {solution.iconSubtitle}
                                </div>
                                <SdfCardFooter className="text-action-primary font-normal text-md pt-8 mb-4">
                                    {productExamples.length > 0 && <SdfButton variant='text' type='primary'
                                                                              onclick={() => onOpenHandler(index, integrationFlowsStateHash)}>View
                                        integration flows</SdfButton>}
                                </SdfCardFooter>
                            </div>

                            {productExamples.length > 0 &&
                                <div className='-mb-12 -ml-4'>
                                    <SdfTooltip tooltipId="alert-info" className="">
                                        <SdfIconButton slot="tooltip-target">
                                            <SdfIcon icon="alert-info" className='mt-2'></SdfIcon>
                                        </SdfIconButton>
                                        {productExamples?.map((example, index) => {
                                            return <div key={index}>{example.sorName}</div>;
                                        })}
                                    </SdfTooltip>
                                    <span className='ml-2 text-default '>Compatible ADP Products</span>

                                </div>
                            }
                        </SdfCard>


                    )

                })}
                {showFeatureSteps && <FeatureSteps handleOnHide={onCloseHandler} show={showFeatureSteps}
                                                   selectedFeatureItem={selectedFeatureItem} useCaseInfo={section1}/>}
            </div>
        </div>

}

export default UseCasePage;