const MAX_BYTE_SIZE = 99999;
const MIN_BYTE_SIZE = 1;

const ARTICLES_BORDER = 'border-solid border-1 border-dark border-opacity-50'
const ARTICLES_BORDER_TOP = `border-r-0 border-l-0 border-b-0 ${ARTICLES_BORDER}`;
const ARTICLES_BORDER_BOTTOM = `border-r-0 border-l-0 border-t-0 ${ARTICLES_BORDER}`;
const ARTICLES_BORDER_RIGHT = `${ARTICLES_BORDER} border-l-0 border-b-0 border-t-0`;
const ARTICLES_BORDER_LEFT = `${ARTICLES_BORDER} border-r-0 border-b-0 border-t-0`;
const NO_PRINT_CLASS = 'no-print'

const ALL_SELECT_ITEM = {
	value: 'all',
	label: 'All'
}

const SHOULD_NOT_UPPERCASE_ALL = [
	'tax'
]

const OVERVIEW_TOP_IMAGE = 'my-4'
const OVERVIEW_TEXT_IMAGE_TOP = 'flex justify-between flex-col md:flex-row items-center mx-4 md:mx-12 md:h-xs'
const SHELL_NAV_ICON = 'items-center mr-24 cursor-pointer w-10'

const DESCRIPTION_STYLE = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
};

export {
	MIN_BYTE_SIZE,
	MAX_BYTE_SIZE,
	ARTICLES_BORDER,
	ARTICLES_BORDER_TOP,
	ARTICLES_BORDER_BOTTOM,
	ARTICLES_BORDER_RIGHT,
	ARTICLES_BORDER_LEFT,
	ALL_SELECT_ITEM,
	SHOULD_NOT_UPPERCASE_ALL,
	OVERVIEW_TOP_IMAGE,
	OVERVIEW_TEXT_IMAGE_TOP,
	SHELL_NAV_ICON,
	NO_PRINT_CLASS,
	DESCRIPTION_STYLE
}