import {useNavigate} from "react-router-dom";
import {useFetchElasticSearchByIdQuery} from "../../store";
import {getArticleId} from "../../routes/legacyRedirects";
import {articlesApiTransformByType} from "../../utility/transform/articlesApi";

export function GuidesPath() {
    const navigate = useNavigate()

    let {isSuccess, data} = useFetchElasticSearchByIdQuery({index: "guides", id: getArticleId()})

    if (isSuccess) {
        data = articlesApiTransformByType(data.hits.hits[0])

        navigate(data.navigationPath)
    }
}