import CategoryBadge from "../../CategoryBadge";
import {getColorCode} from "../../../utility/enums/ExplorerDomainColor";
import {getDomainLabel} from "../../../utility/apiExplorerUtils";
import React from "react";


export function ApiDescription({api}) {
    return (
        <div>
            <CategoryBadge
                colorCode={getColorCode(api?.domain)}
                className="ml-px mb-1"
            >
                {getDomainLabel(api?.domain)}
            </CategoryBadge>
            <div className="flex items-center">
                <div className="text-heading-07 font-black">{api?.title}</div>
                <div className='min-w-8'>&nbsp;</div>
            </div>
            <div className="text-body-02"
                dangerouslySetInnerHTML={{
                     __html: api?.content
                 }}
            />
        </div>
    )
}
