class ExplorerDomainColor {
    static benefits = '#26328c';
    static commerce = '#eeeeee';
    static core = '#dc1e34';
    static hcm = '#009edd';
    static hr = '#f2635d';
    static infrastructure = '#eeeeee';
    static marketplace = '#eeeeee';
    static moneymanagement = '#eeeeee'
    static moneymovement = '#cc9c33'
    static payroll = '#eda47d';
    static staffing = '#4f72cc';
    static supplychain = '#eeeeee'
    static talent = '#f15c22';
    static tax = '#f6b333';
    static time = '#7967ae';
}


function getColorCode(domain) {
    return ExplorerDomainColor[(domain||'').toLowerCase().replace(' ', '')]
}

export {ExplorerDomainColor, getColorCode}
