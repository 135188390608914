import React from "react";

import {SwaggerField} from "../SwaggerField";
import {NoAvailableContentBox} from "../../NotAvailableContentBox";
import GearPencil from "../../../../assets/images/GearPencil.svg";

export function SwaggerFieldsOrNotAvailableBox({data, mappers, notAvailableTitle}) {
    return (
        data ? Object.keys(data).map((key, index) => (
                <SwaggerField
                    key={index}
                    name={key}
                    value={mappers.value(data[key])}
                    fieldType={mappers.fieldType(data[key])}
                    dataType={mappers.dataType(data[key])}
                    description={mappers.description(data[key])}
                    required={mappers.required(data[key])}
                />
            )) :
            <NoAvailableContentBox
                title={notAvailableTitle}
                imgSrc={GearPencil}
            />
    )
}