export function transformDateToLongWithComma(date) {
    return date.toLocaleDateString('en-US', {year: 'numeric', month: 'short', day: '2-digit'})
}

export function transformDateToLongWithCommaAndHour(date) {
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: "2-digit",
        minute: "2-digit"
    })
}