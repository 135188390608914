import React from "react";

export function PageTitle({text, className, style, noMargin, children, id}) {
	return (
		<h1
			className={className}
			style={{...noMargin && {margin: 0}, ...style}}
			id={id}
		>
			{children || text}
		</h1>
	)
}