import React from "react";
import { SdfBusyIndicator, SdfSpotIllustration } from "@synerg/react-components";
import LinkWithIcon from "../LinkWithIcon";
import { selectedUseCasePage, useFetchElasticSearchQuery } from "../../store";
import { buildQueryObj } from "../../utility/FetchingUtils";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export function UseCases() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const navigateUseCase = ({_id: id, _index: index, _source: source}) => {
        dispatch(selectedUseCasePage({id, index, source}));
        const title = source?.title;
        const path = title === 'ADP® Embedded Payroll' ? 'embeddedpayroll' : id;
        navigate(`/build/integrations-by-solution/${index}/${path}`, {state: {id}});
    }

    const useCaseResponse = useFetchElasticSearchQuery(buildQueryObj({index: 'usecases', queryResultMaxSize: 12}));
    let useCaseList;
    if (useCaseResponse.isLoading) {
        useCaseList = <SdfBusyIndicator/>
    } else if (useCaseResponse.error) {
        useCaseList = <h3>Error Loading Use Cases</h3>
    } else {
        useCaseList =
            useCaseResponse.data?.hits?.hits?.map((ele, i) => (
                <section
                    className="min-w-40 cursor-pointer flex flex-col justify-between mt-3"
                    key={i}
                    onClick={() => navigateUseCase(ele)}
                >
                    <div>
                        <div className="font-bold text-heading-04">{ele._source?.title}</div>
                        <div className="font-normal text-body-02 pt-2">{ele._source?.subtitle || "no subtitle"}</div>
                    </div>
                    <div className="text-action-primary font-normal text-body-02 pt-4 min-w-40">
                        <LinkWithIcon iconPosition="after" icon='nav-move-right'>Learn more </LinkWithIcon>
                    </div>
                </section>
        ))
    }
    return (
        <section>
            <div className="flex flex-col md:flex-row lg:flex-row pt-8 ml-1" id="integrationsUseCase">
                <div>
                    <SdfSpotIllustration illustrationName="tablet" id="integrationsUseCaseImage"/>
                </div>
                <div className="px-3">
                    <div className="font-black text-heading-06 pt-1" id="integrationsUseCaseHeader">Integrations to fit your use case</div>
                    <div className="text-heading-03 font-normal pt-2 ml-px" id="integrationsUseCaseDescription">Discover common integration flows with ADP APIs based on
                        your business use case as a solution provider -
                        and accelerate your integration with targeted guides.
                    </div>
                </div>
            </div>
            <div className="mx-4">
                <div className="grid gap-6 sm:grid-cols-1 md:gap-16 md:grid-cols-3 md:pl-16 md:ml-12 md:pr-32 lg:gap-16 lg:grid-cols-3 lg:pl-16 lg:pr-32 pt-4 " id="integrationsUseCaseContent">
                    {useCaseList}
                </div>
            </div>
        </section>
    )
}