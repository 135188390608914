import {CodeTypeEnum} from "../utility/enums/CodeTypeEnum";
import React from "react";
import {SdfBox} from "@synerg/react-components";
import { NoAvailableContentBox } from "./api-explorer/NotAvailableContentBox";

function JsonBox({strData, customStyle, elementRef}) {
    return (
        <SdfBox className={`overflow-scroll whitespace-pre-line xl:whitespace-pre font-mono text-sm ${customStyle}`} ref={elementRef}>
            {strData}
        </SdfBox>
    )
}

function CodeBox({contentType, strData, customStyle, elementRef}) {
    const uis = {
        [CodeTypeEnum.JSON]: <JsonBox strData={strData} customStyle={customStyle} elementRef={elementRef}/>,
    }

    if(!uis[contentType]) return <NoAvailableContentBox
    title="No preview available for media type other than application/json. 
    Please use download and/or copy to clipboard buttons." />

    return uis[contentType];
}

export {CodeBox}