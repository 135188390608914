import React from "react";
import {SdfButton} from "@synerg/react-components";
import {LogIn} from "../LogIn";

export function ViewArticleOrLogIn({
	isPublic,
	isLoggedIn,
	viewContent,
	className,
	navigationPath
}) {
	return (isPublic || isLoggedIn) ?
		<SdfButton
			className={className}
			onClick={viewContent}
			emphasis="primary"
			slot="custom-buttons"
			id="viewBtn"
		>
			View
		</SdfButton> :
		<LogIn
			className={className}
			slot="custom-buttons"
			navigationPath={navigationPath}
		/>
}