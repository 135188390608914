import React from "react";

import {ApiExplorerPreview} from "../api-explorer/ApiExplorerPreview";
import { LoadHtmlFromString } from "../LoadHtmlFromString";

function SearchArticleContent({section, highlightText, apiClick, swaggerUrl}) {return (
        <>
            <ApiExplorerPreview apiClick={apiClick} swaggerUrl={swaggerUrl} />
            <div>
                {
                    section?.sections?.map((element, i) => (
                        <div key={i}>
                            <h2>{element.title}</h2>
                            <div className="w-full overflow-x-auto">
                            <LoadHtmlFromString htmlStr={highlightText(element?.content ?? '')}/>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}


export default SearchArticleContent;