import React, {useEffect, useState, useRef} from 'react';
import {SdfBadge, SdfIcon} from "@synerg/react-components";
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {selectedArticle} from '../store';
import SearchArticleContent from '../components/articles/SearchArticleContent';
import {useSelector} from "react-redux";
import {ARTICLES_BORDER_BOTTOM, ARTICLES_BORDER_TOP} from "../utility/constants";
import {articlesApiTransformByType} from "../utility/transform/articlesApi";
import {getAllArticles} from "../store/services/elasticSearchService";
import {clearAppBarSearch, closeAppBarSearch, highlightText} from "../utility/searchUtil";
import {ArticleTags} from "../components/articles/ArticlesTags";
import {ViewArticleOrLogIn} from "../components/articles/ViewArticleOrLogIn";
import UseCasePage from "./Build/IntegrationsBySolution/UseCasePage";
import { LoadHtmlFromString } from '../components/LoadHtmlFromString';

const SCROLL = 'overflow-y-scroll h-lg xl:h-xl';
const formatNumber = num => num && num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

function SearchResults({
                           searchedKey,
                           panelClosed,
                           navigateToSearchResult
                       }) {
    const [selectedContent, setSelectedContent] = useState('');
    const [isPreview, setIsPreview] = useState(false);
    const [searchResponse, setSearchResponse] = useState()
    const isLoggedIn = useSelector((state) => {
        return state.auth.isLoggedIn;
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const modalRef = useRef();


    useEffect(() => {
        const fetchData = async () => {
            const {articles, total, isError} = await getAllArticles({searchQuery: searchedKey, sort: [{}]})
            setSearchResponse({
                articles,
                total,
                isError
            })
        }
        setIsPreview(false);
        if (searchedKey.length > 2) {
            fetchData()
        } else {
            setSearchResponse(null);
        }
    }, [searchedKey]);

    useEffect(() => {
        setIsPreview(false);
        setSelectedContent('');
        if (searchedKey.length) {
            clearAppBarSearch();
            setSearchResponse(null)
        }
    }, [panelClosed]);

    const setPreviewContent = (article) => {
        setSelectedContent(article);
        setIsPreview(true);
        modalRef?.current?.scrollTo({
            top: 0
          });
    }

    const viewContent = () => {
        dispatch(selectedArticle(selectedContent));
        closeAppBarSearch()
        if (selectedContent?._index === 'usecases' && selectedContent?.title === 'ADP® Embedded Payroll') {
            navigate(`/build/integrations-by-solution/usecases/embeddedpayroll`, { state: { id: selectedContent?._id } });
        }
        else {
            navigate(selectedContent.navigationPath);
        }
    }

    const viewAllResults = () => {
        closeAppBarSearch()
        navigateToSearchResult()
    }

    return (
        <>
            <section className="flex flex-row p-4 pr-0" tabIndex="0">
                {searchResponse && searchResponse?.articles.length > 0 &&
                    <>
                        <section className={`w-3/6 mb-8 ${SCROLL}`}>
                            <div className="flex justify-between items-center mr-2">
                                <div className="font-semibold text-sm">
                                    <span
                                        className="font-bold text-lg">Search results</span> ({searchResponse.total} total)
                                </div>
                                <div className="font-medium text-sm text-action-primary cursor-pointer"
                                     onClick={() => viewAllResults()}>View all results
                                </div>
                            </div>
                            <div className="max-h-8 overflow-auto cursor-pointer mt-4">
                                {searchResponse?.articles.map((article, index) => {
                                    article = articlesApiTransformByType(article)
                                    return (
                                        <div
                                            className={`${ARTICLES_BORDER_BOTTOM} px-2 py-4 hover:bg-action-default-100 ${article?._id + article?.title + article?.tagName === selectedContent._id + selectedContent?.title + selectedContent?.tagName ? 'bg-action-default-100' : ''}`}
                                            key={index}
                                            onClick={() => setPreviewContent(article)}>
                                            <div className="flex justify-between font-bold text-sm">
                                                <LoadHtmlFromString htmlStr={highlightText(article?.title, searchedKey)}/>
                                                <div>
                                                    {!article?.isPublic && !isLoggedIn &&
                                                        <SdfIcon icon='status-locked' external/>}
                                                </div>
                                            </div>
                                            <div className="flex justify-between items-center mt-2">
                                                <ArticleTags article={article}/>
                                                <div className="text-xs">{formatNumber(article?._source?.clicks)} views
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                        {!isPreview && <section className="pl-4">Select a result to preview</section>}
                        {isPreview &&
                            <section className={`w-3/6 text-sm pl-4 pr-8 ${SCROLL} h-md`} ref={modalRef}>
                                {
                                    (selectedContent?.tagName !== 'API' && selectedContent?.title) &&
                                    <div className="flex justify-start mb-2">
                                        <SdfBadge status="new">
                                            Preview
                                        </SdfBadge>
                                    </div>
                                }
                                {!selectedContent?.title && 'Select a result to preview'}
                                <div className="text-2xl font-extrabold">{selectedContent?.title}</div>
                                {(selectedContent?.abstract && !['API', 'USECASE'].includes(selectedContent?.tagName)) &&
                                    <div
                                        className="bg-alert-neutral-50 p-3 rounded mt-2 mb-4 overflow-ellipsis overflow-hidden">
                                        <span className="font-bold ">Summary:</span>{selectedContent?.abstract}
                                    </div>
                                }
                                <div className="flex flex-col break-words">
                                    <div className="flex flex-col">
                                        {(selectedContent.isPublic || isLoggedIn) &&
                                            <>
                                                {
                                                    selectedContent?.tagName === 'USECASE' ?
                                                        <div className="mt-3">
                                                            <UseCasePage
                                                                id={selectedContent?._source.paths[0].split('/').pop()}
                                                                showBreadcrumb={false}
                                                                mediaQueryStyle={false}
                                                            />
                                                        </div> :
                                                        <LoadHtmlFromString
                                                            htmlStr={highlightText(selectedContent?._source?.content ? selectedContent._source.content : '', searchedKey)}
                                                            className="overflow-x-auto"
                                                        />
                                                }

                                                <SearchArticleContent
                                                    section={selectedContent?._source}
                                                    highlightText={(text) => highlightText(text,searchedKey)}
                                                    apiClick={() => viewContent()}
                                                    swaggerUrl={selectedContent?._source?.swagger}
                                                />
                                            </>
                                        }
                                    </div>
                                </div>

                                {!selectedContent.isPublic && !isLoggedIn &&
                                    <div className="flex pt-8 flex-col justify-center items-center">
                                        <SdfIcon icon='status-locked' external/>
                                        <div className="pt-4">Please log in to view content</div>
                                    </div>
                                }
                            </section>
                        }
                    </>
                }
                {searchResponse && searchResponse?.articles.length === 0 &&
                    <>
                        <section className="w-3/6">
                            <div>No matches found for help</div>
                        </section>
                        <section className="w-3/6 text-sm pl-4">
                            Suggestions:
                            <ul>
                                <li>Try a more general keyword</li>
                                <li>Try a different search term</li>
                                <li>Try fewer keywords</li>
                            </ul>
                        </section>
                    </>
                }
                {isPreview &&
                    <section
                        className={`bg-default w-full flex justify-end items-center absolute right-0 bottom-0 ${ARTICLES_BORDER_TOP}`}>
                        <ViewArticleOrLogIn
                            isPublic={selectedContent.isPublic}
                            isLoggedIn={isLoggedIn}
                            viewContent={viewContent}
                            className="m-2"
                            navigationPath={selectedContent.navigationPath}
                        />
                    </section>}
            </section>
        </>
    )
}

export default SearchResults;
