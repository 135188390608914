import React from "react";
import {SdfIcon, SdfIconButton, SdfLink, SdfTooltip} from "@synerg/react-components";
import LinkWithIcon from "../LinkWithIcon";
import CategoryBadge from '../CategoryBadge';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {selectApi} from "../../store";
import {getDomainLabel} from "../../utility/apiExplorerUtils";
import {getColorCode} from "../../utility/enums/ExplorerDomainColor";
import {ARTICLES_BORDER_TOP} from "../../utility/constants";
import {getInnerText} from "../../utility/DOMUtils";

function ApiExplorerProductListView({products}) {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    function onHandleNavigate({api, rest}) {
        const apiProduct = {...api, ...rest}
        dispatch(selectApi(apiProduct))
        navigate(apiProduct._id)
    }

    return (
        <div className="pt-6">
            <div className="font-bold text-alert-neutral pb-4">API</div>
            {products.map(({_source: api, ...rest}, index) => (
                <div
                    key={index}
                    onClick={() => onHandleNavigate({api, rest})}
                    className={`${ARTICLES_BORDER_TOP} flex justify-between items-center hover:bg-action-default-50 cursor-pointer`}
                >
                    <div className="py-4 ml-2">
                        <CategoryBadge
                            isListView
                            colorCode={getColorCode(api.domain)}
                        >
                            {getDomainLabel(api.domain)}
                        </CategoryBadge>
                        <SdfLink className="text-lg no-underline">{api.title}</SdfLink>

                        <SdfTooltip tooltipId="alert-info" className="pl-2 pr-5" attachmentPoint="right">
                            <SdfIconButton slot="tooltip-target">
                                <SdfIcon icon="alert-info"/>
                            </SdfIconButton>
                            <span className="break-words">
                                {getInnerText(api.content)}
                            </span>
                        </SdfTooltip>
                    </div>

                    <LinkWithIcon icon='nav-move-right' iconPosition='after'>View </LinkWithIcon>
                </div>
            ))}
        </div>
    )
}

export default ApiExplorerProductListView;
