import React from 'react';
import ArticlesListWithFilter from "../../components/articles/ArticlesListWithFilter";
import {FILTERS} from "../../components/articles/filters";

const breadcrumbPath = [
    {name: 'Welcome', route: '/welcome'},
    {name: 'Learn Overview', route: '/learn/learn-overview'},
    {name: 'How-to Articles', route: '/learn/how-to-articles'}
];

function HowToArticlesPage() {
    return (
        <ArticlesListWithFilter
           pageTitle="How-To Articles"
            navigatePath="/learn/how-to-articles"
            breadcrumbPath={breadcrumbPath}
            fieldsDescriptions={['TOPIC', 'LAST UPDATED']}
            filtersDefinition={[
                FILTERS.inputWithoutMax,
                FILTERS.sortBy,
            ]}
            customArticlesQuery={{
                tags: ['howto']
            }}
       />
    )
}

export default HowToArticlesPage;
