import {SdfIcon, SdfIconButton, SdfTooltip} from "@synerg/react-components";
import React from "react";

function SwaggerTag({tag}) {
    return (
        <div className="flex items-center">
            <div className="mr-2 text-heading-03 font-bold">
                {tag.name}
            </div>
            <SdfTooltip tooltipId="alert-info">
                <SdfIconButton slot="tooltip-target">
                    <SdfIcon icon="alert-info"></SdfIcon>
                </SdfIconButton>
                {tag.description}
            </SdfTooltip>
        </div>
    )
}

export {SwaggerTag}