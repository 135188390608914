import React from 'react';
import {useFetchArticlePreviewQuery} from "../../store/apis/articlesApi";
import {useParams} from "react-router-dom";
import {ArticlePreview} from "../../components/articles/previews/ArticlePreview";
import {articlesPreviewApiTransform} from "../../utility/transform/articlesApi";
import {GuidePreview} from "../../components/articles/previews/GuidePreview";
import {NotFound} from "../NotFound";

export function CmsPreviewArticle() {
    const {articleId} = useParams();

    let {isSuccess, isError, data} = useFetchArticlePreviewQuery({articleId})

    if (isError) return <NotFound/>

    if (isSuccess) {
        if (!data.hits.hits.length) return <NotFound/>;
        data = articlesPreviewApiTransform(data.hits.hits[0])
    }

    return isSuccess && (data.isGuide ? <GuidePreview guide={data} isChapter={data.isChapter}/> :
        <ArticlePreview article={data}/>)
}
