import React from "react"
import { useNavigate } from "react-router-dom";

import { SdfButton } from "@synerg/react-components"
import { PageTitle } from "../PageTitle"

import groupPic from '../../assets/images/Group2.png'

export function HeroHeader() {
    const navigate = useNavigate();

    const navigateGuide = () => {
        navigate("/build/guides/marketplace-integration-guides/Making-Your-First-API-Call-With-POSTMAN")
    }

    return (
        <div className="bg-gray-50 pl-4 md:pl-5 lg:pl-5 pr-8">
                <main className="flex lg:justify-between flex-col md:flex-row md:items-center lg:items-center md:pl-8 md:mr-24">
                    <section className="mt-4 md:mt-0">
                        <div>
                            <PageTitle
                                className="text-heading-08 font-black"
                                noMargin
                                id="heroHeader"
                            >
                                Integration made<br/> possible
                            </PageTitle>
                        </div>
                        <div className="pt-3" id="heroDescription">
                            Discover resources to help you build <br/>applications and connectors
                            using ADP® APIs
                        </div>

                        <SdfButton onClick={() => navigateGuide()}
                                   emphasis="primary" className="mt-4" id="getStarted1">Get started</SdfButton>
                    </section>
                    <section>
                        <img
                            src={groupPic}
                            alt='Group of people'
                            className="w-full lg:w-lg lg:my-10 md:w-sm md:my-4 md:my-0"
                            id="heroImage"
                        />
                    </section>
                </main>
            </div>
    )
}