function transformProductsToSelect(products) {
    if (!products?.length) return [];
    const productsToSelect = [];

    for (const product of products) {
        if (!(product.title && product.key)) continue;

        productsToSelect.push({
            label: product.title,
            value: product.key,
            offerType: product.northAmericaOffering ? 'northAmerica' : 'international'
        })

    }

    return productsToSelect;
}

export {transformProductsToSelect}