import React from "react";
import './style.css'
import {LoadHtmlFromString} from "../../LoadHtmlFromString";

export function ChapterHtml({htmlStr, className}) {
    return (
        <div className={`overflow-x-auto ${className}`}>
            <LoadHtmlFromString htmlStr={htmlStr}/>
        </div>
    )
}