import React, {useState, useEffect} from "react";
import {SdfButton, SdfSelectSimple} from "@synerg/react-components";
import {EventsModal} from "./modals/EventsModal";

import {PostmanBox} from "./PostmanBox";
import {SlideIn} from "./modals/SlideIn";
import {ApiDescription} from "./ApiDescription";

export default function ApiOutline({
    api,
    product,
    events,
    codelists,
    postmanCollection,
    operations,
    productSelectItems,
    productSelectValue,
    productSelectHandler,
}) {
    const [eventsModalIsOpen, setEventsModalIsOpen] = useState(false)
    const [slideInIsOpen, setSlideInIsOpen] = useState(false)

    useEffect(() => {
        const eventModalElement = document.getElementById("eventModal");
        if (eventModalElement) {
          eventModalElement.style.zIndex = '1200';
        }
      }, [eventsModalIsOpen]);
    
    function openEventsModal() {
        setEventsModalIsOpen(true)
    }

    function openSlideIn() {
        setSlideInIsOpen(true)
    }

    function closeModal() {
        setEventsModalIsOpen(false)
        setSlideInIsOpen(false)
    }

    return (
        <>
            <div className="flex justify-between flex-wrap mb-2">
                <SdfSelectSimple
                    label="ADP Product"
                    className="w-72 pt-6"
                    items={productSelectItems}
                    value={productSelectValue}
                    onSdfChange={productSelectHandler}
                />
                <SdfButton
                    emphasis="secondary"
                    className="mt-12 mb-2 truncate sm:ml-0 max-w-xs"
                    onClick={openSlideIn}
                >
                    Show operations
                </SdfButton>
            </div>

            <div
                className="flex flex-col justify-end md:flex-row sm:pb-0">
                <div className="flex flex-col justify-between xl:col-span-3 lg:col-span-2 md:col-span-2 pt-10 pr-4 w-full">
                    <ApiDescription api={api}/>
                        <div className="mt-10">
                            <div className="text-heading-05 font-bold mb-2">Supported events</div>
                            <div>
                                <SdfButton onClick={openEventsModal} className="pr-3" emphasis="primary"
                                           disabled={events?.length > 0 ? null : true}>View events</SdfButton>
                            </div>
                        </div>
                </div>
                <div className="sm:py-2 md:mt-16 md:pt-8 md:ml-4 md:w-lg">
                    {
                        postmanCollection &&
                        <PostmanBox postmanCollection={postmanCollection} product={product}/>
                    }
                </div>
            </div>

            {eventsModalIsOpen && <EventsModal
                apiTitle={api?.title}
                productTitle={product?.title}
                closeModal={closeModal}
                events={events}
                size="lg"/>}

            {slideInIsOpen && <SlideIn closeModal={closeModal} operations={operations}></SlideIn>}
        </>
    )
}
