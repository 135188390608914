import React from 'react';
import {Navigate, Route} from "react-router-dom";


export function getPathName() {
    let pathName = window.location.pathname
    //remove last character if it was a slash
    if (pathName.charAt(pathName.length - 1) === '/') pathName = pathName.slice(0, -1);
    return pathName;
}

export function getArticleId() {
    const pathName = getPathName()
    const splitedPathName = pathName.split('/')
    let articleId = splitedPathName.pop()
    if (pathName.includes('/apiexplorer')) return splitedPathName[splitedPathName.length - 1]
    return articleId
}

export function legacyRedirects(redirects) {
    return Object.keys(redirects).map((key, index) => {
        let redirectsTo = redirects[key]

        //some legacy paths needs to be redirected by component, because needs logic
        if (typeof redirectsTo === "object") {
            return <Route key={index} exact path={key} element={redirectsTo}/>
        }

        const keyHasRouteParams = redirectsTo.includes(':id');

        if (keyHasRouteParams) {
            const articleId = getArticleId()
            redirectsTo = redirectsTo?.replace(':id', articleId)
        }

        return <Route key={index} exact path={key} element={<Navigate to={redirectsTo}/>}/>
    })
}

