import React from 'react';
import {SdfSpotIllustration} from "@synerg/react-components";
import eventNotificationData from '../test/data/eventNotification.json';

function EventNotification() {
    return (
        <section className="p-12 flex">
            <section className='w-9/12'>
            <div className='text-3xl font-black pb-4'>ADP Event APIs and Event Notification Guide</div>
            <section className="bg-gray-50 flex pl-12 pr-12 pt-4 pb-4 rounded-lg">
                <div>
                    <SdfSpotIllustration illustrationName="documents"/>
                </div>
                <div className="pl-3">
                    <div className="font-black text-2xl pt-2">Summary</div>
                    <div className="text-base font-normal pt-2">Outlines how event notifications are used within ADP APIs.
                    </div>
                </div>
            </section>
            <section>
                <header className="font-bold text-xl pt-6">{eventNotificationData.summaryHead}</header>
                <main className="text-xs pt-4">{eventNotificationData.summaryBody}</main>
            </section>
            <section>
                <header className="font-bold text-xl pt-6">{eventNotificationData.whatsNew}</header>
                <main className="text-base font-bold pt-4">{eventNotificationData.aug}</main>
                <main className="text-xs pt-4">{eventNotificationData.whatsNewBody}</main>
                <main className="text-sm font-bold pt-4">{eventNotificationData.supportVersion}</main>
                <main className="text-xs pt-4">{eventNotificationData.supportversionBody}</main>
            </section>
            <section>
                <header className="font-bold text-xl pt-6">{eventNotificationData.about}</header>
                <main className="text-base font-bold pt-4">{eventNotificationData.event}</main>
                <main className="text-xs pt-4">{eventNotificationData.eventBody}</main>
                <main className="text-base font-bold pt-4">{eventNotificationData.eventnotification}</main>
                <main className="text-xs pt-4">{eventNotificationData.eventnotificationBody}</main>
            </section>
            <section>
                <header className="font-bold text-xl pt-6">{eventNotificationData.processOverview}</header>
                <main className="text-xs pt-4">{eventNotificationData.processOverviewBody}</main>
                <main className="text-xs pt-4">{eventNotificationData.processOverviewBody2}</main>
            </section>
            </section>
            <section className="w-3/12">
                Contents
            </section>
        </section>
    )
}

export default EventNotification;

