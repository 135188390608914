import {HttpMethodsToSdfEnum} from "../../../../utility/enums/HttpMethodsToSdfEnum";
import {SdfFocusPane} from "@synerg/react-components";
import React from "react";
import {useNavigate} from "react-router-dom";
import {sortSwaggerTags} from "../../../../utility/apiExplorerUtils";
import {openAccordion, scrollToElement} from "../../../../utility/DOMUtils";
import {navigateToSwaggerPath} from "../../navigation";

export function SlideIn({closeModal, operations}) {
    let operationsCount = 0;
    const navigate = useNavigate();

    function onHandleSlideIn(method, pathname) {

        setTimeout(() => {
            const expandableBoxId = method + pathname
            const element = document.getElementById(expandableBoxId)

            openAccordion(expandableBoxId)
            scrollToElement(element)

        }, 200)

        closeModal()

        navigateToSwaggerPath(navigate, method, pathname)
    }

    const paneBody = <div className="px-4">
                {sortSwaggerTags(operations.tags)
                    .map((tag, index) => (
                        <div key={index}>

                            <div className="font-bold text-heading-03 pb-3">
                                {tag.name}
                            </div>

                            {
                                Object.keys(operations.paths).map((pathStr) => {
                                    return Object.keys(operations.paths[pathStr]).map((key) => {
                                        const uppercaseHttpMethod = key.toUpperCase();
                                        const pathEndpoint = operations.paths[pathStr][key];
                                        if (!HttpMethodsToSdfEnum[uppercaseHttpMethod]) return
                                        if (!pathEndpoint.tags.includes(tag.name)) return

                                        operationsCount ++;

                                        return (
                                            <div
                                                key={pathEndpoint.operationId}
                                                className="capitalize text-alert-info font-medium cursor-pointer pb-5"
                                                onClick={() => onHandleSlideIn(uppercaseHttpMethod, pathStr)}
                                            >{pathEndpoint.summary}</div>
                                        )
                                    })
                                })
                            }

                        </div>
                    ))}
                <div className="mb-20"></div>
            </div>

    return (
        <SdfFocusPane
            hideDismissButton
            hideAcceptButton
            allowBackgroundInteraction
            size={"sm"}
            visible
            paneType={"anchored"}
            closeable
            onSdfDismiss={closeModal}
            heading={`API operations (${operationsCount})`}
            className="mt-20"
            hideFooter
        >
            {paneBody}
        </SdfFocusPane>
    )
}

