export function headerTagToMargin(headerTagName) {
    const tagInt = parseInt(headerTagName[1])

    return `chapters-heading-${tagInt}`;
}

export function getChapterContents(content) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(content, 'text/html');
    const contents = htmlDoc.querySelectorAll('h3,h4,h5,h6')

    return Array.from(contents).map(chapterContent => chapterContent)
}
