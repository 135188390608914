import React from "react";
import {SdfBadge} from "@synerg/react-components";

import {HttpMethodsToSdfEnum} from "../../../utility/enums/HttpMethodsToSdfEnum";

export function SwaggerPathBrief({method, pathname, handleOnClick}) {
	return (
		<div
			className={`flex items-center w-full ${handleOnClick && 'cursor-pointer'}`}
			onClick={handleOnClick}
		>
			<SdfBadge
				status={HttpMethodsToSdfEnum[method]}
			>
				{method}
			</SdfBadge>
			<div className="text-heading-02 md:text-base font-bold ml-4 w-3/4 overflow-hidden truncate">{pathname}</div>
		</div>
	)
}