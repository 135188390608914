import React from "react";

import SwaggerPath from "./SwaggerPath";
import {HttpMethodsToSdfEnum} from "../../../utility/enums/HttpMethodsToSdfEnum";
import {SwaggerTag} from "./SwaggerTag";
import {sortSwaggerTags} from "../../../utility/apiExplorerUtils";
import {SdfCard} from "@synerg/react-components";
import {SwaggerPathBrief} from "./SwaggerPathBrief";

export function Swagger({url, tags, paths, isPreview, apiClick}) {
    return sortSwaggerTags(tags)
        .map((tag, index) => (
            <div key={index} className={`mb-10 ${!isPreview && 'mt-20'}`}>
                {
                    !isPreview &&
                    <div className="mb-3">
                        <SwaggerTag tag={tag} key={index} isPreview={isPreview}/>
                    </div>
                }
                <div>
                    {
                        Object.keys(paths).map((pathStr) => {
                            return Object.keys(paths[pathStr]).map((key) => {
                                const uppercaseHttpMethod = key.toUpperCase();
                                const pathEndpoint = paths[pathStr][key];
                                const copiedObject = JSON.parse(JSON.stringify(pathEndpoint));
                                const sortedScenarios = copiedObject['x-adpOperationData']?.scenarios?.sort((a, b) => a.name.localeCompare(b.name));
                                if (!HttpMethodsToSdfEnum[uppercaseHttpMethod]) return
                                if (!pathEndpoint.tags.includes(tag.name)) return

                                return (
                                    <div key={pathEndpoint.operationId} className="mb-3">
                                        {
                                            isPreview ?
                                                <>
                                                    <div className={isPreview ? 'mb-2' : ''}>
                                                        <h3
                                                            className="mb-0"
                                                            dangerouslySetInnerHTML={{
                                                                __html: pathEndpoint.summary
                                                            }}
                                                        />
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: pathEndpoint.description
                                                        }} />
                                                    </div>

                                                    <SdfCard onClick={() => apiClick()} className="cursor-pointer mb-8">
                                                        <SwaggerPathBrief
                                                            method={uppercaseHttpMethod}
                                                            pathname={pathStr}
                                                        />
                                                    </SdfCard>
                                                </> :
                                                <SwaggerPath
                                                    method={uppercaseHttpMethod}
                                                    pathname={pathStr}
                                                    summary={pathEndpoint.summary}
                                                    details={{
                                                        description: pathEndpoint.description,
                                                        parameters: pathEndpoint.parameters,
                                                        examples: pathEndpoint.responses,
                                                        scenarios: sortedScenarios,
                                                        canonicalUri: pathEndpoint['x-adpOperationData']?.canonicalUri,
                                                        url
                                                    }}
                                                />
                                        }
                                    </div>
                                )
                            })
                        })
                    }
                </div>
            </div>
        ))
}