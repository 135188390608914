const sortByOptions = {
    mostRecent: { value: 'most-recent', label: 'Most recent' },
    leastRecent: { value: 'least-recent', label: 'Least recent' },
    mostViewed: { value: 'most-viewed', label: 'Most viewed' },
    leastViewed: { value: 'least-viewed', label: 'Least viewed' },
    ascending: { value: 'ascending', label: 'Ascending (A-Z)' },
    descending: { value: 'descending', label: 'Descending (Z-A)' }
};

const domainsSelect = [
    { value: 'all', label: 'All'},
    { value: 'hr', label: 'HR'},
	{ value: 'core', label: 'Core'},
	{ value: 'talent', label: 'Talent'},
	{ value: 'payroll', label: 'Payroll'},
	{ value: 'benefits', label: 'Benefits'},
	{ value: 'staffing', label: 'Staffing'},
	{ value: 'tax', label: 'Tax'},
	{ value: 'time', label: 'Time'},
	{ value: 'money movement', label: 'Money Movement' },
	{ value: 'hcm', label: 'HCM'}
]

function buildQueryObj({
    mkplIntegrationCategory = [],
    adpProducts = [],
    useCaseTags = [],
    query = "",
    sort = { value: 'most-viewed' },
    tags = [],
    guideType = "",
    size = 999,
    index = "general",
    id = "",
    ...rest
}) {
    return {
        mkplIntegrationCategory,
        adpProducts,
        useCaseTags,
        query,
        sort,
        tags,
        guideType,
        size,
        index,
        id,
        ...rest
    }
}

const getClickBody = (queryObj)=> {
    return {
        index: queryObj.index,
        id: queryObj.id,
        type: queryObj.type || '_doc'
    }
}

const getElasticQuery = (queryObj) => {
    const { tags, query, guideType, size, index, mkplIntegrationCategory, adpProducts, useCaseTags, sort, id, ...rest } = queryObj;
    return ({
        query,
        mkplIntegrationCategory,
        adpProducts,
        useCaseTags,
        apiProductCode: "",
        type: "_doc",
        guideType,
        tags,
        from: 0,
        size,
        rbac: "",
        id,
        index,
        sort: getSort(sort),
        ...rest
    }
    )
}

const getSort = (sort) => {
    const sortString = sort?.value;

    switch (sortString) {
        case 'ascending':
            return [{ 'title.raw': "asc" }];
        case 'descending':
            return [{ 'title.raw': "desc" }];
        case 'most-viewed':
            return [{ clicks: "desc" }];
        case 'least-viewed':
            return [{ clicks: "asc" }];
        case 'most-recent':
            return [{ modifiedAt: "desc" }, { createdAt: "desc" }]
        case 'least-recent':
            return [{ modifiedAt: "asc" }, { createdAt: "asc" }]
        default:
            return [];
    }
}

export { buildQueryObj, sortByOptions, getElasticQuery, getClickBody, domainsSelect}
