import {createSlice} from "@reduxjs/toolkit";
import useCaseData from "../../test/data/useCaseList.json";

export const useCasesSlice = createSlice({
    name: 'useCase',
    initialState: useCaseData.useCaseList,
    reducers: {
        addUseCase(state, action) {
            state.push(action.payload);
        }
    }
});
