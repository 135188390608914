import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const productsApi = createApi({
    reducerPath: 'products',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            headers.append('X-Requested-With', 'XMLHttpRequest');
        },
        credentials: 'include'
    }),
    endpoints(builder) {
        return {
            fetchProducts: builder.query({
                query: () => {
                    return {
                        url: `${process.env.REACT_APP_API_PATH}/apiexplorer/products`,
                        method: 'GET'
                    }
                },
            }),
            fetchProductsByApi: builder.query({
                query: ({ resourcePath }) => {
                    return {
                        url: `${process.env.REACT_APP_API_PATH}/apiexplorer/productCode`,
                        method: 'POST',
                        body: { resourcePath }

                    }
                },
            })
        }
    }
});

export const { useFetchProductsQuery, useFetchProductsByApiQuery } = productsApi;
export { productsApi };
