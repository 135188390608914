import { SdfBusyIndicator, SdfButton, SdfCard, SdfIcon, SdfLink, SdfSpotIllustration } from "@synerg/react-components"
import React from "react"
import { selectedArticle, useFetchArticlesQuery } from "../../store";
import { buildQueryObj, sortByOptions } from "../../utility/FetchingUtils";
import {generateUrl} from '../../utility/UriUtil'
import { DESCRIPTION_STYLE } from "../../utility/constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoadHtmlFromString } from "../LoadHtmlFromString";
import useMediaQuery from "../../hooks/useMediaQuery";

export function LatestContent() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMdOrBigger = useMediaQuery('(min-width: 768px)')
    const isLoggedIn = useSelector((state) => {
        return state.auth.isLoggedIn;
    });
    const contentStyles ="text-lg pl-2 sm:pl-1 md:pl-20 md:ml-2 lg:pl-20 lg:ml-0";

    const onClickNavigateToPopularCard = (isLocked, url, article) => {
        dispatch(selectedArticle(article))
        isLocked ? window.location.replace(generateUrl(`${process.env.REACT_APP_API_PATH}/authenticate?redirUrl=${window.location.origin+url}`)) : navigate(url)
    }

    const getUrl = ({index, id, productCode, filename: f, tags, guideType}) => {
        let url;
        if (index === "faq") {
            url = `/learn/faqs/${id}`;
        } else if (index === "general" && tags.includes('howto')) {
            url = `/learn/how-to-articles/${id}`
        } else if (index === "general" && tags.includes('keyconcept')) {
            url = `/learn/key-concepts/${id}`;
        } else if (index === "guides" && guideType === "productIntegration") {
            url = `/build/guides/product-integration-guides/${id}`;
        } else if (index === 'guides' && guideType === 'mkplIntegration') {
            url = `/build/guides/marketplace-integration-guides/${id}`
        } else {
            url = ''
        }
        if (productCode) return `/build/api-explorer/${productCode}/${id}`;
        if (f) return `/services/elasticSearch${url}/doc/${f}`;
        return url;
    };

    const displayContent = (data) => {
        return <section className="grid gap-8 sm:grid-cols-1 md:grid-cols-1 md:pl-16 md:pr-32 md:ml-5 md:px-10 lg:grid-cols-3 lg:pl-10 lg:pr-32 pt-2">
            {data?.map((article, i) => {
                const {_id: id, _index: index, _source: source} = article
                const {filename = '', productCode = '', rbac = [], tags = [], guideType = ''} = source;
                const url = getUrl({id, index, productCode, filename, tags, guideType});
                const noHtml = s => s ? s.replace(/<[^><]+>|&nbsp;/g, '') : '';
                const summary = noHtml(source?.summary || source?.abstract || source?.content);
                const isLocked = /private/.test(rbac) && !isLoggedIn;

                return (
                    <SdfCard
                        key={i}
                        className='cursor-pointer md:mr-0'
                        onClick={
                            () => onClickNavigateToPopularCard(isLocked, url, article)
                        }
                    >
                        <div className={`font-bold text-heading-04 ${isMdOrBigger ? 'break-words' : 'break-all'}`}>{source?.title}</div>
                        <div className="h-full flex flex-col justify-between">
                            <div className={`font-normal text-body-02 pt-2 pr-4 overflow-hidden overflow-ellipsis ${isMdOrBigger ? 'break-words' : 'break-all'}`}
                                 style={DESCRIPTION_STYLE}><LoadHtmlFromString htmlStr={summary}/>
                                 </div>
                            <div className="text-action-primary font-normal text-body-02 pt-4">
                                <SdfLink>
                                    <SdfButton variant='text' type='primary'>
                                        {isLocked ? "Log in to view " : "Read more"}
                                    </SdfButton>
                                    <SdfIcon icon='nav-move-right'/>
                                </SdfLink>
                            </div>
                        </div>
                    </SdfCard>
                );
            })
            }
        </section>
    }

    let popularContent;
    let whatsNewContent;
    const popularList = useFetchArticlesQuery(buildQueryObj({size: 3, index: '', type: ""}));
    const whatsNewList = useFetchArticlesQuery(buildQueryObj({
        size: 3,
        index: '',
        type: "",
        sort: sortByOptions['mostRecent']
    }));

    popularContent = popularList?.isLoading ? <SdfBusyIndicator /> : popularList?.isError ?
        <h3 className={contentStyles}>Error Loading Popular Content</h3> :
        displayContent(popularList?.data?.articles);
    whatsNewContent = whatsNewList?.isLoading ? <SdfBusyIndicator /> : whatsNewList?.isError ?
        <h3 className={contentStyles}>Error Loading What’s New Content</h3> :
        displayContent(whatsNewList?.data?.articles);

    return (
        <>
          <section className="flex flex-col md:flex-row lg:flex-row pt-10 mb-2" id="stayUpToDate">
                <div className="mt-2">
                    <SdfSpotIllustration illustrationName="bullhorn" id="stayUpToDateImage"/>
                </div>
                <div className="pl-3 pt-3">
                    <div className="font-black text-heading-06 pt-1" id="stayUpToDateHeader">Stay up-to-date with the latest content</div>
                    <div className="text-heading-03 font-normal pt-2" id="stayUpToDateDescription">Stay informed as we constantly evolve and innovate on
                        the ADP Marketplace platform.
                    </div>
                </div>
            </section>
            <div className="font-bold text-heading-05 mx-3 sm:mx-4 md:px-10 ">
                <span id="explorePopularContent">
                    <div className="pl-2 sm:pl-1 md:pl-20 md:ml-2 lg:pl-16 lg:ml-0">Popular Content</div>
                    {popularContent}
                </span>

                <div id="exploreWhatsNew">
                    <div className="pl-2 sm:pl-1 md:pl-20 md:ml-2 lg:pl-16 lg:ml-0 pt-5">What’s new</div>
                    {whatsNewContent}
                </div>
            </div>
           
        </>
    )
}