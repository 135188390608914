import React from "react";
import {NavActionsEnum} from "../../../../utility/enums/NavActionsEnum";
import {SdfButton, SdfIcon, SdfSelectSimple} from "@synerg/react-components";

function shouldEnableNavButton(buttonAction, currentIndex, length) {
    if (buttonAction === 'back')
        return currentIndex < 1 ? true : undefined
    else if (buttonAction === 'next')
        return currentIndex === length - 1 ? true : undefined
}

function scenarioToSelectItem(index, scenario) {
    return {value: index, label: scenario.name}
}

export function SwaggerScenarios({scenarios, selectedExampleIndex, handleNavButtonClick, handleScenarioSelectChange}) {
    return (
        <>
            <SdfSelectSimple
                className="mt-6 w-full"
                inputWidth_md="full"
                items={scenarios.map((scenario, index) => {
                    return scenarioToSelectItem(index, scenario)
                })}
                value={scenarioToSelectItem(selectedExampleIndex, scenarios[selectedExampleIndex])}
                onSdfChange={handleScenarioSelectChange}
            >
            </SdfSelectSimple>
            <div className="flex justify-between mt-5 mb-6 mx-1">
                <SdfButton
                    variant="text"
                    slot="tooltip-target"
                    color="primary"
                    emphasis="tertiary"
                    action="standard"
                    disabled={shouldEnableNavButton(NavActionsEnum.BACK, selectedExampleIndex, scenarios.length)}
                    onClick={() => handleNavButtonClick(NavActionsEnum.BACK)}
                >
                    <SdfIcon icon="nav-page-back"/>
                    Back
                </SdfButton>
                {
                    scenarios &&
                    <div className="text-center mt-2">
                        <div className="font-black">
                            {scenarios[selectedExampleIndex].name}
                        </div>
                        <div className="text-alert-neutral text-sm mt-1">
                            {
                                `Viewing example ${selectedExampleIndex + 1} of ${scenarios.length}`
                            }
                        </div>
                    </div>
                }
                <SdfButton
                    size="sm"
                    emphasis="tertiary"
                    action="standard"
                    variant="text"
                    disabled={shouldEnableNavButton(NavActionsEnum.NEXT, selectedExampleIndex, scenarios.length)}
                    onClick={() => handleNavButtonClick(NavActionsEnum.NEXT)}
                >
                    Next
                    <SdfIcon icon="nav-page-next"/>
                </SdfButton>
            </div>
        </>
    )
}