import React from "react";
import {SdfIcon} from "@synerg/react-components";

function SwaggerField({name, value, fieldType, dataType, description, required}) {
    return (
        <div>
            <div className="mt-5 flex flex-row justify-between flex-wrap text-heading-03 font-black">
                <div>
                    {required && <SdfIcon icon="alert-error" className="mr-1 text-alert-error"/>}
                    {name}
                    {(value && value !== 'unset') && <span className="text-priority-minor">{` = '${value}'`}</span>}
                </div>
                <div>
                    {fieldType}
                    <span className="text-tertiary ml-1">
                        ({dataType || 'string'})
                    </span>
                </div>
            </div>
            <div className="w-fit md:w-3/4 overflow-hidden break-words text-alert-neutral mt-1"
                 dangerouslySetInnerHTML={{
                     __html: description
                 }}
            />
        </div>
    )
}

export {SwaggerField}
