import React, {useRef} from "react";
import {SdfExpandableBox} from "@synerg/react-components/dist/components";
import {SdfIcon, SdfIconButton, SdfTooltip} from "@synerg/react-components";
import {SwaggerPathDetails} from "./SwaggerPathDetails";
import {useNavigate} from "react-router-dom";
import {scrollToElement} from "../../../utility/DOMUtils";
import {navigateToSwaggerPath} from "../navigation";
import {SwaggerPathBrief} from "./SwaggerPathBrief";

function SwaggerPath({method, pathname, summary, details}) {
    const navigate = useNavigate()
    const ref = useRef(null)

    const fileNameBase = () => {
        return `${method}${pathname.replaceAll('/', '_')}`
    }
    const scroll = () => {
        setTimeout(() => {
            scrollToElement(ref.current)
        }, 300)

        navigateToSwaggerPath(navigate, method, pathname)
    }
    const getSummary = () => {
        return {
            method,
            pathname,
            description: details.description,
            canonicalUri: details.canonicalUri
        }
    }

    return (
        <SdfExpandableBox id={`${method}${pathname}`} ref={ref} variant="shadowed" triggerPlacement="after"
                          onSdfOpen={scroll}>
            {/*header*/}
            <div slot="header" className="flex justify-between items-center">
                <SwaggerPathBrief
                    method={method}
                    pathname={pathname}
                />
                <SdfTooltip
                    className="invisible md:visible"
                    tooltipId="alert-info"
                >
                    <SdfIconButton slot="tooltip-target">
                        <SdfIcon icon="alert-info"></SdfIcon>
                    </SdfIconButton>
                    {summary}
                </SdfTooltip>
            </div>

            {/*opened box content*/}
            <SwaggerPathDetails
                summary={getSummary()}
                parameters={details.parameters}
                examples={details.examples}
                scenarios={details.scenarios}
                fileNameBase={fileNameBase()}
            />
        </SdfExpandableBox>
    )
}

export default SwaggerPath
