import {uppercaseFirstDigit} from "../stringUtil";
import {ALL_SELECT_ITEM, SHOULD_NOT_UPPERCASE_ALL} from "../constants";
const shouldUppercaseAll = (domain) => (
	domain.length < 4 && !SHOULD_NOT_UPPERCASE_ALL.includes(domain)
)

function shouldResetFilter(apisFromQuery, currentDomainFilter) {
	if (!apisFromQuery?.length || currentDomainFilter === ALL_SELECT_ITEM.value) return false
	return (!apisFromQuery.some((item) => item._source.domain === currentDomainFilter))
}

export function formatFilterLabelFromValue(filterValue) {
    return shouldUppercaseAll(filterValue) ? filterValue.toUpperCase() : uppercaseFirstDigit(filterValue)
}

export function localFilterUseEffect(query, filters, urlKey, setQueryData, setLocalFilterSelect, apisFromQuery, queryDataFilterKey, searchParams, setSearchParams) {
    const domain = searchParams?.get('domain');
    if(!(query.isSuccess && query.data)) return;

    const offlineFilter = filters.find((filter) => filter.customUrlKey === urlKey)
    setQueryData(apisFromQuery)
    setLocalFilterSelect([
        ALL_SELECT_ITEM,
        ...[
            ...new Set(apisFromQuery
            .map(item => item._source[queryDataFilterKey]))
        ]
        .map((filterValue) => ({
            value: filterValue,
            label: shouldUppercaseAll(filterValue) ? filterValue.toUpperCase() : uppercaseFirstDigit(filterValue)
    }))].sort((a, b) => a.label.localeCompare(b.label)))

    const offlineFilterValue = offlineFilter?.value?.value

    if (shouldResetFilter(apisFromQuery, offlineFilter?.value?.value)) {
        if (offlineFilterValue?.items?.length === 1 && offlineFilterValue.items === ALL_SELECT_ITEM) return;
        offlineFilter.setSelectSimple(ALL_SELECT_ITEM)
        setQueryData(apisFromQuery)
        searchParams.delete(urlKey)
        setSearchParams(searchParams)
    } else if (offlineFilterValue === ALL_SELECT_ITEM.value && (domain === null || domain === 'all') ) {
        setQueryData(apisFromQuery)
    } else {
        setQueryData(apisFromQuery.filter((apiFromQuery) => (apiFromQuery._source[queryDataFilterKey] === offlineFilterValue || apiFromQuery._source[queryDataFilterKey] === domain)))
    }
}