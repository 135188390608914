import React from "react";
import {SdfPromoBox} from "@synerg/react-components";
import SearchArticleContent from '../SearchArticleContent';
import ChapterList from "../../guides/ChapterList";
import {transformDateToLongWithComma} from "../../../utility/dateUtil";
import {PageTitle} from "../../PageTitle";
import {WholeGuidePrintView} from "./GuidesPrintView/WholeGuidePrintView/WholeGuidePrintView";
import {ChapterPrintView} from "./GuidesPrintView/ChapterPrintView/ChapterPrintView";
import {getBreadcrumb} from "../../../utility/articlesUtils";
import Breadcrumb from "../../Breadcrumb";
import {GuideChapterPreview} from "./GuideChapterPreview";
import {useSearchParams} from "react-router-dom";
import {LoadHtmlFromString} from "../../LoadHtmlFromString";


function GuidePreview({guide, isChapter}) {
    const [searchParams] = useSearchParams()
    const chapter = searchParams.get('chapter')
    const isPrintView = Boolean(searchParams.get('printView'))

    const mapGuide = {
        'mkplIntegration': 'marketplace-integration-guides',
        'productIntegration': 'product-integration-guides'

    }

    const breadcrumbPath = getBreadcrumb(mapGuide[guide?._source?.guideType], guide.title);

    if (chapter) {
        isChapter = true
    }


    return (
        <div className={`h-full ${isPrintView ? 'print-container-font' : ''}`}>
            {isChapter ? <ChapterPrintView guide={guide} chapter={chapter}/> :
                <WholeGuidePrintView guide={guide}/>}

            {isChapter || chapter ? <GuideChapterPreview guide={guide}/> :

                <div className="no-print flex flex-col px-6">
                    <Breadcrumb path={breadcrumbPath}/>

                    <div>
                        <PageTitle
                            text={guide?.title}
                            className="mb-2"
                        />
                        <div className="text-tertiary mb-6">
                            Last updated: {
                            transformDateToLongWithComma(
                                new Date(guide?.date)
                            )
                        }
                        </div>
                        <div className="flex flex-col w-full">
                            <div className="flex w-full">
                                <SdfPromoBox layout="horizontal" variant="filled"
                                             illustrationName="documents" header="Summary"
                                             message={guide?.abstract} style={{wordBreak: "break-word"}}/>
                            </div>
                            {guide?._source?.navigation?.length > 0 ?
                                <ChapterList list={guide._source?.navigation} guideVersion={guide._source?._ver}
                                             isPreview/> :
                                <>
                                    {
                                        <LoadHtmlFromString htmlStr={guide._source.abstract}/>
                                    }
                                    {
                                        <SearchArticleContent
                                            section={guide?._source}
                                            // eslint-disable-next-line no-undef
                                            highlightText={(text) => highlightText(text)} apiClick={() => {
                                        }}/>
                                    }
                                </>
                            }
                        </div>
                    </div>

                </div>

            }


        </div>

    )
}

export {GuidePreview};
