import React, {useState} from "react";
import {NavActionsEnum} from "../../../utility/enums/NavActionsEnum";
import {SwaggerPathExample} from "./swagger-example/SwaggerPathExample";
import {SwaggerScenarios} from "./swagger-example/SwaggerScenarios";

const titleStyle = 'text-heading-06 font-black mb-1';

function SwaggerPathExamples({scenarios, parameters, headersFromPath, fileNameBase}) {
    const [selectedExampleIndex, setSelectedExampleIndex] = useState(0);

    const handleScenarioSelectChange = (e) => {
        setSelectedExampleIndex(e.detail.value)
    }

    const handleNavButtonClick = (action) => {
        if (action === NavActionsEnum.BACK)
            setSelectedExampleIndex(selectedExampleIndex - 1)
        else if (action === NavActionsEnum.NEXT)
            setSelectedExampleIndex(selectedExampleIndex + 1)
    }

    return (
        <div className="flex flex-col overflow-hidden">
            <div className={titleStyle}>
                Examples
            </div>
            <div className="pb-3">
                Navigate through the example by using either the dropdown below or the navigational links. Each examples
                consist of description summary, code sample, parameters and request header
            </div>

            <div>

                {
                    scenarios &&
                    <SwaggerScenarios
                        scenarios={scenarios}
                        selectedExampleIndex={selectedExampleIndex}
                        handleScenarioSelectChange={handleScenarioSelectChange}
                        handleNavButtonClick={handleNavButtonClick}
                    />
                }
                {
                    <SwaggerPathExample
                        example={scenarios && {example: scenarios[selectedExampleIndex], headersFromPath, parameters}}
                        fileNameBase={fileNameBase}
                        key={scenarios && scenarios[selectedExampleIndex].name}
                    />
                }
            </div>
        </div>
    )
}

export default SwaggerPathExamples
