import React from 'react';
import {SdfLink} from "@synerg/react-components";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

export function InternalLink({
     children,
     path,
     dispatchData,
     ...custom
 }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOnClick = () => {
        dispatchData && dispatch(dispatchData);
        window.scrollTo(0, 0);
        navigate(path);
    }

    return (
        <span className="cursor-pointer">
            <SdfLink
                onClick={handleOnClick}
                {...custom}
            >
                {children}
            </SdfLink>
        </span>
    )
}
