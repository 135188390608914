
function fulfillRoutes(routes) {
    return routes.map((route) => {
        if (route.hasChildren && route.children) {
            // recursively calls for children routes
            route.children = fulfillRoutes(route.children);
        }

        if (route.pathname) {
            route.href = route.pathname
        }

        return route;
    })
}

export default function useMenu() {
    const appRoutes = [
        {
            id: 'welcome',
            icon: 'home',
            title: 'Welcome',
            pathname: '/welcome',
        },
        {
            id: 'learn',
            title: 'Learn',
            icon: 'handbook',
            hasChildren: true,
            expanded: true,
            children: [
                {
                    id: 'learn-overview',
                    title: 'Learn Overview',
                    pathname: '/learn/learn-overview',
                },
                {
                    id: 'key-concepts',
                    title: 'Key Concepts',
                    pathname: '/learn/key-concepts',
                },
                {
                    id: 'how-to-articles',
                    title: 'How-to Articles',
                    pathname: '/learn/how-to-articles',
                },
                {
                    id: 'faqs',
                    title: 'FAQs',
                    pathname: '/learn/faqs',
                },
            ],
        },
        {
            id: 'build',
            title: 'Build',
            icon: 'toolbox',
            hasChildren: true,
            children: [
                {
                    id: 'build-overview',
                    title: 'Build Overview',
                    pathname: '/build/overview',
                },
                {
                    id: 'integrations-by-solution',
                    title: 'Integrations by Solution',
                    pathname: '/build/integrations-by-solution',
                },
                {
                    id: 'api-explorer',
                    title: 'API Explorer',
                    pathname: '/build/api-explorer',
                },
                {
                    id: 'guides',
                    title: 'Guides',
                    hasChildren: true,
                    children: [
                        {
                            id: 'product-integration-guides',
                            title: 'Product Integration Guides',
                            pathname: '/build/guides/product-integration-guides'
                        },
                        {
                            id: 'marketplace-integration-guides',
                            title: 'Marketplace Integration Guides',
                            pathname: '/build/guides/marketplace-integration-guides'
                        }
                    ]
                }
            ],
        },
        {
            id: 'support',
            title: 'Support',
            icon: 'handshake',
            hasChildren: true,
            children: [
                {
                    id: 'support-overview',
                    title: 'Support Overview',
                    pathname: '/support/support-overview'
                },
                {
                    id: 'help-center',
                    title: 'Help Center',
                    pathname: '/support/help-center'
                }
            ]
        }
    ];

    return [
        {
            appRoutes: fulfillRoutes(appRoutes),
        },
    ];
}
