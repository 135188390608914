import React from "react";

import {SdfBox} from "@synerg/react-components";


export function NoAvailableContentBox({title}) {
    return (
        <SdfBox className="bg-action-default-50">
            <div className="text-lg font-bold text-action-default">
                {title}
            </div>
        </SdfBox>
    )
}