import {SdfBusyIndicator} from "@synerg/react-components";
import React from "react";

const classNamesDict = {
	'absolute-center': 'flex items-center justify-center h-3xl',
	'list-loading': 'my-6 pt-6',
	'default': 'my-6'
}

export function BusyPageIndicator({positionClass}) {
	return (
		<SdfBusyIndicator
			className={classNamesDict[positionClass] ?? classNamesDict["default"]}
			size="xl"
		/>
	)
}