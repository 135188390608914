import {useState, useEffect} from "react";

const useLoadingEventListener = (eventType, initialValue) => {
    const [isLoading, setIsLoading] = useState(initialValue)

    useEffect(() => {
        window.addEventListener(
            eventType,
            (e) => setIsLoading(false)
        )
    }, [])

	return isLoading;
}

export default useLoadingEventListener;