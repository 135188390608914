import React from "react";
import {transformDateToLongWithCommaAndHour} from "../../../../../utility/dateUtil";
import {
    getChapterContents,
    headerTagToMargin
} from "../../../../../utility/printViewUtils";

import {
    adpCoverLogo,
    guideCoverBackground,
    guideLegalBackground,
    infoIcon,
    noteIcon,
    resultIcon,
    tipIcon
} from "../../../../../assets/images/guides-pdf";

import './style.css'
import {LoadHtmlFromString} from "../../../../LoadHtmlFromString";

export function WholeGuidePrintView({guide}) {

    return (
        <div className='print-container'>
            <div className="print-guide">
                {/* Needed to pre-fetch images, used as background, in css file to generate PDF */}
                <img src={guideCoverBackground} alt='guide cover background' style={{display: 'none'}}/>
                <img src={guideLegalBackground} alt='guide legal background' style={{display: 'none'}}/>
                <img src={noteIcon} alt='note icon' style={{display: 'none'}}/>
                <img src={tipIcon} alt='tip icon' style={{display: 'none'}}/>
                <img src={infoIcon} alt='info icon' style={{display: 'none'}}/>
                <img src={resultIcon} alt='result icon' style={{display: 'none'}}/>
                {/* --------------------------------------------------------------------------  */}
                <div className="guide-introduction-container">
                    <div className="guide-introduction-guide-text">Guide</div>
                    <div className="guide-introduction-guide-title">{guide.title}</div>
                    <div className="published-date">{guide._source.createdAt && <span>Published on<div>{transformDateToLongWithCommaAndHour(new Date(guide._source.createdAt))}</div></span>}</div>
                    <div className="last-modified">{guide._source.modifiedAt && <span>Last modified<div>{transformDateToLongWithCommaAndHour(new Date(guide._source.modifiedAt))}</div></span>}</div>
                    <img className="adp-logo" alt='adp cover logo' src={adpCoverLogo}/>
                </div>
                <div className="print-break"></div>
                <div className="guide-legal">
                    <div className="guide-legal-title">ADP Copyright Information</div>
                    <div className="guide-legal-info">
                        <div>ADP, the ADP logo, and Always Designing for People are trademarks of ADP, Inc.</div>
                        <div>Windows is a registered trademark of the Microsoft Corporation.</div>
                        <div>All other trademarks are the property of their respective owners.</div>
                        <div>Copyright © {new Date().getFullYear()} ADP, Inc. ADP Proprietary and Confidential - All
                            Rights Reserved.
                            These materials may not be reproduced in any format without the express written permission
                            of ADP, Inc.
                        </div>
                        <div>These materials may not be reproduced in any format without the express written permission
                            of ADP, Inc.
                            ADP provides this publication “as is” without warranty of any kind, either expressed or
                            implied, including,
                            but not limited to, the implied warranties of merchantability or fitness for a particular
                            purpose.
                            ADP is not responsible for any technical inaccurancies or typographical errors which may be
                            contained in this
                            publication. Changes are periodically made to the information herein, and such changes will
                            be incorporated in
                            new editions of this publication. ADP may make improvements and/or changes in the product
                            and/or the programes
                            described in this publication.
                        </div>
                        <div>{guide._source.createdAt && <span>Published on<div>{transformDateToLongWithCommaAndHour(new Date(guide._source.createdAt))}</div></span>}</div>
                        <div>{guide._source.modifiedAt && <span>Published on<div>{transformDateToLongWithCommaAndHour(new Date(guide._source.modifiedAt))}</div></span>}</div>
                    </div>
                </div>
                <div className="print-break"></div>
                <div className="guide-contents-container">
                    <div className="guide-contents-title">Table of Contents</div>
                    {guide._source.navigation.map((chapter, index) => (
                        <div className="guide-contents-chapters" key={index}>
                            <div className="guide-contents-chapter-number">Chapter {index + 1}</div>
                            <div className="guide-contents-chapter-title">{chapter.title}</div>
                            {getChapterContents(chapter.content).map((chapterText, index, array) => {
                                return (
                                    <div
                                        key={index}
                                        className={`chapter-contents-list-headings-title ${headerTagToMargin(chapterText.tagName)}`}>
                                        {chapterText.innerText}
                                    </div>
                                )
                            })}
                        </div>
                    ))}
                </div>
                <div className="print-break"/>
                <div className='guide-chapters-content-container' style={{pageBreakBefore: "always"}}>
                    {guide._source.navigation.map((chapter, index,) => {
                        return (
                            <div key={index} className="chapter-content-container">
                                <div className="chapter-content-chapter-number">Chapter {index + 1}</div>
                                <div className="chapter-content-title">{chapter.title}</div>
                                <div className="guide-content-container">
                                    <LoadHtmlFromString htmlStr={chapter.content}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

