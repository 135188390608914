import {formatFilterLabelFromValue} from "./localFilterUtil";

function urlParamsUtil(urlParamsGetter, urlParams) {
    return urlParams.forEach((urlParams) => param(
        urlParamsGetter, 
        urlParams.isArray,
        urlParams.outsideUrlSetter, 
        urlParams.key, 
        urlParams.transformParamsFunc
    ))
}

function param(
    urlParamsGetter,
    isArray,
    outsideUrlSetter, 
    key, 
    transformParamsFunc,
) {
    if (isArray) {
        const params = urlParamsGetter.getAll(key);
        if (params && params.length)
            outsideUrlSetter(
                transformParamsFunc(params)
            )
    } else {
        let param = urlParamsGetter.get(key);
        if (param && transformParamsFunc) {
            param = transformParamsFunc(param)
        }
        if (param)
            outsideUrlSetter(
                typeof param === 'string' ? param : param[0]
            )
    }
}

const filterSelect = (select) => {
    return (targetSelectItem) => {
        const selected =  select.filter((currentSelectItem) => targetSelectItem?.indexOf(currentSelectItem.value) !== -1);
        return selected?.length ? selected : [{value: targetSelectItem, label: formatFilterLabelFromValue(targetSelectItem)}]
    }
}

export {urlParamsUtil, filterSelect}