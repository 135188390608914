import React, {useState} from "react";
import {SdfSegmentedControl} from "@synerg/react-components";
import {CodeSampleRequest} from "./CodeSampleRequest";
import {CodeSampleResponse} from "./CodeSampleResponse";
import {isEmptyObject} from "../../../../utility/ObjectUtil";

const exampleTitleStyle = `text-heading-05 font-black mb-2 mt-4`

function SwaggerPathExample({example: {example, headersFromPath, parameters} = {}, fileNameBase}) {
    const types = [{label: 'Request', value: 'request'}, {label: 'Response', value: 'response'}]
    const [type, setType] = useState(types[0]);

    const handleCodeSampleType = (e) => {
        setType(types[e.detail])
    }

    const getData = (type) => {
        if (!example?.[type]) return null;

        return Object.keys(example?.[type]).map(headerOrParameter => {
            const {description, required} = parameters.find(parameter => parameter.name === headerOrParameter)
            return {
                [headerOrParameter]: {
                    value: example?.[type][headerOrParameter],
                    description,
                    required
                }
            }
        }).reduce((acc, value) => {
            return {
                ...acc,
                ...value
            }
        }, {})
    }

    const getRequestHeaders = () => {
        return getData('headers')
    }

    const getParameters = () => {
        return getData('parameters')
    }

    const getHeadersInfos = () => {
        if (!headersFromPath) return null

        const headers = Object.values(headersFromPath).find(statusCode => statusCode.headers && !isEmptyObject(statusCode.headers))

        return headers?.headers;
    }

    return (<div>
            {
                example?.description &&
                <>
                    <div className={exampleTitleStyle}>
                        Description
                    </div>
                <div dangerouslySetInnerHTML={{
                    __html: example.description
                }} />
                </>
            }
            <div className={exampleTitleStyle}>
                Code Sample(s)
            </div>

            <div className="flex justify-center mb-4 mt-6">
                <SdfSegmentedControl
                    items={types}
                    onSdfChange={handleCodeSampleType}
                />
            </div>
            {
                type.value === types[0].value ?
                    <CodeSampleRequest
                        request={example?.content}
                        headers={getRequestHeaders()}
                        parameters={getParameters()}
                        fileName={example?.name}
                        fileNameBase={fileNameBase}
                    /> :
                    <CodeSampleResponse
                        responses={example?.responses}
                        headers={getHeadersInfos()}
                        fileNameBase={fileNameBase}
                    />
            }
        </div>
    )
}

export {SwaggerPathExample}
