import React from "react";
import {SwaggerFieldsOrNotAvailableBox} from "./SwaggerFieldsOrNotAvailableBox";
import {fieldsMapper} from "./mappers";
import {FieldsDescription} from "./FieldsDescription";
import {CodeExample} from "../../code-example/CodeExample";
import {SwaggerNoPreview} from "./SwaggerNoPreview";

export function CodeSampleRequest({request, fileNameBase, headers, parameters}) {
    return (
        <>
            {
                request ?
                    Object.keys(request).map((contentType, index) => {
                        return (
                            <div key={contentType} className="mt-5">
                                <CodeExample
                                    key={index}
                                    path={request[contentType]?.externalValue}
                                    name={request[contentType].name}
                                    contentType={contentType}
                                    fileName={`${fileNameBase}-request.json`}
                                />
                            </div>
                        )
                    }) :
                    <SwaggerNoPreview/>
            }
            <FieldsDescription description="Parameters" showRequiredDescription/>
            <SwaggerFieldsOrNotAvailableBox
                data={parameters}
                mappers={{
                    ...fieldsMapper,
                    fieldType: (data) => 'path'
                }}
                notAvailableTitle="No parameters provided"
            />
            <FieldsDescription description="Request header(s)" showRequiredDescription/>
            <SwaggerFieldsOrNotAvailableBox
                data={headers}
                mappers={{
                    ...fieldsMapper,
                    fieldType: (data) => 'header'
                }}
                notAvailableTitle="No request headers provided"
            />
        </>
    )
}