import React from 'react';
import {SdfBusyIndicator} from '@synerg/react-components';
import ApiExplorerPopularProducts from '../../components/api-explorer/ApiExplorerPopularProducts';

import {useDispatch} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {selectedArticle, useFetchProductsQuery, useFetchArticlesQuery, useFetchElasticSearchQuery} from '../../store';
import {buildQueryObj} from '../../utility/FetchingUtils';

import LinkWithIcon from '../../components/LinkWithIcon';
import Icon from '../../assets/images/Tools.svg';
import {OverviewPageTitle} from "../../components/OverviewPageTitle";
import {OVERVIEW_TEXT_IMAGE_TOP, OVERVIEW_TOP_IMAGE} from "../../utility/constants";
import {BriefCard} from "../../components/BriefCard";

const SECTION_TITLE_FONT_SIZE = 'text-3xl'
const SECTION_TITLE_FONT_WEIGHT = 'font-black'

function BuildOverviewPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const {
        data: integrationBySolutionData,
        error: integrationBySolutionError,
        isLoading: integrationBySolutionIsLoading
    } = useFetchElasticSearchQuery(buildQueryObj({index: 'usecases', size: 3}))

    const {
        data: apiExplorerData,
        error: apiExplorerError,
        isLoading: apiExplorerIsLoading,
        isSuccess: apiExplorerIsSuccess
    } = useFetchProductsQuery();

    const {
        data: productGuidesData,
        error: productGuidesError,
        isLoading: productGuidesIsLoading
    } = useFetchArticlesQuery(buildQueryObj({
        tags: ['guide'],
        index: "guides",
        guideType: "productIntegration",
        size: 4
    }));

    const {
        data: marketplaceGuidesData,
        error: marketplaceGuidesError,
        isLoading: marketplaceGuidesIsLoading
    } = useFetchArticlesQuery(buildQueryObj({tags: ['guide'], index: "guides", guideType: "mkplIntegration", size: 4}));

    const onHandleNavigate = (where, article) => {
        dispatch(selectedArticle(article));
        if (article?._index === "usecases" && article?._source?.title === 'ADP® Embedded Payroll') {
            navigate(`/build/${where}/embeddedpayroll`, { state: { id: article?._id } });
        }
        else {
            navigate(`/build/${where}/${article.key || article._id}`);
        }
    }

    return (
        <div className="break-words">
            <div className="bg-action-primary-900">
                <div className={OVERVIEW_TEXT_IMAGE_TOP}>
                    <section>
                        <OverviewPageTitle text="Build"/>
                        <div className="text-heading-07 font-black text-inverse-default mb-1">
                            Equip yourself with the tools <br/>
                            you need to integrate with ADP
                        </div>
                        <div className="text-lg font-normal text-inverse-default">
                            Explore common API integration use cases as a solution provider. Explore API<br/>
                            details within API Explorer and reference guides to maximize your use of ADP APIs.
                        </div>
                    </section>
                    <img
                        alt='Tools'
                        src={Icon}
                        className={OVERVIEW_TOP_IMAGE}
                    />
                </div>
            </div>
            <div className='mx-3 mt-6 md:mx-12 lg:mx-12'>
                <section className='mb-10'>
                    <div className='flex justify-between items-center'>
                        <span className={`${SECTION_TITLE_FONT_SIZE} ${SECTION_TITLE_FONT_WEIGHT} tracking-sm`}>Integrations by Solution</span>
                        <LinkWithIcon to='/build/integrations-by-solution' icon='nav-move-right' iconPosition='after'>View
                            all</LinkWithIcon>
                    </div>

                    <div className="grid gap-4 lg:grid-cols-3 sm:grid-cols-1 pt-4">
                        {integrationBySolutionIsLoading ? <SdfBusyIndicator/> : ''}
                        {integrationBySolutionError ? <h3>Error loading Integrations by Solution</h3> : ''}
                        {
                            integrationBySolutionData && integrationBySolutionData.hits.hits.slice(0, 3).map((integrationBySolution, index) => (
                                <BriefCard
                                    key={index}
                                    title={integrationBySolution._source.title}
                                    subtitle={integrationBySolution._source.content.section1.subtitle}
                                    footer="Learn more"
                                    onClick={() => onHandleNavigate('integrations-by-solution/usecases', integrationBySolution)}
                                />
                            ))
                        }
                    </div>

                </section>

                <section className='mb-10'>
                    <div className='flex justify-between items-center'>
                        <span className={`${SECTION_TITLE_FONT_SIZE} ${SECTION_TITLE_FONT_WEIGHT} tracking-sm`}>Explore APIs by Product</span>
                        <LinkWithIcon to='/build/api-explorer' icon='nav-move-right' iconPosition='after'>View
                            all</LinkWithIcon>
                    </div>

                    <div>
                        {apiExplorerIsLoading ? <SdfBusyIndicator/> : ''}
                        {apiExplorerError ? <h3>Error loading Explore APIs by product</h3> : ''}
                        {apiExplorerIsSuccess && <ApiExplorerPopularProducts products={apiExplorerData}/>}
                    </div>
                </section>

                <section className='mb-10'>
                    <div className='flex justify-between items-center'>
                        <span className={`${SECTION_TITLE_FONT_SIZE} ${SECTION_TITLE_FONT_WEIGHT} tracking-sm`}>Product Guides</span>
                        <LinkWithIcon
                            to='/build/guides/product-integration-guides'
                            icon='nav-move-right'
                            iconPosition='after'
                        >
                            View all
                        </LinkWithIcon>

                    </div>

                    <div className='grid gap-5 lg:grid-cols-2 sm:grid-cols-1 grid-rows-2 pt-4'>
                        {productGuidesIsLoading ? <SdfBusyIndicator/> : ''}
                        {productGuidesError ? <h3>Error loading Product Guides</h3> : ''}
                        {
                            productGuidesData && productGuidesData.articles.map((productGuide, index) => (
                                <BriefCard
                                    key={index}
                                    title={productGuide._source.title}
                                    subtitle={productGuide._source.abstract}
                                    footer="View guide"
                                    onClick={() => onHandleNavigate('guides/product-integration-guides', productGuide)}
                                />
                            ))
                        }
                    </div>
                </section>

                <section>
                    <div className='flex justify-between items-center'>
                        <span className={`${SECTION_TITLE_FONT_SIZE} ${SECTION_TITLE_FONT_WEIGHT} tracking-sm`}>Marketplace Guides</span>
                        <LinkWithIcon
                            to='/build/guides/marketplace-integration-guides'
                            icon='nav-move-right'
                            iconPosition='after'
                        >
                            View all
                        </LinkWithIcon>
                    </div>

                    <div className='grid gap-5 lg:grid-cols-2 sm:grid-cols-1 grid-rows-2 pt-4'>
                        {marketplaceGuidesIsLoading ? <SdfBusyIndicator/> : ''}
                        {marketplaceGuidesError ? <h3>Error loading Marketplace Guides</h3> : ''}
                        {
                            marketplaceGuidesData && marketplaceGuidesData.articles.map((marketplaceGuide, index) => (
                                <BriefCard
                                    key={index}
                                    title={marketplaceGuide._source.title}
                                    subtitle={marketplaceGuide._source.abstract}
                                    footer="View guide"
                                    onClick={() => onHandleNavigate('guides/marketplace-integration-guides', marketplaceGuide)}
                                />
                            ))
                        }
                    </div>

                </section>

            </div>
        </div>
    )
}

export default BuildOverviewPage;
